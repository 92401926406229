import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function FAQLeftGridComponent() {
  const [faqBox, setFAQBox] = useState("faq_getting_started");
  const scrollToSection = (id) => {
    setFAQBox(id);
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  return (
    <Grid
      item
      xs={12}
      md={3}
      lg={3}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "48px",
        "@media (max-width:900px)": { display: "none" },
      }}
    >
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "25.6px",
          letterSpacing: "0.08px",
        }}
      >
        Support /{" "}
        <span sx={{ color: "var(--neutral-900, #131316)!important" }}>
          {faqBox==="faq_getting_started" && "Getting Started"}
          {faqBox==="faq_withdrawals" && "Withdrawals"}
          {faqBox==="faq_accounts" && "Accounts"}
          {faqBox==="faq_payments" && "Payments"}
        </span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <svg
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_675_37991)">
            <circle cx="40" cy="40" r="40" fill="#495ECA" />
            <g opacity="0.4">
              <g filter="url(#filter0_b_675_37991)">
                <path
                  d="M27.5594 40.4722L21.8432 -188.809C21.6359 -197.127 32.6856 -200.195 36.7963 -192.961L236.558 158.587C240.669 165.821 232.377 173.743 225.338 169.308L31.2922 47.0413C29.0272 45.6141 27.6261 43.1485 27.5594 40.4722Z"
                  fill="url(#paint0_linear_675_37991)"
                  fillOpacity="0.05"
                />
                <path
                  d="M27.5594 40.4722L21.8432 -188.809C21.6359 -197.127 32.6856 -200.195 36.7963 -192.961L236.558 158.587C240.669 165.821 232.377 173.743 225.338 169.308L31.2922 47.0413C29.0272 45.6141 27.6261 43.1485 27.5594 40.4722Z"
                  stroke="white"
                  strokeOpacity="0.5"
                />
              </g>
              <g filter="url(#filter1_b_675_37991)">
                <path
                  d="M56.6287 23.9539L50.9126 -205.328C50.7052 -213.645 61.755 -216.713 65.8656 -209.479L265.627 142.069C269.738 149.303 261.446 157.225 254.407 152.789L60.3615 30.5229C58.0965 29.0958 56.6955 26.6302 56.6287 23.9539Z"
                  fill="url(#paint1_linear_675_37991)"
                  fillOpacity="0.2"
                />
                <path
                  d="M56.6287 23.9539L50.9126 -205.328C50.7052 -213.645 61.755 -216.713 65.8656 -209.479L265.627 142.069C269.738 149.303 261.446 157.225 254.407 152.789L60.3615 30.5229C58.0965 29.0958 56.6955 26.6302 56.6287 23.9539Z"
                  stroke="white"
                />
              </g>
            </g>
            <path
              d="M53 43C53 50.1812 47.1812 56 40 56C32.8187 56 27 50.1812 27 43C27 36.5 31.7688 31.1125 38 30.1562V28H36.25C35.8375 28 35.5 27.6625 35.5 27.25V24.75C35.5 24.3375 35.8375 24 36.25 24H43.75C44.1625 24 44.5 24.3375 44.5 24.75V27.25C44.5 27.6625 44.1625 28 43.75 28H42V30.1562C44.3438 30.5188 46.4813 31.5063 48.2313 32.9438L49.95 31.225C50.2438 30.9313 50.7188 30.9313 51.0125 31.225L52.7812 32.9937C53.075 33.2875 53.075 33.7625 52.7812 34.0562L50.9438 35.8938L50.9062 35.9312C52.2313 37.9562 53 40.3875 53 43ZM42 45.25V35.7812C42 35.3687 41.6625 35.0312 41.25 35.0312H38.75C38.3375 35.0312 38 35.3687 38 35.7812V45.25C38 45.6625 38.3375 46 38.75 46H41.25C41.6625 46 42 45.6625 42 45.25Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_b_675_37991"
              x="-28.6592"
              y="-247.525"
              width="316.814"
              height="468.619"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_675_37991"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_675_37991"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_b_675_37991"
              x="-19.5898"
              y="-284.043"
              width="356.814"
              height="508.619"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_675_37991"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_675_37991"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_675_37991"
              x1="136.677"
              y1="-17.1871"
              x2="27.6662"
              y2="44.7566"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.2" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_675_37991"
              x1="165.746"
              y1="-33.7054"
              x2="56.7356"
              y2="28.2383"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" stopOpacity="0.2" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_675_37991">
              <rect width="80" height="80" rx="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Typography
          sx={{
            width: "256px",
            color: "var(--neutral-900, #131316)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "56px",
            letterSpacing: "-0.4px",
            mt: "32px",
          }}
        >
          Getting Started
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          component={Link}
          sx={{
            p: "15px 24px",
            borderLeft: `3px solid ${
              faqBox === "faq_getting_started" ? "#495ECA" : "#E3E3E8"
            }`,
            textDecoration: "none",
          }}
          onClick={() => {
            setFAQBox("faq_getting_started");
            scrollToSection("faq_getting_started");
          }}
        >
          <Typography
            sx={{
              color: faqBox === "faq_getting_started" ? "#495ECA" : "#131316",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "25.6px",
              letterSpacing: "0.08px",
            }}
          >
            Getting Started
          </Typography>
        </Box>
        <Box
          component={Link}
          sx={{
            p: "15px 24px",
            borderLeft: `3px solid ${
              faqBox === "faq_withdrawals" ? "#495ECA" : "#E3E3E8"
            }`,
            textDecoration: "none",
          }}
          onClick={() => {
            setFAQBox("faq_withdrawals");
            scrollToSection("faq_withdrawals");
          }}
        >
          <Typography
            sx={{
              color: faqBox === "faq_withdrawals" ? "#495ECA" : "#131316",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "25.6px",
              letterSpacing: "0.08px",
            }}
          >
            Withdrawals
          </Typography>
        </Box>
        <Box
          component={Link}
          sx={{
            p: "15px 24px",
            borderLeft: `3px solid ${
              faqBox === "faq_accounts" ? "#495ECA" : "#E3E3E8"
            }`,
            textDecoration: "none",
          }}
          onClick={() => {
            setFAQBox("faq_accounts");
            scrollToSection("faq_accounts");
          }}
        >
          <Typography
            sx={{
              color: faqBox === "faq_accounts" ? "#495ECA" : "#131316",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "25.6px",
              letterSpacing: "0.08px",
            }}
          >
            Accounts
          </Typography>
        </Box>
        <Box
          component={Link}
          sx={{
            p: "15px 24px",
            borderLeft: `3px solid ${
              faqBox === "faq_payments" ? "#495ECA" : "#E3E3E8"
            }`,
            textDecoration: "none",
          }}
          onClick={() => {
            setFAQBox("faq_payments");
            scrollToSection("faq_payments");
          }}
        >
          <Typography
            sx={{
              color: faqBox === "faq_payments" ? "#495ECA" : "#131316",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "25.6px",
              letterSpacing: "0.08px",
            }}
          >
            Payments
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}