import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { ButtonLoader, PrimaryBlackButton } from "../../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import KYCBLOCK from "../../components/Kyc";
import { hasAccess } from "../../utilities";
import { CardTitleComponentWithRightArrow } from "../../components/Cards";
import { AuthPRightLayout, BadgeLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";
import SoundWaveLoader from "../../components/Loader/SoundWaveLoader";

export default function ExternalCurrency() {
  const { accessLists } = useSelector((state) => state.auth);

  const { external } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputs, setInputs] = useState([]);

  const handleChange = (id) => {
    if (inputs.includes(id)) {
      let temp = inputs;
      const idToRemove = id;
      const filteredArray = temp.filter((item) => item !== idToRemove);
      return setInputs(filteredArray);
    }
    setInputs((prev) => [...prev, id]);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "externalCurrency",
        action: "ACTION_GET_RETURNED_EXTERNAL",
        payload: {
          choices: inputs,
        },
      };
      await dispatch(PostHandler(req));

      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  // api calls
  const init = async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "externalCurrency",
        action: "ACTION_GET_EXTERNAL",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    kycStatus?.status === "Completed" && init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  useEffect(() => {
    if (external?.choices?.length > 0) setInputs([...external?.choices]);
  }, [external?.choices]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item md={3} lg={4}>
            <Box
              sx={{
                pt: "32px",
                mb: "15px",
              }}
            >
              <Header5Layout text="External Currency" fs="16px" />

              <AuthPRightLayout
                text="Select what you want to recieve as external payment currencies."
                fs="16px"
                lh="22px"
                mt="10px"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={9} lg={8}>
            {kycStatus?.status !== "Completed" ? (
             
                <KYCBLOCK />
            ) : (
              <>
                <CardLayout
                  component={<>
                    <CardTitleComponentWithRightArrow mainTitle="Manage External Currency" noArrow={true} />

                    {/* {choices?.} */}
                    {isFetching ? (
                      <Box sx={{ height: '250px', display: 'grid', placeContent: 'center' }}>
                        <SoundWaveLoader />
                      </Box>
                    ) : (
                      <Box sx={{ p: { lg: '20px', xs: '15px' } }}>
                        <Grid container>
                          {external?.tokens?.length > 0 &&
                            external?.tokens?.map((item, index) => (
                              <Grid item key={item?.id + index} lg={3} xs={12} sm={6} md={4} sx={{ p: '5px' }}>
                                <CardLayout
                                  component={<Box
                                    sx={{
                                      p: "16px 0px",
                                      display: "flex",
                                      gap: "15px",
                                      alignItems: "center",
                                      justifyContent: 'center'
                                    }}
                                  >

                                    <input
                                      type="checkbox"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      value={true}
                                      onChange={() => handleChange(item?.id)}
                                      checked={inputs.includes(item?.id)}
                                    />

                                      <Box
                                      sx={{
                                        width: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={item?.logo}
                                        alt={item?.logo}
                                        height="25px"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                        alignItems: "flex-start",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          gap: "8px",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "27.9px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {item?.token}
                                        </Typography>
                                        <BadgeLayout text={item?.network} />
                                      </Box>
                                    </Box>
                                  </Box>}
                                />

                              </Grid>
                            ))}
                        </Grid>
                        <Box sx={{ mt: '15px', p: '5px' }}>
                          {hasAccess(["Externalcurrency-update"], accessLists) && (
                            <>
                              {isSubmitting ? (
                                <ButtonLoader maxWidth="100px" />
                              ) : (
                                <PrimaryBlackButton
                                  title="Save"
                                  text="Save"
                                  my="20px"
                                  onClick={handleSubmit}
                                />
                              )}
                            </>
                          )}
                        </Box>
                      </Box>

                    )}
                  </>}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
