import React from "react";
import { Box, Typography } from "@mui/material";
const selectedTheme = localStorage.getItem('theme') || 'light';

export default function WatchlistListComponent(props) {
  const {
    cryptoName,
    cryptoShortName,
    amount,
    increaseAmount,
    icon,
    showCurrency,
  } = props;
  return (
    <Box
      sx={{
        p: "10px",
        borderTop: selectedTheme !== 'dark' && "1px solid var(--neutral-200, #E3E3E8)",
        display: "flex",
        gap: "12px",
        alignItems: "center",
      }}
    >
      <img src={icon} alt={icon} width="25px" style={{ minWidth: "25px" }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              textTransform: "capitalize",
            }}
          >
            {cryptoName}
          </Box>
          <Typography
            sx={{
              color: "var(--neutral-400, #9C9CAB)",
              textAlign: "center",
              fontFeatureSettings: `'ss06' on`,
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 500,
              letterSpacing: "0.06px",
            }}
          >
            {cryptoShortName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: 400,
              letterSpacing: "0.06px",
              textTransform: "uppercase",
            }}
          >
            {showCurrency} {amount}
          </Box>
          {increaseAmount ? (
            <Box sx={{ display: "flex", gap: "10px" }}>
              {" "}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2011_5633)">
                  <path
                    d="M12 10L8 6L4 10H12Z"
                    fill="#11CF8B"
                    stroke="#11CF8B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2011_5633">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <Typography
                sx={{
                  color: "var(--main-colors-black, #0E0637)",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "16.2px",
                }}
              >
                {increaseAmount}%
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
}
