import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AuthPRight } from "../../components/Helpers/helperComponents";
import { PrimaryBlackButton, PrimaryButton2 } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { PrimaryInputComponents } from "../../components/Style";
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";

const ChangeEmail = () => {
  const { changeEmail, changeEmailId } = useSelector(
    (state) => state?.settings
  );
  const [inputs, setInputs] = useState({});
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [isVerifyShow, setIsVerifyShow] = useState(false);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (
      (e.target.name === "existingEmailOtp" ||
        e.target.name === "newEmailOtp") &&
      e.target.value?.length > 6
    )
      return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "initiateEmailChange",
        action: "ACTION_INITIATE_CHANGE_EMAIL",
        payload: {
          newEmail: inputs?.newEmail,
        },
        reqPayload: {
          changeEmail: inputs?.newEmail,
        },
      };

      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) setIsVerifyShow(true);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const resendOtp = async () => {
    try {
      setIsOtpLoading(true);
      const req = {
        url: "resendEmailOtp",
        payload: {
          newEmail: changeEmail,
        },
      };

      await dispatch(PostHandler(req));
      setIsOtpLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerifyAndChangeEmail = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "changeEmail",
        action: "REMOVE_CHANGE_EMAIL",
        payload: {
          existingEmailOtp: inputs?.existingEmailOtp,
          newEmailOtp: inputs?.newEmailOtp,
          emailReqId: changeEmailId,
        },
      };

      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) setIsVerifyShow(false);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.newEmail ? false : true);
  }, [inputs]);

  useEffect(() => {
    setIsSubmitDisable(
      inputs?.newEmailOtp && inputs?.existingEmailOtp ? false : true
    );
  }, [inputs?.newEmailOtp, inputs?.existingEmailOtp]);

  return (
    <Grid container>
      <Grid item md={3} lg={4}>
        <Box
          sx={{
            pt: "32px",
            mb: "15px",
          }}
        >
          <Header5Layout text="Change Email" fs="16px" />
          <AuthPRightLayout
            text="Remember not to share your otp with anyone"
            fs="16px"
            lh="22px"
            mt="10px"
          />

          <Header5Layout text="Steps" fs="16px" mt="30px" />

          <AuthPRightLayout text="1. add new email" fs="16px" lh="22px" mt="10px" />
          <AuthPRightLayout
            text="2. verify old and new email ids with otps we sended your emails"
            fs="16px"
            lh="22px"
            mt="10px"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9} lg={8}>

        <CardLayout
          p="15px"
          component={<>
            {!isVerifyShow ? (
              <>
                <InputLabels label="New Email" mb="5px" />
                <PrimaryInputComponents
                  id="newEmail"
                  type="email"
                  onChange={handleChange}
                  placeholder="Enter email"
                  name="newEmail"
                  value={inputs?.newEmail || ""}
                />

                <Box sx={{ mt: "15px" }}>
                  {isLoading ? (
                    <Button variant="contained" title="submit" disabled>
                      <CircularProgress size="1.7rem" />
                    </Button>
                  ) : (
                    <PrimaryBlackButton
                      onClick={handleSubmit}
                      disabled={isDisable}
                      text="Sent Request"
                    />
                  )}
                </Box>
              </>
            ) : (
              <>
                <InputLabels label="Verify Existing Email" mt="30px" />
                <AuthPRight
                  text={`Enter six digit otp we sended your existing email `}
                  fs="16px"
                  lh="22px"
                  mt="10px"
                />
                <PrimaryInputComponents
                  id="existingEmailOtp"
                  type="number"
                  onChange={handleChange}
                  placeholder="Enter OTP"
                  name="existingEmailOtp"
                  value={inputs?.existingEmailOtp || ""}
                />

                <InputLabels label="Verify New Email" mt="30px" />
                <AuthPRight
                  text={`Enter six digit otp we sended your new email: ${changeEmail || ""
                    } `}
                  fs="16px"
                  lh="22px"
                  mt="10px"
                />
                <PrimaryInputComponents
                  id="newEmailOtp"
                  placeholder="Enter OTP"
                  type="number"
                  onChange={handleChange}
                  value={inputs?.newEmailOtp || ""}
                  name="newEmailOtp"
                />

                {!isOtpLoading ? (
                  <Box
                    sx={{ display: "flex", alignItems: "center", py: "20px" }}
                  >
                    <AuthPRight
                      text={`Did not get otp? `}
                      fs="16px"
                      lh="22px"
                      mt="0px"
                    />
                    <Box
                      onClick={resendOtp}
                      style={{
                        color: "var(--main-colors-primary, #5235E8)",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Resend OTP
                    </Box>
                  </Box>
                ) : (
                  <CircularProgress />
                )}

                <Box>
                  {isSubmitting ? (
                    <Button variant="contained" title="submit" disabled>
                      <CircularProgress size="1.7rem" />
                    </Button>
                  ) : (
                    <PrimaryButton2
                      title="Submit"
                      onClick={handleVerifyAndChangeEmail}
                      disabled={isSubmitDisable}
                    />
                  )}
                </Box>
              </>
            )}
          </>}
        />


      </Grid>
    </Grid>
  );
};

export default ChangeEmail;
