import React from "react";
import { Box, Typography } from "@mui/material";
export default function POSCryptoSearchBoxes({
  data,
  active,
  swapValue,
  loading
}) {


  return (
    <Box
      key={data?.id + "fd"}
      sx={{
        cursor: "pointer",
        borderRadius: "5px",
        border:
          active ? "none" : "0.75px solid var(--Neutral-300, #EFF0F6)",
        background: loading === data?.id ? "#03a9f4" : active && "#495ECA",
        color: active ? "#FFF" : "#170F49",
        boxShadow:
          active
            ? "0px 4px 12px rgba(73, 94, 202, 0.4)"
            : "0px 1.5px 4.5px 0px rgba(73, 94, 202, 0.18)",
        height: "66px",
        display: "flex",
        alignItems: "center",
        justifyContent: active ? "space-between" : "center",
        minWidth: "145px",
        textDecoration: "none",
        transition: "background 0.3s, color 0.3s, box-shadow 0.3s",
        position: "relative",
        width: '100%',
        p: '5px'
      }}
    >
      {loading === data?.id ? (
        <Box
          sx={{
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box className="loader" />
        </Box>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: '5px'
              }}
            >
              <Box
                component="img"
                src={data?.logo}
                sx={{ height: "24px!important", width: "auto" }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: '5px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "SF Pro Display",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                  }}
                >
                  {data?.token}
                </Typography>
                <Typography
                  sx={{
                    color: active ? "#000" : "#FFF",
                    fontFamily: "SF Pro Display",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    background: active ? "#FFF" : "#000",
                    p: '1px 2px',
                    borderRadius: '3px'
                  }}
                >
                  {data?.network}
                </Typography>
              </Box>
            </Box>
            {active && swapValue && swapValue}
          </Box>

          {active && <Box>
            <Box className="loader2" />

          </Box>}
          {/* <Box sx={{
            position: 'absolute',
            fontSize: '11px',
            border: '1.5px solid #fff',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            display: 'grid',
            placeContent: 'center',
            fontWeight: 600,
            right: '5px',
            top:'5px'
          }}>15s</Box> */}

        </>
      )}
    </Box>
  );
}
