import React, {useEffect} from "react";
import { Box, Typography } from "@mui/material";
import ResponsiveAppBar from "../../components/appbar";
import Logo from "../../assets/img/logo/logo-light2.png";
import ComponentOne from "./components/componentOne";
import ContactMailComponent from "./components/ContactMailComponent";
import ComponentEight from "./components/componentThree";
import { useNavigate } from "react-router-dom";
export default function ContactUsComponent() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  useEffect(() => {
    // Reset the scroll position to the top on page load
    window.scrollTo(0, 0);
   
  }, []);
  return (
    <div className="main">
      {" "}
      <Box
        sx={{
          margin: "20px 15px 0px",
          background: "black",
          height: "500px",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
          zIndex: 2,
          "@media (max-width:800px)": {
            p: "0!important",
            margin: "10px 8px 0px",
          },
          "@media (max-width:600px)": { height: "400px!important" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 0px 0px 80px ",
            "@media (max-width:800px)": { p: "0!important" },
          }}
        >
          <Box
            onClick={handleClick}
            component="img"
            src={Logo}
            sx={{
              height: "50px",
              mt: "22px",
              "@media (max-width:800px)": {
                pl: "40px!important",
              },
              "@media (max-width:500px)": {
                height: "40px!important",
                mt: "18px",
              },
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              background: "#FFFFFF",
              color: "black!important",
              maxWidth: "800px",
              maxHeight: "65px",
              borderRadius: "0px 0px 0px 30px!important",

              "@media (max-width:800px)": {
                pl: 0,
              },
              "@media (max-width:500px)": {
                height: "60px!important",
              },
            }}
          >
            <ResponsiveAppBar />
          </Box>
        </Box>
        <Box
          sx={{
            mt: "109px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection:"column"
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFeatureSettings: " 'ss06' on",
              fontFamily: "Poppins",
              fontSize: "4.375rem",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "77px",
              letterSpacing: "-0.7px",
              "@media (max-width:800px)": {
                textAlign: "center",
                fontSize: "2.075rem",
                lineHeight: "50px",
              },
            }}
          >
            We’re here to help
          </Typography>
          {/* <DescriptionComponent
              text={<>
               For any question you have in mind—whether as a merchant or user—send a message our way and we’ll be speedily in touch with you. 
              </>}
              mt="20px"
              fs="20px"
              mw="700px"
              ta="center"
            /> */}
        </Box>
      </Box>
      <ComponentOne />
      <ContactMailComponent />
      <ComponentEight />
    </div>
  );
}
