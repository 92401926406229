import React, { useState, useEffect } from "react";
import BasicTableWithBody, {
  NoRecordInTable,
  StyledTableCell,
  StyledTableRow,
  TableDataLoad,
} from "../../components/Tables";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import moment from "moment";
import StatusShow from "../../components/Tables/StatusShow";
import { ButtonLoader, PrimaryBlackButton } from "../../components/Buttons";
import { getTotalPage, hasAccess } from "../../utilities";
import { Box, Grid } from "@mui/material";
import KYCBLOCK from "../../components/Kyc";
import BankAccountInfo, {
  WalletInfo,
} from "../../components/Kyc/BankAccountInfo";
import WithdrawalInvoice from "./WithdrawalInvoice";
import { CardTitleComponentWithFilterComponent } from "../../components/Cards";
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";

const WithdrawalRequests = () => {
  const { accessLists } = useSelector((state) => state.auth);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = 10;
  const { withdrawalRequestList } = useSelector((state) => state?.transactions);
  const dispatch = useDispatch();

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "withdrawalRequest",
        action: "ACTION_GET_WR",
        params: { pageNumber: page },
      };

      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.withdrawalRequests?.count)
        setTotalPages(
          parseInt(
            getTotalPage(res?.data?.data?.withdrawalRequests?.count, perPage)
          )
        );
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const newWithdrawalRequest = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "withdrawalRequest",
        action: "ACTION_GET_WR_RETURNED",
        payload: {
          type: withdrawalRequestList?.userDetails?.kycdetails?.tron_address
            ? "crypto"
            : "bank",
        },
      };

      await dispatch(PostHandler(req));
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Grid container>
        <Grid item md={3} lg={4}>
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5Layout text="Withdrawal Request" fs="16px" />

            <AuthPRightLayout
              text="Initiate withdrawal request we will send you the amount."
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={8}>
          {withdrawalRequestList?.userDetails?.kycdetails?.status !==
            "Completed" ? (

            <KYCBLOCK />
          ) : (
            <CardLayout
              component={<>
                <CardTitleComponentWithFilterComponent
                  mainTitle="Withdrawal Requests"
                  component={
                    hasAccess(["Withdrawalrequest-create"], accessLists) && (
                      <>
                        {isSubmitting ? (
                          <ButtonLoader maxWidth="100px" />
                        ) : (
                          <PrimaryBlackButton
                            title="New Withdrawal Request"
                            text="New Withdrawal Request"
                            onClick={newWithdrawalRequest}
                          />
                        )}
                      </>
                    )
                  }
                />


                <Box sx={{ px: '15px', mt: '15px' }}>
                  {withdrawalRequestList?.userDetails?.kycdetails?.tron_address ? (
                    <WalletInfo
                      eth={
                        withdrawalRequestList?.userDetails?.kycdetails
                          ?.ethereum_address
                      }
                      tron={
                        withdrawalRequestList?.userDetails?.kycdetails?.tron_address
                      }
                      bnb={
                        withdrawalRequestList?.userDetails?.kycdetails
                          ?.binance_address
                      }
                    />
                  ) : (
                    <BankAccountInfo
                      bankName={
                        withdrawalRequestList?.userDetails?.kycdetails?.bankname
                      }
                      name={
                        withdrawalRequestList?.userDetails?.kycdetails?.fullnamebank
                      }
                      accountNo={
                        withdrawalRequestList?.userDetails?.kycdetails
                          ?.accountnumber
                      }
                      ifsc={withdrawalRequestList?.userDetails?.kycdetails?.ifsc}
                    />
                  )}
                </Box>

                <Box sx={{ mt: "20px", borderTop: '1px solid #0001' }}>
                  <BasicTableWithBody
                    minWid="100%"
                    tableHead={["Created At", "Status", "Invoice"]}
                    tableBody={
                      <>
                        {isFetching ? (
                          <TableDataLoad />
                        ) : withdrawalRequestList?.withdrawalRequests?.rows
                          ?.length > 0 ? (
                          withdrawalRequestList?.withdrawalRequests?.rows?.map(
                            (row) => (
                              <StyledTableRow key={row?.id}>
                                <StyledTableCell component="th" scope="row">
                                  {moment(row.createdAt).format("llll")}
                                </StyledTableCell>
                                {/* <StyledTableCell align="left">${row.amount}</StyledTableCell> */}
                                <StyledTableCell align="left">
                                  <StatusShow status={row?.status} />
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                  {row?.status === "Completed" && (
                                    <WithdrawalInvoice data={row} />
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          )
                        ) : (
                          <NoRecordInTable colSpan="7" />
                        )}
                      </>
                    }
                    pages={totalPages}
                    page={page}
                    setPage={setPage}
                  />
                </Box>
              </>}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default WithdrawalRequests;
