import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import HorizontalExternalQRPageStepper from "./posQRExternalStepper";
import { QRCode } from "react-qrcode-logo";
import qrlogo from "../../../assets/img/pos/logo.png";
import { CopyToClipboardButton } from "../../../components/Helpers/helperComponents";
import { CountdownComponentWithLoader } from "../../../components/Helpers/countdown";
import { ResourceLoader } from "../../../components/Helpers/svg";
import { InputLabels } from "../../../components/Helpers/label";
import { addBigNumber, getSecondsDifference, Numberz } from "../../../utilities";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PendingAmountShow from "../Component/PendingAmountShow";
export default function POSQRExternal({ loading, showLog, paymentData }) {
  const [partialShow, setPartialShow] = useState(false);
  useEffect(() => {
    setPartialShow(paymentData?.status === "Partial Sent" ? true : false);
  }, [paymentData?.status]);
  return (
    <Box>
      <Box
        sx={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center',
          background: "#f0f0f169",
          p: '5px',
          borderRadius: '5px',
          mt: '10px'
        }}
      >
        <InputLabels label="Pay" mt="0px" ta="center" />


        <Box sx={{
          display: 'flex'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                fontSize: {
                  sm: "18px",
                  xs: '16px'
                },
                fontWeight: 600
              }}
            >
              {paymentData?.status === "priceChange" ||
                (Numberz(paymentData?.newAmount) > 0 && Numberz(paymentData?.cryptoAmount) !== Numberz(paymentData?.newAmount))
                ? paymentData?.exchangeFee ? addBigNumber(paymentData?.exchangeFee, paymentData?.newAmount) : paymentData?.newAmount
                : paymentData?.cryptoAmountWithFee || paymentData?.cryptoAmount}{" "}

              {paymentData?.token} ({paymentData?.network})
            </Typography>
          </Box>


          <CopyToClipboardButton
            content={paymentData?.status === "priceChange" || (Numberz(paymentData?.newAmount) > 0 && Numberz(paymentData?.cryptoAmount) !== Numberz(paymentData?.newAmount))
              ? paymentData?.exchangeFee ? addBigNumber(paymentData?.exchangeFee, paymentData?.newAmount) : paymentData?.newAmount
              : paymentData?.cryptoAmountWithFee || paymentData?.cryptoAmount}
            background="#fff"
            fontsize="15px!important"
          />
        </Box>
      </Box>

      {(paymentData?.status === "priceChange" ||
        (paymentData?.newAmount &&
          paymentData?.cryptoAmount !== paymentData?.newAmount)) && (
          <Box
            sx={{
              background: "#495ECA",
              p: "3px 5px",
              fontWeight: 600,
              borderRadius: "5px",
              fontSize: "9px",
              color: "#FFF",
              cursor: "pointer",
              display: "flex",
              gap: "3px",
              alignItems: "center",
              maxWidth: '118px'
            }}
            onClick={() => showLog()}
          >
            Price change detected
            <InfoOutlinedIcon
              sx={{
                color: "#FFF",
                fontSize: "11px !important",
              }}
            />
          </Box>
        )}
      <Box
        sx={{
          borderRadius: "15px",
          background: "#FFF",
          gap: "10px",
          width: "100%",
        }}
      >
        {loading ? (
          <Box sx={{ py: "10px", width: '100%', display: 'grid', placeContent: 'center' }}>
            <ResourceLoader />
          </Box>
        ) : (
          <Box
            sx={{
              mt: '10px',
              display: "grid",
              placeContent: "center",
            }}
          >
            <QRCode
              value={paymentData?.publickey}
              logoImage={qrlogo}
              padding={0}
              size={200}
              logoWidth={40}
            />
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >



          {/* <InputLabels label="Address" mt="10px" ta="center" /> */}

          {/* public key */}
          {!loading && paymentData?.publickey && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: '10px',
                background: '#f0f0f169',
                p: '5px',
                borderRadius: '10px',
                fontSize: {
                  sm: "14px",
                  xs: '10px'
                },
                fontWeight: 600,
                wordBreak: "break-word",
                gap: '5px'
              }}
            >
              <Box>
                {paymentData?.publickey}
              </Box>

              <CopyToClipboardButton
                content={paymentData?.publickey}
                background="#fff"
                fontsize="14px!important"
              />
            </Box>
          )}

          <Box sx={{
            fontSize: '12px',
            display: 'flex',
            p: '10px',
            background: '#a17902f2',
            color: '#ffeb3b',
            alignItems: 'center',
            borderRadius: '5px',
            gap: '3px',
            mt: '10px',
            fontWeight: 600,
            mb: '10px'
            // justifyContent: 'center',
          }}>
            <Tooltip
              title="The amount of digital assets is dependent on the market and is subject to change. In case of a major change in the asset price, the amount can be changed mid transaction also."
              arrow
            >
              <InfoOutlinedIcon
                sx={{
                  color: "#ffeb3b",
                  fontSize: '16px'
                }}
              />
            </Tooltip>
            Only send {`${paymentData?.token} (${paymentData?.network})`} assets to this address. Other assets will be lost forever.
          </Box>

          {/* partial show */}
          {partialShow && (
            <PendingAmountShow
              pendingAmount={paymentData?.exchangeFee ? addBigNumber(paymentData?.pendingAmount, paymentData?.exchangeFee) : paymentData?.pendingAmount}
              paymentStatus={paymentData?.status}
              token={paymentData?.token}
              network={paymentData?.network}
            />
          )}


        </Box>
      </Box>

      {
        !loading && paymentData?.expireDate && (
          <CountdownComponentWithLoader
            expireIn={getSecondsDifference(new Date(), paymentData?.expireDate)}
            colour="red"
            content="Your Transaction will expire in "
          />
        )
      }
      {!loading && <HorizontalExternalQRPageStepper paymentData={paymentData} />}
    </Box >
  );
}
