import React from "react";
import { Box, Stack, Pagination, Typography } from "@mui/material";

const TablePaginations = (props) => {
  const { pages, page, setPage, totalSelectedItem } = props;
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <Box
      sx={{
        mt: "5px",
        mb: "15px",
        display: "flex!important",
        justifyContent: totalSelectedItem ? "space-between !important" : "end",
      }}
    >
      {totalSelectedItem > 0 && (
        <Typography>{totalSelectedItem} row selected</Typography>
      )}
      <Stack spacing={2} sx={{}}>
        <Pagination
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600px",
            lineHeight: "20px",
            color: "#110C2C",
            "& .MuiPaginationItem-root": {
              borderRadius: "5px",
              border: "none",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "white", // Customize the background color of the selected page
              color: "#110C2C", // Customize the text color of the selected page
              border: "1px solid #110C2C",
            },
            " .MuiPaginationItem-previousNext": {
              backgroundColor: "#343B41!important", // Customize the background color of the side buttons
              color: "#C4CDD5!important", // Customize the text color of the side buttons
              border: "1px solid #C4CDD5",
              "&:hover": {
                backgroundColor: "white!important", // Customize the background color on hover
                color: "black",
              },
            },
            " .MuiPaginationItem-previousNext.Mui-disabled": {
              backgroundColor: "#343B41!important", // Customize the background color of the side buttons
              color: "#C4CDD5!important", // Customize the text color of the side buttons
              "&:hover": {
                backgroundColor: "white!important", // Customize the background color on hover
                color: "black",
              },
            },
          }}
          count={pages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </Box>
  );
};

export default TablePaginations;
