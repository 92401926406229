import { Box } from "@mui/material";
import React from "react";
import {
  StepTypo,
} from "../../../components/Helpers/helperComponents";
import { useLocation } from "react-router-dom";
import { Header5Layout } from "../../../components/Layouts/AllLayOuts";

const kycMenuList = [
  {
    title: 'Identity Verification',
    link: 'identity-verification'
  },
  {
    title: 'Company Verification',
    link: 'company-verification'
  },
  {
    title: 'Withdrawal Verification',
    link: 'withdrawal-verification'
  }
]
const StepMenu = () => {
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          md: "row",
          lg: "column",
        },
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {kycMenuList?.map((item, index) =>
        <Box
          key={item?.title + index}
          sx={{
            mb: "15px",
            background: new RegExp(item.link).test(pathname) && "#8859c8",

            // background: item?.link?.test(pathname) && "#8859c8",
            display: "flex",
            gap: "10px",
            borderRadius: "10px",
            alignItems: 'center'
          }}
        >
          <StepTypo title={index + 1} active={new RegExp(item.link).test(pathname)} />
          <Header5Layout
            text={item?.title}
            fs="16px"
            mt="0px"
            clr={new RegExp(item.link).test(pathname) && "#fff"}

          />
        </Box>
      )}

    </Box>
  );
};

export default StepMenu;
