import { Box } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
// import { ClickAwayListener } from "@mui/base/ClickAwayListener";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { ContentRow, DeleteDialog } from "../../components/Dialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { hasAccess } from "../../utilities";
import { StyledTableCell, StyledTableRow } from "../../components/Tables";
import { PrimaryIconButton } from "../../components/Buttons";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Header5Layout } from "../../components/Layouts/AllLayOuts";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";

const ApiKeyList = ({ item, setUpdateData, setComponentShow }) => {
  const { accessLists } = useSelector((state) => state.auth);
  const [loadings, setLoadings] = useState({
    delApiKey: false,
    lockApiKey: false,
  })
  const dispatch = useDispatch();
  // const handleUpdateClick = (item) => {
  //   setUpdateData(item);
  //   setComponentShow("update");
  //   handleClickAway();
  // };


  const [handleModals, setHandleModals] = useState({
    seeDetails: false,
    delApiKey: false,
    lockApiKey: false,
  });

  const handleLock = async (id, lock) => {
    try {
      setLoadings(prev => ({ ...prev, lockApiKey: true }))
      const req = {
        url: "lockApiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: {
          keyId: id,
          lock: lock,
        },
      };
      await dispatch(PostHandler(req));
      setLoadings(prev => ({ ...prev, lockApiKey: false }))
      setHandleModals((prev) => ({ ...prev, lockApiKey: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoadings(prev => ({ ...prev, delApiKey: true }))

      const req = {
        url: "deleteApiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: {
          keyId: id,
        },
      };
      await dispatch(PostHandler(req));
      setLoadings(prev => ({ ...prev, delApiKey: true }))

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <StyledTableRow key={item?.id}>

        <StyledTableCell align="left">
          <Header5Layout text={item?.name} fs="16px" mt="0" />
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(item?.createdAt).format("DD-MM-YY")}
        </StyledTableCell>

        <StyledTableCell align="left">
          <Box sx={{ display: 'flex', gap: '5px' }}>
            <PrimaryIconButton
              title="See details"
              text={<VisibilityOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900, }} />}
              onClick={() => setHandleModals({ seeDetails: true })}
            />

            {hasAccess(["Apikey-update"], accessLists) && (
              <PrimaryIconButton
                title={item?.islocked ? "Click to unlock" : "Click to lock"}
                text={<LockOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900 }} />}
                background={item?.islocked && "#bb86fc"}
                onClick={() => setHandleModals({ lockApiKey: true })}
              />
            )}

            {hasAccess(["Apikey-delete"], accessLists) && (
              <PrimaryIconButton
                title="Click to delete"
                text={<DeleteOutlineOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900 }} />}
                background={"#cf6679"}
                onClick={() => setHandleModals({ delApiKey: true })}
              />
            )}
          </Box>
        </StyledTableCell>



      </StyledTableRow>

      {/* {handleModals?.seeDetails && (
        <DetailsModal
          isShow={true}
          accountInfo={item}
          opens={handleModals?.seeDetails}
          close={() => setHandleModals({ seeDetails: false })}
        />
      )} */}

      {handleModals?.seeDetails && (
        <LeftToRightModal
          modalTitle="See Api Key Details"
          width="470px"
          modalBody={
            <Box sx={{
              p: '15px'
            }}>
              <ContentRow left="Name" right={item?.name} />
              <ContentRow left="Type" right={item?.type} />
              <ContentRow
                left="Generated At"
                right={moment(item?.createdAt).format("llll")}
              />
              <ContentRow left="Api Key" right={item?.key} isCopy={true} />
            </Box>
          }
          openTrigger={handleModals?.seeDetails}
          closeModal={() => setHandleModals({ seeDetails: false })}
        />
      )}

      {handleModals?.delApiKey && (
        <DeleteDialog
          isShow={true}
          data={item}
          title="Sure you want to delete?"
          content="Are you sure you want to Delete this?"
          opens={handleModals?.delApiKey}
          execute={() => handleDelete(item?.id)}
          close={() => setHandleModals({ delApiKey: false })}
          executeLoading={loadings?.delApiKey}
        />
      )}

      {handleModals?.lockApiKey && (
        <DeleteDialog
          isShow={true}
          data={item}
          title={`Sure you want to ${item?.islocked ? "Unlock" : "lock"}?`}
          content={`Are you sure you want to  ${item?.islocked ? "unlock" : "lock"
            } this?`}
          opens={handleModals?.lockApiKey}
          execute={() => handleLock(item?.id, item?.islocked ? false : true)}
          close={() => setHandleModals({ lockApiKey: false })}
          executeLoading={loadings?.lockApiKey}
        />
      )}
    </>
  );
};

export default ApiKeyList;
