import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import LeftGridComponentOne from "./components/Left/LeftGridComponentOne";
import LeftGridComponentTwo from "./components/Left/LeftGridComponentTwo";
import LeftGridComponentThree from "./components/Left/LeftGridComponentThree";
import { GetHandler } from "../../Redux/RequestHandlers";
import { useDispatch } from "react-redux";
import RightGridComponentThree from "./components/Right/RightGridComponentThree";
import RecentTransactions from "./components/Right/RightGridComponentTwo";
// import LeftToRightModal from "../../components/Dialog/LeftToRightModal";

export default function DashboardComponent() {
  const dispatch = useDispatch();
  const init = async () => {
    try {
      let req = {
        url: "dashboard",
        action: "ACTION_GET_DASHBOARD",
      };
      await dispatch(GetHandler(req));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <LeftGridComponentOne />
      <Grid
        container
        sx={{
          overflow: "hidden!important",
          p: {
            xs: 0,
            lg: "5px"
          },
          mt: "5px"
        }}
      >
        <Grid item xs={12} md={6} lg={8} sx={{
          pr: {
            md: "4px"
          }
        }}>
          <LeftGridComponentTwo />
          <RecentTransactions />
        </Grid>
        <Grid item xs={12} md={6} lg={4} sx={{ pl: { md: "8px" } }}>
          <LeftGridComponentThree />
          <RightGridComponentThree />
        </Grid>
      </Grid>
    </>
  );
}
