import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem } from "@mui/material";

import { InputLabels } from "../../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { ButtonLoader, PrimaryBlackButton } from "../../../components/Buttons";

export default function EditSubMerchant({ data }) {
  const { roles } = useSelector((state) => state?.roles);

  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const dispatch = useDispatch();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "merchantUsersUpdate",
        action: "ACTION_GET_USERS_RETURNED",
        payload: {
          ...inputs,
          email: data?.email,
        },
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);

      if (res?.data?.success) setInputs({});
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(!inputs.roleid ? true : false);
  }, [inputs]);

  return (
    <>
      <Box sx={{ p: "15px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <InputLabels label="Email" mt="0px" />
            <PrimaryInputComponents
              id="email"
              name="email"
              // onChange={handleChange}
              value={data?.email || ""}
              placeholder="-"
              disabled={true}
            />
            
          </Box> */}

          <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
            <InputLabels label="Role" />
            <Select
              labelId="demo-simple-select-label"
              id="selectRoleA"
              onChange={handleChange}
              name="roleid"
              value={inputs?.roleid || data?.role?.id}
              sx={{
                height: "48px",
                width: "100%",
                "&:hover fieldset": {
                  border: "1px solid #495ECA!important",
                },
                "&:focus-within fieldset, &:focus-visible fieldset": {
                  border: "1px solid #495ECA!important",
                  boxShadow: "0 0 0 2px #495eca6b",
                },
              }}
            >
              <MenuItem key={324} value="-">
                <Typography sx={{ color: "grey" }}>-</Typography>
              </MenuItem>

              {roles?.map((item) => (
                <MenuItem key={item?.id} value={item?.id}>
                  {item?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            mt: "20px",
            width: "100%",
          }}
        >
          {isLoading ? (
            <ButtonLoader />
          ) : (
            <PrimaryBlackButton
              title="Save"
              onClick={handleUpdate}
              disabled={isDisable}
              maxWidth="100px"
            />
          )}
        </Box>
      </Box>
    </>
  );
}
