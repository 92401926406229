import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { InputLabels } from '../../../components/Helpers/label'
import { PrimaryInputComponents } from '../../../components/Style'
import { useDispatch, useSelector } from 'react-redux'
import { PostHandler } from '../../../Redux/RequestHandlers'
import { ButtonLoader, PrimaryBlackButton } from '../../../components/Buttons'

const VerifySubMerchantOTP = () => {
    const { verifyUser } = useSelector(state => state?.roles);
    const [inputs, setInputs] = useState(null);
    const [isLoadings, setIsLoadings] = useState(false);
    const [isDisable, setIsDisable] = useState(true);
    const dispatch = useDispatch();


    const handleChange = (e) =>
        setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const handleSubmit = async () => {
        try {
            setIsLoadings(true);
            if (!verifyUser?.id) return setIsLoadings(false);
            const req = {
                url: "merchantUsersVerify",
                action: "ACTION_GET_USERS_RETURNED_ADDED",
                payload: { ...inputs, uid: verifyUser?.id },
            };
            await dispatch(PostHandler(req));
            setIsLoadings(false);
        } catch (error) {
            console.error(error);
        }
    };

    const resendOtp = async () => {
        try {
            setIsLoadings(true);
            if (!verifyUser?.id) return setIsLoadings(false);

            const req = {
                url: "merchantUsersGetOtp",
                payload: { uid: verifyUser?.id },
            };
            await dispatch(PostHandler(req));
            setIsLoadings(false);
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        setIsDisable(inputs?.otp?.length === 6 && verifyUser?.id ? false : true);
    }, [inputs, verifyUser?.id]);
    return (
        <Box
            sx={{
                p: '15px'
            }}>
            {verifyUser?.otp &&
                <Typography sx={{
                    fontSize: '14px',
                    mb: '15px'
                }}>
                    One Time Password has been sent to {verifyUser?.email} mail account. please enter to verify it.
                </Typography>
            }

            <Typography sx={{
                fontSize: '14px',
                mb: '10px'
            }}>

                Did not get OTP?
            </Typography>
            {!isLoadings ? (
                <PrimaryBlackButton
                    title="Click to Get Otp"
                    onClick={resendOtp}
                    text="Get OTP"
                />
            ) : (
                <CircularProgress size="1.5rem" />
            )}



            <InputLabels label="OTP" mt="10px" />
            <PrimaryInputComponents
                id="subMerchantOtp"
                name="otp"
                type="number"
                onChange={handleChange}
                value={inputs?.otp || ""}
                placeholder="Enter 6 digit otp here."
                required
            />

            <Box sx={{ mt: '1.5rem' }}>
                {isLoadings ? (
                    <ButtonLoader />
                ) : (
                    <PrimaryBlackButton
                        text="Verify User"
                        onClick={handleSubmit}
                        disabled={isDisable}
                    />
                )}
            </Box>
        </Box>
    )
}

export default VerifySubMerchantOTP