import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, FormControlLabel, TableRow } from "@mui/material";
import {
  NewBasicTableWithBody,
  NoRecordInTable,
  PermissionTC,
  TableDataLoad,
} from "../../components/Tables";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import { PrimaryBlackButton } from "../../components/Buttons";

export const CheckboxPermission = ({
  readonly,
  inputs,
  data,
  handleInputChange,
}) => (
  <FormControlLabel
    control={
      <input
        disabled={readonly}
        type="checkbox"
        checked={inputs?.includes(data?.id)}
        onChange={(e) => handleInputChange(e, data?.id, data)}
      />
    }
    sx={{
      p: "5px",
      borderRadius: "5px",
      fontSize: "12px",
    }}
  />
);

export default function GetPermissions({ id }) {
  const [inputs, setInputs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isDefaultChecked, setIsDefaultChecked] = useState({});
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (e, value, obj) => {
    if (e.target.checked) {
      setIsDefaultChecked((prev) => ({ ...prev, [value]: true }));
      setInputs((prev) => [...prev, value]);
    } else {
      setIsDefaultChecked((prev) => ({ ...prev, [value]: false }));

      let arr = inputs;
      if (arr.indexOf(value) !== -1) arr.splice(arr.indexOf(value), 1);
      setInputs(arr);
    }
  };
  const { permissionList, rolesPermissions } = useSelector(
    (state) => state?.roles
  );

  const getPermissionsOfRole = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "rolePermissions",
        action: "ACTION_GET_ROLES_PERMISSIONS",
        params: { rid: id },
      };
      const res = await dispatch(GetHandler(req));
      setIsFetching(false);
      setIsReadOnly(res?.data?.data?.readonly);
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "permissions",
        action: "ACTION_GET_PERMISSIONS",
        params: { pageNumber: 1 },
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);

    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (id) {
      getPermissionsOfRole();
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (permissionList) {
      setPermissions(Object.keys(permissionList));
    }
  }, [permissionList]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "rolePermissionsUpdate",
        payload: {
          rid: id,
          permissions: inputs,
        },
      };
      await dispatch(PostHandler(req));
      getPermissionsOfRole();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setInputs(
      rolesPermissions?.length > 0
        ? rolesPermissions?.map((item) => item?.permissionid)
        : []
    );
  }, [rolesPermissions]);

  return (
    <Box sx={{ p: "15px", height: '93vh', overflowY: 'scroll' }}>
      <NewBasicTableWithBody
        minWid="100%"
        maxWid="100%"
        tableHead={["", "Read", "Create", "Update", "Delete"]}
        tableBody={
          <>
            {isFetching ? (
              <TableDataLoad />
            ) : permissions?.length > 0 ? (
              permissions?.map((items) => (
                <TableRow key={items} sx={{ height: '90px' }}>
                  <PermissionTC data={items} />
                  <PermissionTC data=
                    {permissionList[items]?.length > 0 &&
                      /read/.test(permissionList[items][0]?.name)
                      ? permissionList[items][0]?.id && (
                        <CheckboxPermission
                          inputs={inputs}
                          data={permissionList[items][0]}
                          readonly={isReadOnly}
                          handleInputChange={handleInputChange}
                        />
                      )
                      : /read/.test(permissionList[items][1]?.name)
                        ? permissionList[items][1]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][1]}
                            readonly={isReadOnly}
                            handleInputChange={handleInputChange}
                          />
                        )
                        : /read/.test(permissionList[items][2]?.name)
                          ? permissionList[items][2]?.id && (
                            <CheckboxPermission
                              inputs={inputs}
                              data={permissionList[items][2]}
                              readonly={isReadOnly}
                              handleInputChange={handleInputChange}
                            />
                          )
                          : /read/.test(permissionList[items][3]?.name) &&
                          permissionList[items][3]?.id && (
                            <CheckboxPermission
                              inputs={inputs}
                              data={permissionList[items][3]}
                              readonly={isReadOnly}
                              handleInputChange={handleInputChange}
                            />
                          )}
                  />


                  <PermissionTC data={permissionList[items]?.length > 0 &&
                    /create/.test(permissionList[items][0]?.name)
                    ? permissionList[items][0]?.id && (
                      <CheckboxPermission
                        inputs={inputs}
                        data={permissionList[items][0]}
                        handleInputChange={handleInputChange}
                        readonly={isReadOnly}
                      />
                    )
                    : /create/.test(permissionList[items][1]?.name)
                      ? permissionList[items][1]?.id && (
                        <CheckboxPermission
                          inputs={inputs}
                          data={permissionList[items][1]}
                          handleInputChange={handleInputChange}
                          readonly={isReadOnly}
                        />
                      )
                      : /create/.test(permissionList[items][2]?.name)
                        ? permissionList[items][2]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][2]}
                            handleInputChange={handleInputChange}
                            readonly={isReadOnly}
                          />
                        )
                        : /create/.test(permissionList[items][3]?.name) &&
                        permissionList[items][3]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][3]}
                            handleInputChange={handleInputChange}
                            readonly={isReadOnly}
                          />
                        )}
                  />




                  <PermissionTC data={permissionList[items]?.length > 0 &&
                    /update/.test(permissionList[items][0]?.name)
                    ? permissionList[items][0]?.id && (
                      <CheckboxPermission
                        inputs={inputs}
                        data={permissionList[items][0]}
                        readonly={isReadOnly}
                        handleInputChange={handleInputChange}
                      />
                    )
                    : /update/.test(permissionList[items][1]?.name)
                      ? permissionList[items][1]?.id && (
                        <CheckboxPermission
                          inputs={inputs}
                          data={permissionList[items][1]}
                          readonly={isReadOnly}
                          handleInputChange={handleInputChange}
                        />
                      )
                      : /update/.test(permissionList[items][2]?.name)
                        ? permissionList[items][2]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][2]}
                            readonly={isReadOnly}
                            handleInputChange={handleInputChange}
                          />
                        )
                        : /update/.test(permissionList[items][3]?.name) &&
                        permissionList[items][3]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][3]}
                            readonly={isReadOnly}
                            handleInputChange={handleInputChange}
                          />
                        )}
                  />



                  <PermissionTC data={permissionList[items]?.length > 0 &&
                    /delete/.test(permissionList[items][0]?.name)
                    ? permissionList[items][0]?.id && (
                      <CheckboxPermission
                        inputs={inputs}
                        data={permissionList[items][0]}
                        readonly={isReadOnly}
                        handleInputChange={handleInputChange}
                      />
                    )
                    : /delete/.test(permissionList[items][1]?.name)
                      ? permissionList[items][1]?.id && (
                        <CheckboxPermission
                          inputs={inputs}
                          data={permissionList[items][1]}
                          readonly={isReadOnly}
                          handleInputChange={handleInputChange}
                        />
                      )
                      : /delete/.test(permissionList[items][2]?.name)
                        ? permissionList[items][2]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][2]}
                            readonly={isReadOnly}
                            handleInputChange={handleInputChange}
                          />
                        )
                        : /delete/.test(permissionList[items][3]?.name) &&
                        permissionList[items][3]?.id && (
                          <CheckboxPermission
                            inputs={inputs}
                            data={permissionList[items][3]}
                            readonly={isReadOnly}
                            handleInputChange={handleInputChange}
                          />
                        )}
                  />
                </TableRow>
              ))
            ) : (
              <NoRecordInTable />
            )}
          </>
        }
      // pages={totalPages}
      // page={page}
      // setPage={setPage}
      />
      {isLoading ? (
        <Button variant="contained">
          <CircularProgress sx={{ color: "yellow" }} size="1rem" />
        </Button>
      ) : (
        !isReadOnly && (

          <PrimaryBlackButton
            text="Save"
            onClick={handleSubmit}
          />
        )
      )}

    </Box>
  );
}
