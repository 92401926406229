import {
  Box,
  Container,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetHandler, uploadHandler } from "../../../Redux/RequestHandlers";
import { InputLabels } from "../../../components/Helpers/label";
import { PrimaryInputComponents, PrimarySelectComponent } from "../../../components/Style";
import { ButtonLoader, PrimaryBlackButton } from "../../../components/Buttons";
import { country } from "../../../components/Helpers/country";
import KYCPending from "./Pending";
import StepMenu from "./StepMenu";
import SoundWaveLoader from "../../../components/Loader/SoundWaveLoader";
import { CardLayout } from "../../../components/Layouts/AllLayOuts";

const IdentityVerificationComponent = () => {
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const { kycinfo } = useSelector((state) => state?.transactions);
  const [isFetching, setIsFetching] = useState(false);
  const [inputs, setInputs] = useState({
    natinality: "United Arab Emirates",
    countryofresidance: "United Arab Emirates",
    identitydocumenttype: "Driving License",
  });
  const [images, setImages] = useState({});
  const [rawFiles, setRawFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setRawFiles((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
      setImages((prev) => ({
        ...prev,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.fullname &&
        inputs?.identitynumber &&
        inputs?.identitydocumenttype &&
        inputs?.natinality &&
        inputs?.countryofresidance &&
        rawFiles?.document1 &&
        rawFiles?.document2
        ? false
        : true
    );
  }, [inputs, rawFiles]);

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isDisable) return setLoading(false);
      const data = new FormData();
      data.append("fullname", inputs?.fullname);
      data.append("identitynumber", inputs?.identitynumber);
      data.append("identitydocumenttype", inputs?.identitydocumenttype);
      data.append("countryofresidance", inputs?.countryofresidance);
      data.append("natinality", inputs?.natinality);
      data.append("document1", rawFiles?.document1);
      data.append("document2", rawFiles?.document2);
      const req = {
        url: "identityVerification",
        payload: data,
        action: "ACTION_CHANGE_KYC_IDENTITY",
      };

      const response = await dispatch(uploadHandler(req));

      setLoading(false);
      if (response?.data?.success)
        return navigate("/settings/kyc/company-verification/");
    } catch (error) {
      console.error(error);
    }
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "getKycInfo",
        action: "ACTION_GET_KYC_INFO",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Grid container>
            <Grid item lg={3} sx={{ pr: "15px" }}>
              <StepMenu />
            </Grid>

            <Grid item xs={12} lg={9}>
              {/* get apis */}
              <CardLayout
                p="15px"
                component={
                  <>
                    {isFetching ? (
                      <Box
                        sx={{
                          height: '75vh',
                          display: 'grid',
                          placeContent: 'center'
                        }}>
                        <SoundWaveLoader />
                      </Box>
                    ) : /Requested|Pending|Completed/.test(kycinfo?.kyc?.status) ||
                      kycStatus?.identity ? (
                      <KYCPending kyc={kycinfo?.kyc?.status} />
                    ) : (
                      <Box sx={{ p: '10px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Full Name" mb="5px" />
                            <PrimaryInputComponents
                              id="fullname"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter full name"
                              name="fullname"
                              value={inputs?.fullname || ""}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <InputLabels
                              label="Identity Number (as per document)"
                              mb="5px"
                            />
                            <PrimaryInputComponents
                              id="identityNumber"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter identity number"
                              name="identitynumber"
                              value={inputs?.identitynumber || ""}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: "10px" }}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Nationality" mt="15px" />
                            <PrimarySelectComponent
                              id="demo-simple-select"
                              onChange={handleChange}
                              name="natinality"
                              value={inputs?.natinality || ""}

                              children={country?.map((item) => (
                                <MenuItem key={item?.name} value={item?.name}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                            />

                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Country of residence" mt="15px" />
                            <PrimarySelectComponent
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={handleChange}
                              name="countryofresidance"
                              value={inputs?.countryofresidance || ""}
                              children={country?.map((item) => (
                                <MenuItem key={item?.name} value={item?.name}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                            />

                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: "10px" }}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Identity Document Type" mt="15px" />
                            <PrimarySelectComponent
                              onChange={handleChange}
                              name="identitydocumenttype"
                              value={inputs?.identitydocumenttype || ""}
                              children={["Driving License", "National ID", "Passport"]?.map((item, index) =>
                                <MenuItem
                                  key={item + index}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              )}
                            />

                          </Grid>
                        </Grid>

                        <InputLabels
                          label="Upload document as per selected identity document"
                          mt="25px"
                        />

                        <Box
                          sx={{
                            mt: "20px",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "242px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "SF Pro Display",
                                fontSize: "14px",
                                fontWeight: 400,
                                mb: "10px",
                              }}
                            >
                              Upload front side
                            </Typography>
                            <Box
                              className="modal_input_image"
                              sx={{
                                height: "242px",
                                borderRadius: "40px",
                                border: "1px dashed var(--Gray-4, #BDBDBD)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <label className="mt-3">
                                {images?.document1 ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      maxHeight: "100%",
                                      backgroundSize: "contain",
                                    }}
                                    component={"img"}
                                    src={images?.document1}
                                  />
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="62"
                                      height="62"
                                      viewBox="0 0 62 62"
                                      fill="none"
                                    >
                                      <path
                                        d="M3.875 15.5H17.4375L23.25 7.75H38.75L44.5625 15.5H58.125V50.375H3.875V15.5Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M31 40.6875C36.3503 40.6875 40.6875 36.3503 40.6875 31C40.6875 25.6497 36.3503 21.3125 31 21.3125C25.6497 21.3125 21.3125 25.6497 21.3125 31C21.3125 36.3503 25.6497 40.6875 31 40.6875Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Click to upload image
                                  </>
                                )}
                                <input
                                  type="file"
                                  name="document1"
                                  onChange={onImageChange}
                                  // value={fileInputState}
                                  // accept=""
                                  accept="image/*"
                                  required
                                />
                              </label>
                            </Box>
                            <Typography
                              sx={{
                                mt: "20px",
                                fontFamily: "SF Pro Display",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              Provide files in JPG or PDF format, 10 MB maximum
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              width: "242px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "SF Pro Display",
                                fontSize: "14px",
                                fontWeight: 400,
                                mb: "10px",
                              }}
                            >
                              Upload back side
                            </Typography>
                            <Box
                              className="modal_input_image"
                              sx={{
                                height: "242px",
                                borderRadius: "40px",
                                border: "1px dashed var(--Gray-4, #BDBDBD)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // placeContent: "center",
                              }}
                            >
                              <label className="mt-3">
                                {images?.document2 ? (
                                  <Box
                                    sx={{
                                      width: "100%",
                                      maxHeight: "100%",
                                      backgroundSize: "contain",
                                    }}
                                    component={"img"}
                                    src={images?.document2}
                                  />
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="62"
                                      height="62"
                                      viewBox="0 0 62 62"
                                      fill="none"
                                    >
                                      <path
                                        d="M3.875 15.5H17.4375L23.25 7.75H38.75L44.5625 15.5H58.125V50.375H3.875V15.5Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M31 40.6875C36.3503 40.6875 40.6875 36.3503 40.6875 31C40.6875 25.6497 36.3503 21.3125 31 21.3125C25.6497 21.3125 21.3125 25.6497 21.3125 31C21.3125 36.3503 25.6497 40.6875 31 40.6875Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Click to upload image
                                  </>
                                )}
                                <input
                                  type="file"
                                  name="document2"
                                  onChange={onImageChange}
                                  // value={fileInputState}
                                  // accept=""
                                  accept="image/*"
                                  required
                                />
                              </label>
                            </Box>
                            <Typography
                              sx={{
                                mt: "20px",
                                fontFamily: "SF Pro Display",
                                fontSize: "14px",
                                fontWeight: 400,
                              }}
                            >
                              Provide files in JPG or PDF format, 10 MB maximum
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ mt: "20px" }}>
                          {loading ? (
                            <ButtonLoader maxWidth="100px" />
                          ) : (
                            <PrimaryBlackButton
                              text="Save"
                              onClick={handleSubmit}
                              disabled={isDisable}
                            />
                          )}
                        </Box>
                      </Box>
                    )}
                  </>
                }

              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default IdentityVerificationComponent;
