import React from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import SliderTableWalletComponent from "./sliderTableComponent";
import { Numberz, getPercentage, tokenHelpers } from "../../../utilities";
import { AuthP } from "../../../components/Helpers/helperComponents";
import BasicTableWithBody, { NoRecordInTable, StyledTableCell, StyledTableRow } from "../../../components/Tables";


export default function WalletTable(props) {
  const { totalPortfolioValue, showCurrency, balancesData } = useSelector(
    (state) => state?.dashboard
  );

  return (
    <>
      <BasicTableWithBody
        tableHead={[
          "Asset",
          "Balance",
          `Fiat (${showCurrency === "usd" ? "USD" : "AED"})`,
          "Allocation"
        ]}
        tableBody={
          <>
            {balancesData?.balances?.length > 0 ? (
              balancesData?.balances?.map((row, index) => (
                <StyledTableRow key={row?.network + row?.token + index}>
                  <StyledTableCell component="th" scope="row">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "30px",
                        }}
                      >
                        <Box
                          component="img"
                          src={row?.logo}
                          alt={row?.logo}
                          sx={{
                            minWidth: "30px",
                            maxWidth: "100%",
                            backgroundSize: "contain",
                            // width: "30px",
                            // height: "35px"
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                            fontFamily: "SF Pro Display",
                          }}
                        >
                          {tokenHelpers[row?.token]}
                        </Typography>
                        <AuthP text={row?.token} fs="14px" mt="0px" />
                      </Box>
                    </Box>
                    {row?.platform}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {Numberz(row?.balance).toFixed(6)} {row?.token}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {showCurrency === "usd"
                      ? Numberz(row?.realTimePrice?.usd * row?.balance).toFixed(
                        2
                      )
                      : Numberz(row?.realTimePrice?.aed * row?.balance).toFixed(
                        2
                      )}
                  </StyledTableCell>
                  {/* <StyledTableCell align="left">{0}</StyledTableCell> */}
                  <StyledTableCell align="left">

                    <SliderTableWalletComponent
                      percent={
                        getPercentage(
                          Numberz(Number(totalPortfolioValue?.USD)),
                          Numberz(Number(row?.realTimePrice?.usd)) *
                          Numberz(Number(row?.balance))
                        ).toFixed(2) || 0
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <NoRecordInTable />
            )}
          </>
        }
      />


    </>
  );
}


