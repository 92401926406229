import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import { Box, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { MainListItems, SettingList } from "./listItems";
import { useLocation, useNavigate } from "react-router-dom";
// import Logo from "../../assets/img/logo2.png";
import CurrencyBtn from "./CurrencyBtn";
import { DeleteDialog } from "../Dialog";
import LogoutIcon from '@mui/icons-material/Logout';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Hamburger, Header5Layout, MobileSideNavLayout } from "../Layouts/AllLayOuts";
import themes from "../Theme";
import CustomizedSwitches from "../Helpers/SwitchComponent";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Logo } from "./Logo";
// import { LogoComponent } from "./Layout/Logo";

const selectedTheme = localStorage.getItem('theme') || 'light';


const settings = ["Settings", "Wallet", "Dashboard"];


function DashboardDrawer({ innerHead }) {
  // const { register, kyc, accountCreation, fundDeposit } = useSelector(state => state?.dashboard)
  // const { role } = useSelector(state => state?.auth)
  const navigate = useNavigate();
  const handleNavigation = (setting) => {
    // Navigate to the appropriate route based on the clicked setting
    if (setting === "Settings")
      return navigate("/settings/profile", { replace: true });
    if (setting === "Wallet")
      return navigate("/wallet/", { replace: true });
    if (setting === "Dashboard")
      return navigate("/dashboard/", { replace: true });
  };
  const { pathname } = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>

      <AppBar position="sticky" component="nav" sx={{
        boxShadow: "none!important",
        background: 'none',
      }}>
        <Toolbar
          sx={{
            display: "flex",
            px: '15px !important'
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              // color: "black!important",
            }}
          >
            <Hamburger />
          </IconButton>
          {/* <IconButton1Layout 
            onClick={handleDrawerToggle}
            component={<Hamburger />}
          /> */}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              width: '100%'
            }}
          >
            {innerHead?.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {innerHead?.map((item, index) => (
                  <Box
                    key={item + index}

                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {index > 0 && <Box sx={{ p: '0px 10px' }}><ArrowForwardIosIcon sx={{ color: '#64748B', fontSize: '12px' }} /></Box>}
                    <Header5Layout text={item} fs="18px" fw={900} mt="0px" />
                  </Box>
                ))}
              </Box>
            )}
          </Box>


          <Box
            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "100%", gap: "15px" }}
          >
            <CurrencyBtn
              border={themes[selectedTheme]?.colors?.border}
              color={themes[selectedTheme]?.text?.primary}

            />

            <Tooltip title="Switch theme" arrow>
              <IconButton
                onClick={() => {
                  const theme = localStorage.getItem('theme')
                  localStorage.setItem('theme', theme === "dark" ? "light" : "dark")
                  window.location.reload(false)
                }}
              >
                {selectedTheme === 'dark' ? <LightModeIcon sx={{ color: '#fff' }} /> : <DarkModeIcon sx={{ color: '#000' }} />}
              </IconButton>
            </Tooltip>
            {/* <NotificationMenu /> */}

            {/* Settings */}
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleOpenUserMenu} sx={{
                p: '7px',
                background: '#ADA3F5',
                border: themes[selectedTheme]?.colors?.border,
                "&:hover": {
                  background: '#ADA3F5',
                }

              }}>
                <PermIdentityOutlinedIcon sx={{ color: "#000" }} />
              </IconButton>

              <Menu
                sx={{ mt: "45px" }}
                id="menuAppBar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Box sx={{
                  border: themes[selectedTheme]?.colors?.border,
                  background: themes[selectedTheme]?.colors?.card,
                  mt: '-8px',
                  mb: '-8px',
                  overflow: 'hidden'
                }}>
                  <MenuItem
                    key={"xyz"}
                    sx={{
                      // background: 'red',
                      fontSize: '15px',
                      fontWeight: '600',
                      pb: '5px',

                      borderBottom: themes[selectedTheme]?.colors?.border,
                      color: themes[selectedTheme]?.text?.primary,
                      '&:hover': {
                        background: 'none'
                      }
                    }}
                  >
                    My Account
                  </MenuItem>
                  {settings?.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleNavigation(setting)
                        handleCloseUserMenu()
                      }}
                    >
                      <Typography textAlign="center" sx={{
                        color: themes[selectedTheme]?.text?.primary,
                        fontSize: '15px'
                      }}>{setting}</Typography>
                    </MenuItem>
                  ))}

                  <MenuItem
                    key={"theme"}
                    sx={{
                      color: themes[selectedTheme]?.text?.primary,
                      // borderTop: themes[selectedTheme]?.colors?.border,
                      gap: '5px'
                    }}
                  // onClick={() => handleNavigation(setting)}
                  >
                    Light
                    <CustomizedSwitches
                      checked={selectedTheme === "dark"}
                      onClick={() => {
                        const theme = localStorage.getItem('theme')
                        localStorage.setItem('theme', theme === "dark" ? "light" : "dark")
                        window.location.reload(false)
                      }
                      }
                    />
                    Dark


                  </MenuItem>

                  <MenuItem
                    key={"logoutInitiate"}
                    sx={{
                      color: themes[selectedTheme]?.text?.primary,
                      borderTop: themes[selectedTheme]?.colors?.border,
                    }}
                  // onClick={() => handleNavigation(setting)}
                  >

                    <DeleteDialog
                      customComponent={<Box sx={{
                        display: 'flex',
                        gap: '5px',
                        py: '5px',
                        width: '100%',
                        fontSize: '14px',
                      }}><LogoutIcon sx={{ fontSize: '18px' }} /> Log Out</Box>}
                      title="Sure you want to logout?"
                      content="Are you sure you want to log out?"
                      execute={() => navigate("/login/", { replace: true })}
                    />
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav" sx={{ background: 'red' }}>
        <Drawer
          open={mobileOpen}
          variant="temporary"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
        >
          <MobileSideNavLayout
            component={<>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: '12px',
                  pl: '25px'
                }}
              >
                <Logo mobileView={true} />
              </Box>
              <Divider />
              <Box sx={{ minWidth: '260px', height: '93.5vh', pb: '10px' }}>
                {/settings/.test(pathname) ? <SettingList /> : <MainListItems />}

              </Box>
            </>
            }
          />

          {/* <List component="nav">{<MainListItems />}</List> */}
        </Drawer>
      </Box>
    </Box >
  );
}

DashboardDrawer.propTypes = {
  window: PropTypes.func,
};

export default DashboardDrawer;
