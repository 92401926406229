import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AuthHeader,
  AuthP,
  LogoText,
} from "../../components/Helpers/helperComponents";
import { InputLabels } from "../../components/Helpers/label";
import { PasswordValidation } from "../../Validation/formValidations";
import { PostHandler } from "../../Redux/RequestHandlers";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";
import { PrimaryInputComponents } from "../../components/Style";

const ResetPassword = () => {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [errors, setErrors] = useState({});
  const { num, lc, uc, sp, len } = PasswordValidation(inputs.newPassword || "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "resetPassword",
        payload: inputs,
      };
      const response = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (response?.data?.success) return navigate("/login/");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!inputs.newPassword && !inputs.confirmPassword) return;
    setErrors((prev) => ({
      ...prev,
      matchPass: inputs.newPassword === inputs.confirmPassword ? false : true,
    }));
  }, [inputs]);

  useEffect(() => {
    if (!inputs.newPassword) return;
    setErrors((prev) => ({
      ...prev,
      criteria: num && lc && uc && sp && len ? false : true,
    }));
  }, [inputs?.newPassword, num, lc, uc, len, sp]);

  useEffect(() => {
    setIsDisable(!errors?.criteria && !errors?.matchPass ? false : true);
  }, [inputs, errors]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: {
            xs: "40px",
            md: "80px",
          },
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--neutral-200, #E3E3E8)",
          background: "var(--main-colors-white, #FFF)",
          boxShadow: "10px 4px 30px 0px rgba(161, 145, 243, 0.10)",
          maxWidth: "400px",
        }}
      >
        <LogoText />
        <AuthHeader text="Reset your password" />
        <AuthP text="Password should contain one uppercase, one lowecase, one special charecter, one number and length should be minimum 8" />

        <Box sx={{ mt: "10px", width: "100%" }}>
          <InputLabels label="New Password" />
          <PrimaryInputComponents
            color="#000"
            id="newPassword"
            type="password"
            name="newPassword"
            onChange={handleChange}
            value={inputs?.newPassword || ""}
            placeholder="Enter your password"
          />
          {errors?.criteria && (
            <Typography sx={{ color: "red" }}>
              Password criteria not matched.
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: "10px", width: "100%" }}>
          <InputLabels label="Confirm Password" />
          <PrimaryInputComponents
            color="#000"
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            onChange={handleChange}
            value={inputs?.confirmPassword || ""}
            placeholder="Enter confirm password"
          />
        </Box>

        {errors?.matchPass && inputs?.confirmPassword && (
          <Typography sx={{ color: "red" }}>
            Password and Confirm Password not Matched
          </Typography>
        )}

        {isLoading ? (
          <ButtonLoader />
        ) : (
          <PrimaryButton
            title="Reset Password"
            onClick={handleSubmit}
            disabled={isDisable}
            mt="15px"
          />
        )}
      </Box>
    </Box>
  );
};

export default ResetPassword;
