
const themes = {
    fontSize: {
        header: '16px',
        body: '14px',
        label: '12px',
        smallLabel: '10px'
    },
    dark: {
        mode: 'dark',
        primary: {
            main: '#bb86fc',
            light: '#ee98fb',
            dark: '#8859c8',
        },
        secondary: {
            main: '#03dac6',
            light: '#66fff9',
            dark: '#BB86FC',
        },
        colors: {
            default: '#121212',
            sideNav: '#121212',
            paper: '#1e1e1e',
            label: '#2f2f2f',
            body: '#292828',
            // body: '#292828',
            card: '#403f3f',
            // card: '#403f3f',
            border: '1px solid #fff2',
            bc: '#fff2',
            app: '#495ECA'
        },
        error: {
            main: '#cf6679',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0b0b0',
            disabled: '#8c8c8c',
        },
        divider: '#292929',
    },
    light: {
        mode: 'light',
        primary: {
            main: '#6200ea',
            light: '#9d46ff',
            dark: '#0a00b6',
        },
        secondary: {
            main: '#03dac6',
            light: '#66fff9',
            dark: '#00a896',
        },
        colors: {
            default: '#ffffff',
            sideNav: '#FFF',
            paper: '#f5f5f5',
            label: '#d6e3ee5e',
            border: '1px solid #e2e8f0',
            bc: '#e2e8f0',
            app: '#495ECA',
            body: '#F1F5F966',
            card: '#FFF'
        },
        error: {
            main: '#b00020',
        },
        text: {
            primary: '#000000',
            secondary: '#4a4a4a',
            disabled: '#9e9e9e',
        },
        divider: '#e0e0e0',
    },
};

export default themes;

