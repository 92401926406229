import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import TransactionTable from "./components/transactionTable";
import { getTotalPage } from "../../utilities";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CardTitleComponentWithFilterComponent } from "../../components/Cards";
import { TransactionSvg } from "../../components/Drawer/svgs";
import { CardLayout } from "../../components/Layouts/AllLayOuts";
export default function TransactionComponent() {
  const { transactions } = useSelector((state) => state?.transactions);
  const dispatch = useDispatch();
  const [pages, setPages] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [params, setParams] = useState({});
  const perPage = 10;

  const init2 = async (parametars) => {
    try {
      setIsFetching(true);
      const req = {
        url: "transactions",
        action: "ACTION_GET_TRANSACTIONS",
        params: params
          ? {
            pageNumber: pages,
            ...params,
            ...parametars,
          }
          : {
            pageNumber: pages,
          },
      };
      const response = await dispatch(GetHandler(req));
      if (response?.data?.data?.transactions?.count)
        setTotalPages(getTotalPage(Number(response?.data?.data?.transactions?.count), perPage));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (Number(value) === -1) return setParams({ pageNumber: 1, get: "all" });
    if (transactions?.tokens?.length > 0 && value > -1) {
      const selectedIndexData = transactions?.tokens[value];
      setParams({
        token: selectedIndexData?.token,
        network: selectedIndexData?.network,
        pageNumber: 1,
      });
    }
  }


  useEffect(() => {
    if (pages > 0) init2({ pageNumber: pages });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  useEffect(() => {
    if (Object.keys(params)?.length > 1) init2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <Grid container>



      <Grid item xs={12} md={12} lg={12}>
        <CardLayout
          component={<>
            <CardTitleComponentWithFilterComponent
              mainTitle="Transactions"
              component={<>
                <Box sx={{
                  background: '#72707054',
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '5px',
                  overflow: 'hidden'
                }}>
                  <Box sx={{ px: '5px', display: 'grid', placeContent: 'center' }}>
                    <FilterAltOutlinedIcon sx={{ fontSize: '16px' }} />
                  </Box>
                  <Select
                    id="demo-simple-select"
                    defaultValue="-1"
                    onChange={handleChange}
                    sx={{
                      height: "35px",
                      fontSize: "14px !important",
                      background: '#fff'
                    }}



                  >
                    <MenuItem value="-1" key="fff">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "3px",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: '14px'
                        }}
                      >
                        <TransactionSvg />
                        <Box>All Transactions</Box>
                      </Box>
                    </MenuItem>


                    {transactions?.tokens?.length > 0 &&
                      transactions?.tokens?.map((item, index) => (
                        <MenuItem value={index} key={item?.id + index}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              justifyContent: "center",
                              fontSize: '14px',
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ width: "20px", display: 'grid', placeContent: 'center' }}>
                              <img src={item?.logo} alt={item?.logo} height="20px" />
                            </Box>
                            {item?.token}
                            <Box
                              sx={{
                                color: "var(--neutral-400, #FFF)",
                                textAlign: "center",
                                fontFeatureSettings: `'ss06' on`,
                                fontFamily: "SF Pro Display",
                                fontSize: "9px",
                                fontStyle: "normal",
                                letterSpacing: "0.06px",
                                background: '#000',
                                p: '0px 4px',
                                borderRadius: '3px',
                                fontWeight: 600,
                              }}
                            >
                              {item?.network}
                            </Box>
                          </Box>
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </>}

            />

            <TransactionTable
              totalPages={totalPages}
              setPage={setPages}
              page={pages}
              callback={init2}
              params={params}
              isFetching={isFetching}
              setParams={setParams}
            />
          </>}
        />
      </Grid>
    </Grid>
  );
}
