// import io from 'socket.io-client';
// import config from '../config/config';

// const customOrigin = "https://gafah-stage.netlify.app";
// // const socket = io("http://135.181.92.246:8094", {
// //   // path:'/transaction/socket.io',
// //     transports: ["websocket"],
// //     extraHeaders: {
// //     Origin: customOrigin,
// //     },
// // });
// // io.sails.environment = 'development';
// const socket = io(config.EXTERNAL_SOCKET_URL, {
//   path: config.EXTERNAL_SOCKET_PATH,//'/externalapi/socket.io',
//   transports: ["websocket", "polling"],
//   extraHeaders: {
//     Origin: customOrigin,
//   },
//   // environment: 'development'
// });
// // const socket = io("https://api.gafahpay.com/", {
// //   path: '/external-confirmation/socket.io',
// //   transports: ["websocket","polling"],
// //   extraHeaders: {
// //     Origin: customOrigin,
// //   },
// //   // environment: 'development'
// // });
// const ExternalSocketService = {
//   connect: () => {
//     socket.connect();

//     socket.on('connect', () => {
//       console.log('External Socket connected', socket.id);
//     });

//     socket.on('disconnect', () => {
//       console.log('External Socket disconnected', socket.id);
//     });

//     // Add more event listeners and handlers as needed
//   },

//   emitEvent: (eventName, data) => {
//     console.log("External emitEvent", eventName, data);
//     socket.emit(eventName, data);
//   },

//   subscribeToEvent: (eventName, callback) => {
//     socket.on(eventName, (data) => {
//       callback(data);
//     });
//   },

//   disconnect: () => {
//     socket.disconnect();
//   },
//   isConnected: () => {
//     return socket && socket.connected;
//   },
  
// };
// export default ExternalSocketService;


import io from 'socket.io-client';
import config from '../config/config';

const customOrigin = "https://gafah-stage.netlify.app";

let socket;

const ExternalSocketService = {
  connect: () => {
    // Initialize the socket connection only if it's not already initialized
    if (!socket) {
      socket = io(config.EXTERNAL_SOCKET_URL, {
        path: config.EXTERNAL_SOCKET_PATH, // '/externalapi/socket.io',
        transports: ["websocket", "polling"], // Fallback to polling if websocket fails
        extraHeaders: {
          Origin: customOrigin,
        },
        reconnectionAttempts: 3, // Limit reconnection attempts
        timeout: 2000, // 20 seconds timeout
      });

      socket.on('connect', () => {
        console.log('External Socket connected', socket.id);
      });

      socket.on('disconnect', (reason) => {
        console.log(`External Socket disconnected: ${reason}`);
      });

      socket.on('connect_error', (error) => {
        console.error('Connection error:', error.message);
        ExternalSocketService.disconnect();
      });

      socket.on('error', (error) => {
        console.error('WebSocket encountered an error:', error.message);
        ExternalSocketService.disconnect();
      });
    }
  },

  emitEvent: (eventName, data) => {
    if (socket) {
      console.log("External emitEvent", eventName, data);
      socket.emit(eventName, data);
    }
  },

  subscribeToEvent: (eventName, callback) => {
    if (socket) {
      socket.on(eventName, (data) => {
        callback(data);
      });
    }
  },

  disconnect: () => {
    if (socket) {
      socket.disconnect();
      socket = null; // Ensure the socket is fully cleaned up
      console.log('External Socket disconnected and cleaned up');
    }
  },

  isConnected: () => {
    return socket && socket.connected;
  },
};

export default ExternalSocketService;


