import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const ButtonLoader = ({ width, maxWidth, mt }) => (
  <Button
    variant="contained"
    color="primary"
    
    sx={{
      background: "var(--main-colors-primary, #5235E8)",
      color: "#fff",
      textTransform: "none",
      borderRadius: "8px",
      transition: "0.4s",
      maxWidth: maxWidth,
      width: width || "100%",
      mt: mt,
      "&:hover": {
        background: "var(--main-colors-primary, #5235E8)",
        transition: "0.4s",
      },
    }}
    disabled
  >
    <Box className="loader"></Box>
    {/* <CircularProgress size="1.7rem" /> */}
  </Button>
);

export const PrimaryButton = ({ title, onClick, disabled, mt, maxWidth }) => (
  <Button
    variant="contained"
    color="primary"
    
    sx={{
      background: "var(--main-colors-primary, #495ECA)",
      color: "#fff",
      textTransform: "none",
      p: "11px 24px",
      height: "48px",
      borderRadius: "8px",
      transition: "0.4s",
      maxWidth: maxWidth || "100%",
      width: "100%",
      "&:hover": {
        background: "var(--main-colors-primary, #495ECA)",
        transition: "0.4s",
      },
      mt: mt,
    }}
    onClick={onClick}
    disabled={disabled || false}
  >
    <Typography
      sx={{
        color: "#fff",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "25.6px",
        letterSpacing: "0.08px",
      }}
    >
      {title || "Submit"}
    </Typography>
  </Button>
);

export const PrimaryButton2 = ({ title, onClick, disabled, height, my }) => (
  <Button
    variant="contained"
    color="primary"
    
    sx={{
      background: "var(--main-colors-primary, #495ECA)",
      color: "#fff",
      textTransform: "none",
      height: height || "48px !important",
      borderRadius: "8px",
      transition: "0.4s",
      maxWidth: "240px",
      width: "max-content",
      my: my,
      // width: "100%",
      "&:hover": {
        background: "var(--main-colors-primary, #495ECA)",
        transition: "0.4s",
      },
    }}
    onClick={onClick}
    disabled={disabled || false}
  >
    <Typography
      sx={{
        color: "#fff",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        // lineHeight: "25.6px",
        letterSpacing: "0.08px",
      }}
    >
      {title || "Submit"}
    </Typography>
  </Button>
);

export const SocialButton = ({ icon, title, onClick, disabled }) => (
  <Button
    variant="contained"
    color="primary"
    
    sx={{
      background: "var(--main-colors-white, #FFF)",
      border: "1px solid var(--primary-shades-400, #D6D1FA)",
      color: "#0E0637",
      textTransform: "none",
      p: "11px 24px",
      height: "48px",
      borderRadius: "8px",
      transition: "0.4s",
      // maxWidth: "240px",
      width: "100%",
      "&:hover": {
        background: "var(--main-colors-white, #FFF)",
        color: "#0E0637",
        transition: "0.4s",
      },
    }}
    onClick={onClick}
    disabled={disabled || false}
  >
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      {icon}

      <Typography
        sx={{
          color: "#0E0637",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "25.6px",
          lineSpacing: "0.08px",
        }}
      >
        {title || "Submit"}
      </Typography>
    </Box>
  </Button>
);

export const LinkButton = ({ title, to, height }) => (
  <Button
    variant="contained"
    color="primary"
    
    sx={{
      background: "var(--main-colors-primary, #000)",
      color: "#fff",
      textTransform: "none",
      height: height || "40px !important",
      borderRadius: "8px",
      transition: "0.4s",
      maxWidth: "240px",
      width: "max-content",
      // width: "100%",
      "&:hover": {
        background: "var(--main-colors-primary, #000)",
        transition: "0.4s",
      },
    }}
    component={Link}
    to={to}
  >
    {title || "Submit"}
  </Button>
);


export const PrimaryBlackButton = ({ title, text, onClick, disabled, mt, maxWidth }) => (
  <Tooltip title={title} arrow>
    <Button
      variant="contained"
      color="primary"
      
      sx={{
        background: "#3f3e3efc",
        color: "#fff",
        textTransform: "none",
        borderRadius: "5px",
        transition: "0.4s",
        maxWidth: maxWidth || "100%",
        "&:hover": {
          background: "#000",
          transition: "0.4s",
        },
        mt: mt,
        fontSize: '14px',
        p: '5px 10px',
        fontWeight: 'bold'
      }}
      onClick={onClick}
      disabled={disabled || false}
    >

      {text || "Submit"}
    </Button>
  </Tooltip>
);

export const PrimaryIconButton = ({ title, text, onClick, disabled, mt, background, color }) => (
  <Tooltip title={title} arrow>
    <IconButton
      variant="contained"
      color={color || "primary"}
      
      sx={{
        background: background || "#495ECA",
        color: "#fff",
        width: '30px',
        height: '30px',
        textTransform: "none",
        borderRadius: "5px",
        transition: "0.4s",
        
        "&:hover": {
          background: background || "#495ECA",
          color: '#fff',
          transition: "0.4s",
        },
        mt: mt,
        fontSize: '14px',
        fontWeight: 'bold'
      }}
      onClick={onClick}
      disabled={disabled || false}
    >

      {text || "Submit"}
    </IconButton>
  </Tooltip>
);

export const ErrorButton = ({ title, text, onClick, disabled, mt, maxWidth, component, to }) => (
  <Tooltip title={title}>
    <Button
      component={component}
      to={to}
      variant="contained"
      color="error"
      
      sx={{
        color: "#fff",
        textTransform: "none",
        borderRadius: "5px",
        transition: "0.4s",
        maxWidth: maxWidth || "100%",
        mt: mt,
        fontSize: '14px',
        p: '5px 10px',
        fontWeight: 'bold'
      }}
      onClick={onClick}
      disabled={disabled || false}
    >

      {text || "Cancel"}
    </Button>
  </Tooltip>
);

export const AppButton = ()=>{
  
}



