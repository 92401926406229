import React, { useEffect, useState } from "react";
import AlertDialog from "../../../components/Dialog";
import { Box, TextareaAutosize } from "@mui/material";
import { validEmail } from "../../../Validation/formValidations";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { PrimaryInputComponents } from "../../../components/Style";
import { ButtonLoader, PrimaryBlackButton } from "../../../components/Buttons";
import { InputLabels } from "../../../components/Helpers/label";
import themes from "../../../components/Theme";
const selectedTheme = localStorage.getItem('theme') || 'light';


export default function NewTicket() {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [showModal, setShowModal] = useState();
  const dispatch = useDispatch();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "tickets",
        action: "ACTION_POST_TICKET_RETURN",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (res?.data?.success) {
        // init();
        setInputs({});
        setShowModal("We have received your query. We are working on it!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      validEmail(inputs?.email) &&
        inputs?.subject &&
        inputs?.name &&
        // inputs?.company &&
        inputs?.description
        ? false
        : true
    );
  }, [inputs]);

  return (
    <Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabels label="Full Name" fw="900" mb="5px" />
        <PrimaryInputComponents
          id="name"
          name="name"
          placeholder="Full Name"
          onChange={handleChange}
          value={inputs?.name || ""}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: "10px" }}>
        <InputLabels label="Email" fw="900" mb="5px" />
        <PrimaryInputComponents
          id="email"
          type="email"
          name="email"
          onChange={handleChange}
          value={inputs?.email || ""}
          placeholder="Email"
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", mt: "10px" }}>
        <InputLabels label="Subject" fw="900" mb="5px" />
        <PrimaryInputComponents
          id="subject"
          name="subject"
          onChange={handleChange}
          value={inputs?.subject || ""}
          placeholder="Subject"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: "10px",
          mb: "15px",
        }}
      >
        <InputLabels label="Message" fw="900" mb="5px" />
        <TextareaAutosize
          onChange={handleChange}
          name="description"
          value={inputs?.description || ""}
          minRows={10}
          maxRows={12}
          placeholder="Hello there,I would like to complaint about..."
          style={{
            padding: "15px",
            borderRadius: "5px",
            fontFamily: "SF Pro Display",
            fontSize: "15px",
            background: 'transparent',
            color: themes[selectedTheme]?.text?.primary
          }}
        />
      </Box>

      {!isLoading ? (
        <PrimaryBlackButton
          text="Create Ticket"
          disabled={isDisable}
          onClick={handleSubmit}
        />
      ) : (
        <ButtonLoader width="140px" />
      )}
      {showModal && (
        <AlertDialog
          btnTitle="Okay"
          title="Status"
          content={showModal}
          controlled={true}
          controlModal={showModal ? true : false}
          okayComponent={true}
          execute={() => {
            setShowModal("");
          }}
        />
      )}
    </Box>
  );
}
