import io from 'socket.io-client';
import config from '../config/config';

const customOrigin = "https://gafah-stage.netlify.app";

let socket;

const UserSocketService = {
  connect: () => {
    if (!socket) {
      socket = io(config.USER_SOCKET_URL, {
        path: config.USER_SOCKET_PATH, // '/externalapi/socket.io'
        transports: ["websocket", "polling"], // Fallback to polling if websocket fails
        extraHeaders: {
          Origin: customOrigin,
        },
        reconnectionAttempts: 3, // Number of reconnection attempts
        timeout: 2000, // 20 seconds timeout
      });

      socket.on('connect', () => {
        console.log('User Socket connected', socket.id);
      });

      socket.on('disconnect', (reason) => {
        console.log(`User Socket disconnected: ${reason}`);
      });

      socket.on('connect_error', (error) => {
        console.error('Connection error:', error.message);
        UserSocketService.disconnect();
      });

      socket.on('error', (error) => {
        console.error('WebSocket encountered an error:', error.message);
        UserSocketService.disconnect();
      });
    }
  },

  emitEvent: (eventName, data) => {
    if (socket) {
      console.log("User emitEvent", eventName, data);
      socket.emit(eventName, data);
    } else {
      console.warn("Socket is not connected. Cannot emit event:", eventName);
    }
  },

  subscribeToEvent: (eventName, callback) => {
    if (socket) {
      socket.on(eventName, (data) => {
        callback(data);
      });
    } else {
      console.warn("Socket is not connected. Cannot subscribe to event:", eventName);
    }
  },

  disconnect: () => {
    if (socket) {
      socket.disconnect();
      socket = null; // Ensure the socket is fully cleaned up
      console.log('User Socket disconnected and cleaned up');
    }
  },

  isConnected: () => {
    return socket && socket.connected;
  }
};

export default UserSocketService;
