import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Typography, Collapse, IconButton } from "@mui/material";

import {
  DashboardSVG,
  WalletSVG,
  PricesSVG,
  AccountSVG,
  SettingSVG,
  ConfigSvg,
  APISvg,
  WithdrawalSvg,
} from "../../svgs";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import KeyIcon from "@mui/icons-material/Key";
import { useSelector } from "react-redux";
import { hasAccess } from "../../../../utilities";
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

export const DashboardMenuTitle = ({ mt, title, icon }) => (
  <Box
    sx={{
      mt: mt,
      display: "flex",
      alignItems: "center",
      gap: "8px",
      alignSelf: "stretch",
      ml: "10px",
      mb: "15px",
      textTransform: "uppercase",
    }}
  >
    {icon}
    <Typography
      sx={{
        color: "var(--Neutral-400, #9C9CAB)",
        fontFeatureSettings: "ss06 on",
        fontFamily: "SF Pro Display",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "135%" /* 16.2px */,
        letterSpacing: "0.48px",
      }}
    >
      {title}
    </Typography>
  </Box>
);

export const DashboardMenuItem = ({ title, to, icon, active }) => (
  <>
    <LightTooltip title={title} placement="right" arrow>
      <Box>
        <IconButton
          component={Link}
          to={to}
          sx={{
            display: "flex",
            width: "40px",
            height: "40px",
            borderRadius: "8px",
            background: active && "#F1F5F9",
            "&:hover": {
              background: active && "#F1F5F9",
              color: "#000",
              transition: "0.4s",
            },
          }}
        >
          {icon}
        </IconButton>
        
      </Box>
    </LightTooltip>
  </>
);

export const DesktopMenuLists = () => {
  const { accessLists } = useSelector(state => state.auth)
  const { pathname } = useLocation();
  const [openConfig, setOpenConfig] = React.useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100% - 50px)",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
        {hasAccess(["Dashboard-read"], accessLists) &&
          <DashboardMenuItem
            title="Dashboard"
            to="/dashboard/"
            icon={
              <DashboardSVG color={/dashboard/.test(pathname) ? "#000" : "#9C9CAB"} />
            }
            active={/dashboard/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Wallet-read"], accessLists) &&
          <DashboardMenuItem
            title="Wallet"
            to="/wallet/"
            icon={<WalletSVG color={/wallet/.test(pathname) ? "#000" : "#9C9CAB"} />}
            active={/wallet/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Prices-read"], accessLists) &&
          <DashboardMenuItem
            title="Prices"
            to="/prices/"
            icon={<PricesSVG color={/prices/.test(pathname) ? "#000" : "#9C9CAB"} />}
            active={/prices/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["TransactionBasic-read", "TransactionAdvance-read"], accessLists) &&
          <DashboardMenuItem
            title="Transactions"
            to="/transactions/"
            icon={
              <AccountSVG color={/transactions/.test(pathname) ? "#000" : "#9C9CAB"} />
            }
            active={/transactions/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Smartlink-read", "Apikey-read", "Vat-read", "Externalcurrency-read"], accessLists) &&
          <Box
            sx={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => setOpenConfig(!openConfig)}
          >
            <DashboardMenuItem
              title="Configuration"
              icon={
                <ConfigSvg
                  color={
                    /api-keys|smartlinks|vat|configurations\/external/.test(
                      pathname
                    )
                      ? "#000"
                      : ""
                  }
                />
              }
              active={
                /api-keys|smartlinks|vat|configurations\/external/.test(pathname)
                  ? true
                  : false
              }
            />

            <Collapse in={openConfig} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  position: "absolute",
                  p: "5px",
                  mt: "0!important",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  background: "#fff",
                  borderRadius: "5px",
                  border: "1px solid #e2e8f0",
                  top: "-200%",
                  left: "45px",
                  zIndex: "2",
                }}
              >
                {hasAccess(["Apikey-read"], accessLists) &&
                  <DashboardMenuItem
                    title="Api Keys"
                    to="/api-keys/"
                    icon={
                      <KeyIcon
                        sx={{
                          color: /api-keys/.test(pathname) ? "#000" : "#9C9CAB",
                        }}
                      />
                    }
                    active={/api-keys/.test(pathname) ? true : false}
                  />
                }

                {hasAccess(["Vat-read"], accessLists) &&
                  <DashboardMenuItem
                    title="Vat"
                    to="/vat/"
                    icon={
                      <WithdrawalSvg color={/vat/.test(pathname) ? "#000" : "#9C9CAB"} />
                    }
                    active={/vat/.test(pathname) ? true : false}
                  />
                }

                {hasAccess(["Smartlink-read"], accessLists) &&
                  <DashboardMenuItem
                    title="Smart Links"
                    to="/smartlinks/"
                    icon={
                      <APISvg color={/smartlinks/.test(pathname) ? "#000" : "#9C9CAB"} />
                    }
                    active={/smartlinks/.test(pathname) ? true : false}
                  />
                }

                {hasAccess(["Externalcurrency-read"], accessLists) &&
                  <DashboardMenuItem
                    title="External Currencies"
                    to="/configurations/external/"
                    icon={
                      <CurrencyBitcoinIcon
                        sx={{
                          color: /configurations\/external/.test(pathname)
                            ? "#000"
                            : "#9C9CAB",
                        }}
                      />
                    }
                    active={
                      /configurations\/external/.test(pathname) ? true : false
                    }
                  />
                }
              </Box>
            </Collapse>
          </Box>
        }

        {hasAccess(["Accessibility-read"], accessLists) &&
          <DashboardMenuItem
            title="Accessibility"
            to="/accessibility/"
            icon={
              <AccessibilityNewOutlinedIcon sx={{ color: /accessibility/.test(pathname) ? "#000" : "#9C9CAB" }} />
            }
            active={/accessibility/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["withdrawalrequest-read"], accessLists) &&
          <DashboardMenuItem
            title="Withdrawal Request"
            to="/withdrawal-request/"
            icon={
              <ArrowOutwardIcon
                sx={{ color: /withdrawal-request/.test(pathname) ? "#000" : "#9C9CAB" }}
              />
            }
            active={/withdrawal-request/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Ticket-read"], accessLists) &&
          <DashboardMenuItem
            title="Tickets"
            to="/ongoing-ticket/"
            icon={
              <HelpCenterOutlinedIcon
                sx={{
                  color: /ongoing-ticket/.test(pathname) ? "#000" : "#9C9CAB",
                }}
              />
            }
            active={/ongoing-ticket/.test(pathname) ? true : false}
          />
        }
      </Box>
      <Box>
        <DashboardMenuItem
          title="Settings"
          to="/settings/profile/"
          icon={<SettingSVG />}
          active={false}
        />
      </Box>
    </Box>
  );
};
// e5e7eb
// F1F5F966

export const DeskTopSettingList = () => {
  const { accessLists } = useSelector(state => state.auth)
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "calc(100% - 50px)",
        alignItems: "center",
      }}
    >
      <Box sx={{
        display: "flex",
        gap: "10px",
        flexDirection: "column"
      }}>
        {hasAccess(["Profile-read"], accessLists) &&
          <DashboardMenuItem
            title="Profile"
            to="/settings/profile/"
            icon={<Person2OutlinedIcon sx={{ color: /profile/.test(pathname) ? "#000" : "#9C9CAB" }} />}
            active={/profile/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Security-update"], accessLists) &&
          <DashboardMenuItem
            title="Security"
            to="/settings/security/"
            icon={<SecurityOutlinedIcon sx={{ color: /security/.test(pathname) ? "#000" : "#9C9CAB" }} />}
            active={/security/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["SettingsChangePassword-update"], accessLists) &&
          <DashboardMenuItem
            title="Password"
            to="/settings/change-password/"
            icon={<PasswordOutlinedIcon sx={{ color: /change-password/.test(pathname) ? "#000" : "#9C9CAB" }} />}
            active={/change-password/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Kyc-read"], accessLists) &&
          <DashboardMenuItem
            title="KYC"
            to="/settings/kyc/identity-verification/"
            icon={<AdminPanelSettingsOutlinedIcon sx={{ color: /kyc/.test(pathname) ? "#000" : "#9C9CAB" }} />}
            active={/kyc/.test(pathname) ? true : false}
          />
        }


      </Box>
      <Box>
        <DashboardMenuItem
          title="Back"
          to="/dashboard/"
          icon={<KeyboardReturnOutlinedIcon sx={{ color: "#9C9CAB" }} />}
          active={false}
        />
      </Box>

    </Box>
  );
};

export const ReferralList = () => {
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        {/* <DashboardMenuTitle title="Account" icon={<AccountSVG2 />} /> */}
        <Box
          sx={{
            p: "0 24px 0px 30px",
            display: "flex",
            // gap: "8px",
            flexDirection: "column",
          }}
        >
          <DashboardMenuItem
            title="Dashboard"
            to="/referral/"
            icon={
              <DashboardSVG
                color={/dashboard/.test(pathname) ? "#495ECA" : ""}
              />
            }
            active={/referral/.test(pathname) ? true : false}
          />
        </Box>
      </Box>
      <Box sx={{ px: "18px", bottom: "10px" }}>
        <DashboardMenuItem
          title="Back"
          to="/dashboard/"
          icon={<KeyboardReturnOutlinedIcon sx={{ color: "#9C9CAB" }} />}
          active={false}
        />
      </Box>
    </Box>
  );
};
