import * as React from "react";

import { Box } from "@mui/material";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetHandlerForPaymentGateway } from "../../Redux/RequestHandlers";
import {
  Failed,
  UrlReject,
} from "../../components/Helpers/helperComponents";
import { useState } from "react";
import { LogoSvg, ResourceLoader } from "../../components/Helpers/svg";
import { getSecondsDifference, hasObjectPropertyExist, isExpiredDate } from "../../utilities";
import { CountdownComponentWithLoader } from "../../components/Helpers/countdown";
import WalletIcon from "@mui/icons-material/Wallet";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { MerchantAndLogoComponent, OrderBox, PaymentButton } from "./Component/PosComponents";

export default function POSStepper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoices } = useSelector((state) => state?.pos);
  const [loading, setLoading] = React.useState(true);
  const [isRejected, setIsRejected] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("id");
  const [isExchangesShow, setIsExchangesShow] = useState(false)
  // api calls
  const init = async () => {
    if (
      hasObjectPropertyExist(invoices, param1) &&
      invoices[param1]?.id === param1 &&
      invoices[param1]?.currentPage === "externalPaymentCheckout" &&
      invoices[param1]?.token
    )
      return navigate(invoices[param1]?.exchange ? `/invoice/external?id=${invoices[param1]?.id}&&exchange=${invoices[param1]?.exchange}` : `/invoice/external?id=${invoices[param1]?.id}`);
    try {
      let req = {
        url: "invoiceDetails",
        action: "ACTION_INVOICE_DETAILS",
        params: {
          id: param1, //"ea448ecc-6c92-4a1d-91db-55b3be507c78",
        },
      };
      const res = await dispatch(GetHandlerForPaymentGateway(req));
      if (res?.data?.data?.jwt_token?.expiresIn)
        setExpireDate(res?.data?.data?.jwt_token?.expiresIn);

      setIsRejected(res?.data?.success ? false : true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "ACTION_CLEAR_PAYMENT_DETAILS",
        payload: param1,
      });
    }
  };

  const clearExpiredPayments = () => {
    if (invoices) {
      Object.keys(invoices).forEach(key => {
        const payment = invoices[key];
        const jwtExpiresIn = payment?.jwt_token?.expiresIn || payment?.expireIn;
        if (!jwtExpiresIn || isExpiredDate(jwtExpiresIn))
          dispatch({
            type: "ACTION_CLEAR_PAYMENT_DETAILS",
            payload: key,
          });
      });
    }
  }

  React.useEffect(() => {
    // clearing expired Invoices
    clearExpiredPayments()
    param1 ? init() : setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param1]);

  return (
    <Box sx={{ p: '20px' }}>

      {param1 && <>
        {/* merchant Name */}
        <MerchantAndLogoComponent merchantName={hasObjectPropertyExist(invoices, param1) && invoices[param1]?.merchantName} bb="0px" />


        {/* order box */}
        <OrderBox
          amount={hasObjectPropertyExist(invoices, param1) && invoices[param1]?.currency + " " + invoices[param1]?.amount}
          mainTitleText="Amount"
          subTitleText="Select Wallet"
        />
      </>}


      {!param1 ? (
        <UrlReject
          my="100px"
          text={`url should be ${location?.pathname}?id=xxxxx-xxx-xxxx...`}
        />
      ) : loading ? (
        <Box sx={{ py: "130px", width: '100%', display: 'grid', placeContent: 'center' }}>
          {" "}
          <ResourceLoader />
        </Box>
      ) : isRejected ? (
        <Failed
          my="100px"
          text="Looks like the transaction has expired, please try to Restart the Payment"
        />
      ) :

        <>


          {/* select wallet box */}
          {hasObjectPropertyExist(invoices, param1) && (
            <>
              <Box
                sx={{ my: '40px' }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <PaymentButton
                    component={Link}
                    to={`/invoice/gafah?id=${param1}`}
                    icon={<LogoSvg width={18} height={18} />}
                    titleText="Pay with Gafah Wallet"
                    bs="0px 2px 6px 0px rgba(19, 18, 66, 0.07)"
                    border="1px solid var(--Neutral-300, #495ECA)"
                  />

                  {invoices[param1]?.exchangeDetails && JSON.parse(invoices[param1]?.exchangeDetails)?.length > 0 ?
                    <Box
                      sx={{
                        overflow: 'hidden',
                        mt: '10px',
                        borderRadius: "5px",
                        border: "1px solid var(--Neutral-300, #EFF0F6)",
                        width: "100%",
                        textAlign: "center",
                        boxShadow: "0px 2px 6px 0px rgba(73, 94, 202, 0.18)",
                        textDecoration: "none",
                        height: isExchangesShow ? `${Number(JSON.parse(invoices[param1]?.exchangeDetails)?.length * 66 + 132)}px` : '66px',
                        transition: 'height 0.3s ease-out'
                      }}
                    >
                      <PaymentButton
                        icon={<WalletIcon sx={{ color: "#495ECA" }} />}
                        titleText="Pay with any External Wallets/Exchange."
                        onClick={() => setIsExchangesShow(!isExchangesShow)}
                        rightIcon={<ArrowDropDownOutlinedIcon />}
                      />


                      {isExchangesShow && <Box>
                        {JSON.parse(invoices[param1]?.exchangeDetails)?.length > 0 && JSON.parse(invoices[param1]?.exchangeDetails)?.map(item =>
                          <Box sx={{
                            borderTop: "1px solid var(--Neutral-300, #EFF0F6)",
                          }}
                            key={item}>
                            <PaymentButton
                              component={Link}
                              to={`/invoice/external?id=${param1}&&exchange=${item}`}
                              titleText={`Pay with ${item}`}
                              bt="1px solid var(--Neutral-300, #EFF0F6)"
                              hover={{
                                background: '#495ECA',
                                color: "#FFF"
                              }}
                            />
                          </Box>
                        )}

                        <Box sx={{
                          borderTop: "1px solid var(--Neutral-300, #EFF0F6)",
                        }}>
                          <PaymentButton
                            component={Link}
                            to={`/invoice/external?id=${param1}`}
                            titleText={`Pay with other`}
                          />
                        </Box>
                      </Box>}
                    </Box>

                    :
                    <PaymentButton
                      component={Link}
                      to={`/invoice/external?id=${param1}`}
                      icon={<WalletIcon sx={{ color: "#495ECA" }} />}
                      titleText="Pay with any External Wallets/Exchange."
                      onClick={() => setIsExchangesShow(!isExchangesShow)}
                    />

                  }
                </Box>
              </Box>

              {!loading && expireDate && (
                <CountdownComponentWithLoader
                  expireIn={getSecondsDifference(new Date(), expireDate)}
                  colour="red"
                  content="Your Transaction will expire in "
                />
              )}
            </>
          )}
        </>
      }
    </Box>
  );
}
