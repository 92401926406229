import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LogoImg from "../../assets/img/logo2.png";
import { useNavigate, useParams } from "react-router-dom";
import { Failed } from "../../components/Helpers/helperComponents";
import { useDispatch } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import { ResourceLoader } from "../../components/Helpers/svg";
import { ButtonLoader, PrimaryBlackButton } from "../../components/Buttons";
import { MerchantAndLogoComponent, OrderBox } from "../POS/Component/PosComponents";

export default function SmartLink() {
  const { code, businessname } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [smartLinkDetail, setSmartLinkDetail] = useState(null)

  const init = useCallback(async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "smartlinkDetails",
        params: {
          code: code,
          businessname: businessname
        },
        state: {
          code: code,
        },
      };
      let result = await dispatch(GetHandler(req));
      setIsExpired(!result?.data?.success);
      if (result?.data?.success) setSmartLinkDetail(result?.data?.data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }, [code, businessname, dispatch]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      let req = {
        url: "smartlinkInitiatePayment",
        payload: {
          code: code,
        },
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success && res?.data?.data?.id)
        navigate(`/invoice?id=${res?.data?.data?.id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }, [code, dispatch, navigate]);

  useEffect(() => {
    if (code) init();
  }, [code, init]);

  return (
    <Box
      sx={{
        background: "#F1F5F966",
        height: "95vh",
        p: '15px',
      }}
    >
      <Box sx={{ mt: '20vh' }}>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={5} lg={4} xl={3}
            sx={{
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
              p: '15px',
            }}>
            {isFetching ?
              <Box sx={{ display: 'grid', placeContent: 'center', py: '50px' }}>
                <ResourceLoader />
              </Box>
              : isExpired ? (
                <Failed
                  my="50px"
                  text="Looks like this payment link has been expired, please try with other links to Restart the Payment"
                />
              ) : (
                <>
                  <MerchantAndLogoComponent
                    merchantName={
                      smartLinkDetail && (
                        <Typography sx={{ fontSize: '16px' }}>
                          {/* {smartLinkDetail?.merchantName} */}
                          Smartlink
                        </Typography>
                      )
                    }
                    bb="none"
                  />

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '20px',
                    fontWeight: 900,
                    py: '15px'
                  }}>
                    {smartLinkDetail?.merchantName}
                  </Box>

                  <OrderBox
                    amount={smartLinkDetail && smartLinkDetail?.currency + " " + smartLinkDetail?.amount}
                    mainTitleText="Amount"
                    fs="16px"
                  // subTitleText="Smartlink"
                  />

                  {smartLinkDetail && (
                    <Box
                      sx={{
                        pt: "33px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          alignItems: "center",
                          pb: '30px',
                        }}
                      >
                        {!isSubmitting ? (
                          <PrimaryBlackButton
                            onClick={handleSubmit}
                            title="Click to continue"
                            text="Continue to Pay"
                          />
                        ) : (
                          <ButtonLoader />
                        )}
                      </Box>
                    </Box>
                  )}
                </>
              )}
          </Grid>
          {!isFetching && (
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  fontSize: "12px",
                  fontWeight: "700",
                  mt: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "SF Pro Display",
                  gap: "3px",
                }}
              >
                <Box
                  component="img"
                  src={LogoImg}
                  sx={{ height: "20px", width: "auto" }}
                />
                <Box sx={{ fontFamily: "SF Pro Display" }}>
                  Secure Payment
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}
