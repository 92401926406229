import { Box } from "@mui/material";
import React from "react";
import { CloseCircleStatusIcon, LoaderIcon } from "../Icons/StatusSvgIcons";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Tooltip from "@mui/material/Tooltip";


export const StatusShowConfig = {
  Processing: {
    color: "#000",
    background: "#f2f21c6b",
  },
  Initiated: {
    color: "",
    background: "#f2f21c85",
  },
  "Partial Sent": {
    color: "",
    background: "#f2f21c85",
  },
  "Waiting Confirmations": {
    color: "#000",
    background: "#f2f21c85",
  },
  "Completed (Manually)": {
    color: "#4e9b05",
    background: "#0a96761a",
  },
  Completed: {
    color: "#4e9b05",
    background: "#0a96761a",
  },

  Declined: {
    color: "#f80303",
    background: "#f3040426",
  },
  Failed: {
    color: "#f80303",
    background: "#f3040426",
  },
  Expired: {
    color: "#f80303",
    background: "#f3040426",
  },
  Incomplete: {
    color: "#f80303",
    background: "#f3040426",
  },
};

const statusIcons = {
  Processing: <LoaderIcon color="#495ECA" />,
  "Waiting Confirmations": <LoaderIcon color="#495ECA" />,
  "Partial Sent": <LoaderIcon color="#4e9b05" />,
  Initiated: <LoaderIcon />,
  Completed: <CheckCircleOutlineIcon sx={{ fontSize: "18px", ml: '-2px' }} color="success" />,
  Declined: <CloseCircleStatusIcon />,
  Failed: <CloseCircleStatusIcon />,
  Expired: <CloseCircleStatusIcon />,
  "Completed (Manually)": <CheckCircleOutlineIcon sx={{ fontSize: "15px" }} color="success" />
}


export const StatusCountShow = ({ status, count }) => {
  return (
    <Tooltip title={status} arrow>
      <Box
        sx={{
          width: "max-content",
          fontSize: "14px",
          fontWeight: 500,
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {statusIcons[status]} {count}
      </Box>
    </Tooltip>

  );
};

const StatusShow = ({ status }) => {
  return (
    <Box
      sx={{
        width: "max-content",
        fontSize: "14px",
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      {statusIcons[status]} {status}
    </Box>
  );
};
export default StatusShow;

export const StatusShowOld = ({ status }) => {
  return (
    <Box
      sx={{
        color: StatusShowConfig[status]?.color,
        background: StatusShowConfig[status]?.background,
        width: "max-content",
        padding: "1px 10px",
        borderRadius: "10px",
        fontSize: "12px",
        fontWeight: 500,
      }}
    >
      {status}
    </Box>
  );
};




export const InComming = () => (
  <Box
    sx={{
      transform: 'rotate(125deg)'
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 2.1665C5.6741 2.1665 2.16699 5.67361 2.16699 9.99984C2.16699 12.8986 3.74133 15.4303 6.08402 16.7855C6.32305 16.9238 6.40473 17.2297 6.26646 17.4687C6.12819 17.7077 5.82233 17.7894 5.58329 17.6511C2.94431 16.1246 1.16699 13.27 1.16699 9.99984C1.16699 5.12132 5.12181 1.1665 10.0003 1.1665C14.8788 1.1665 18.8337 5.12132 18.8337 9.99984C18.8337 13.27 17.0563 16.1246 14.4174 17.6511C14.1783 17.7894 13.8725 17.7077 13.7342 17.4687C13.5959 17.2297 13.6776 16.9238 13.9166 16.7855C16.2593 15.4303 17.8337 12.8986 17.8337 9.99984C17.8337 5.67361 14.3266 2.1665 10.0003 2.1665ZM9.64677 6.31301C9.84203 6.11775 10.1586 6.11775 10.3539 6.31301L13.6872 9.64634C13.8825 9.8416 13.8825 10.1582 13.6872 10.3534C13.4919 10.5487 13.1754 10.5487 12.9801 10.3534L10.5003 7.87367V18.3332C10.5003 18.6094 10.2765 18.8332 10.0003 18.8332C9.72418 18.8332 9.50032 18.6094 9.50032 18.3332V7.87367L7.02054 10.3534C6.82528 10.5487 6.5087 10.5487 6.31344 10.3534C6.11818 10.1582 6.11818 9.8416 6.31344 9.64634L9.64677 6.31301Z"
        fill="#495ECA"
      />
    </svg>
  </Box>
);

const paymentTypeIcons = {
  Payment: <InComming />,
  Initiated: <LoaderIcon />,
  Completed: <CheckCircleOutlineIcon sx={{ fontSize: "15px" }} color="success" />,
  Declined: <CloseCircleStatusIcon />,
  Failed: <CloseCircleStatusIcon />,
  Expired: <CloseCircleStatusIcon />,
}

export const PaymentTypeShow = ({ type }) => <Box
  sx={{
    width: "max-content",
    fontSize: "14px",
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }}
>
  {paymentTypeIcons[type]} {type}
</Box>