import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { Box, Typography, ListItemButton, Collapse } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from 'react-redux'
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import {
  DashboardSVG,
  WalletSVG,
  PricesSVG,
  AccountSVG,
  SettingSVG,
  ConfigSvg,
  AccountSVG2,
  CompanySvg,
  APISvg,
  WithdrawalSvg,
} from "./svgs";
import { hasAccess } from "../../utilities";

export const DashboardMenuTitle = ({ mt, title, icon }) => (
  <Box
    sx={{
      mt: mt,
      display: "flex",
      padding: "2px 12px",
      alignItems: "center",
      gap: "8px",
      alignSelf: "stretch",
      ml: "10px",
      mb: "15px",
      textTransform: "uppercase",
    }}
  >
    {icon}
    <Typography
      sx={{
        color: "var(--Neutral-400, #9C9CAB)",
        fontFeatureSettings: "ss06 on",
        fontFamily: "SF Pro Display",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "135%" /* 16.2px */,
        letterSpacing: "0.48px",
      }}
    >
      {title}
    </Typography>
  </Box>
);

export const DashboardMenuItem = ({ title, to, icon, active }) => (
  <ListItemButton
    component={Link}
    to={to}
    sx={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
      padding: "12px",
      borderRadius: "8px",
      border: active && "0.9px solid #000",
      background: active && "#F7F6FE",
    }}
  >
    {icon}
    <Typography
      sx={{
        color: active ? "#0E0637" : "#717184",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20.3px",
      }}
    >
      {title}
    </Typography>
  </ListItemButton>
);

export const MainListItems = () => {
  const { accessLists } = useSelector(state => state.auth)
  const { pathname } = useLocation();
  const [openConfig, setOpenConfig] = React.useState(false);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <Box
        sx={{ p: "15px", display: "flex", gap: "8px", flexDirection: "column" }}
      >
        {hasAccess(["Dashboard-read"], accessLists) &&
          <DashboardMenuItem
            title="Dashboard"
            to="/dashboard/"
            icon={
              <DashboardSVG color={/dashboard/.test(pathname) ? "#000" : ""} />
            }
            active={/dashboard/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Wallet-read"], accessLists) &&
          <DashboardMenuItem
            title="Wallet"
            to="/wallet/"
            icon={<WalletSVG color={/wallet/.test(pathname) ? "#495ECA" : ""} />}
            active={/wallet/.test(pathname) ? true : false}
          />
        }



        {hasAccess(["Prices-read"], accessLists) &&
          <DashboardMenuItem
            title="Prices"
            to="/prices/"
            icon={<PricesSVG color={/prices/.test(pathname) ? "#495ECA" : ""} />}
            active={/prices/.test(pathname) ? true : false}
          />
        }
        {/* <DashboardMenuItem
          title="Portfolio"
          to="/dashboard/"
          icon={<PortfoliosSVG />}
          active={false}
        /> */}

        {hasAccess(["TransactionBasic-read", "TransactionAdvance-read"], accessLists) &&
          <DashboardMenuItem
            title="Transactions"
            to="/transactions/"
            icon={
              <AccountSVG
                color={/transactions/.test(pathname) ? "#495ECA" : ""}
              />
            }
            active={/transactions/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Smartlink-read", "Apikey-read", "Vat-read", "Externalcurrency-read"], accessLists) &&
          <>
            <ListItemButton
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "10px",
                padding: "12px",
                borderRadius: "8px",
              }}
              onClick={() => setOpenConfig(!openConfig)}
            >
              <ConfigSvg />
              <Typography
                sx={{
                  color: "#717184",
                  fontFeatureSettings: `'ss06' on`,
                  fontFamily: "SF Pro Display",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "20.3px",
                }}
              >
                Configurations
              </Typography>

              {openConfig ? (
                <KeyboardArrowUpIcon sx={{ color: "#717184" }} />
              ) : (
                <KeyboardArrowDownIcon sx={{ color: "#717184" }} />
              )}
            </ListItemButton>
            <Collapse in={openConfig} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  pl: "1rem",
                  mt: "0!important",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {hasAccess(["Apikey-read"], accessLists) &&

                  <DashboardMenuItem
                    title="Api Keys"
                    to="/api-keys/"
                    icon={
                      <APISvg color={/api-keys/.test(pathname) ? "#495ECA" : ""} />
                    }
                    active={/api-keys/.test(pathname) ? true : false}
                  />
                }
                {/* <DashboardMenuItem
              title="Withdrawals"
              to="/withdrawal/"
              icon={
                <WithdrawalSvg
                  color={/withdrawals/.test(pathname) ? "#495ECA" : ""}
                />
              }
              active={/withdrawal/.test(pathname) ? true : false}
            /> */}

                {hasAccess(["Vat-read"], accessLists) &&
                  <DashboardMenuItem
                    title="Vat"
                    to="/vat/"
                    icon={
                      <WithdrawalSvg color={/vat/.test(pathname) ? "#495ECA" : ""} />
                    }
                    active={/vat/.test(pathname) ? true : false}
                  />
                }

                {hasAccess(["Smartlink-read"], accessLists) &&

                  <DashboardMenuItem
                    title="Smart Links"
                    to="/smartlinks/"
                    icon={
                      <APISvg color={/smartlinks/.test(pathname) ? "#495ECA" : ""} />
                    }
                    active={/smartlinks/.test(pathname) ? true : false}
                  />
                }
                {hasAccess(["Externalcurrency-read"], accessLists) &&
                  <DashboardMenuItem
                    title="External Currencies"
                    to="/configurations/external/"
                    icon={
                      <APISvg color={/configurations\/external/.test(pathname) ? "#495ECA" : ""} />
                    }
                    active={/configurations\/external/.test(pathname) ? true : false}
                  />
                }
              </Box>
            </Collapse>
          </>

        }

        {hasAccess(["Accessibility-read"], accessLists) &&
          <DashboardMenuItem
            title="Accessibility"
            to="/accessibility/"
            icon={
              <AccessibilityNewOutlinedIcon sx={{ color: /accessibility/.test(pathname) ? "#000" : "#9C9CAB" }} />
            }
            active={/accessibility/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["withdrawalrequest-read"], accessLists) &&
          <DashboardMenuItem
            title="Withdrawal Request"
            to="/withdrawal-request/"
            icon={
              <WalletSVG color={/withdrawal-request/.test(pathname) ? "#495ECA" : ""} />
            }
            active={/withdrawal-request/.test(pathname) ? true : false}
          />
        }

        {hasAccess(["Ticket-read"], accessLists) &&
          <DashboardMenuItem
            title="Tickets"
            to="/ongoing-ticket/"
            icon={
              <ConfigSvg
                color={/ongoing-ticket/.test(pathname) ? "#495ECA" : ""}
              />
            }
            active={/ongoing-ticket/.test(pathname) ? true : false}
          />
        }

      </Box>

      {hasAccess(["Profile-read", "Settingschangepassword-update", "Kyc-read", "Security-update"], accessLists) &&
        <Box sx={{ px: "18px" }}>
          <DashboardMenuItem
            title="Settings"
            to="/settings/profile/"
            icon={<SettingSVG />}
            active={false}
          />
        </Box>
      }
    </Box>
  );
};

export const SettingList = () => {
  const { accessLists } = useSelector(state => state.auth)
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        height: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{pt: '10px'}}>
        <DashboardMenuTitle title="Account" icon={<AccountSVG2 />} />
        <Box
          sx={{
            p: "0 24px 0px 30px",
            display: "flex",
            // gap: "8px",
            flexDirection: "column",
          }}
        >
          {hasAccess(["Profile-read"], accessLists) &&
            <DashboardMenuItem
              title="Profile"
              to="/settings/profile/"
              // icon={<DashboardSVG color={/dashboard/.test(pathname) ? "#495ECA" : ""} />}
              active={/profile/.test(pathname) ? true : false}
            />
          }

          {hasAccess(["Security-update"], accessLists) &&
            <DashboardMenuItem
              title="Security"
              to="/settings/security/"
              active={/security/.test(pathname) ? true : false}
            />
          }

          {hasAccess(["Profile-read", "Settingschangepassword-update", "Kyc-read", "Security-update"], accessLists) &&
            <DashboardMenuItem
              title="Password"
              to="/settings/change-password/"
              active={/change-password/.test(pathname) ? true : false}
            />
          }
        </Box>

        <DashboardMenuTitle title="Company" icon={<CompanySvg />} mt="15px" />
        <Box
          sx={{
            p: "0 24px 0 30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <DashboardMenuItem
            title="Notifications & Privacy"
            to="/settings/privacy-notifications/"
            // icon={<DashboardSVG color={/dashboard/.test(pathname) ? "#495ECA" : ""} />}
            active={/privacy-notifications/.test(pathname) ? true : false}
          /> */}

          {/* <DashboardMenuItem
            title="Payment Methods"
            to="/settings/payment-methods/"
            // icon={<DashboardSVG color={/dashboard/.test(pathname) ? "#495ECA" : ""} />}
            active={/payment-methods/.test(pathname) ? true : false}
          /> */}
          {hasAccess(["kyc-read"], accessLists) &&
            <DashboardMenuItem
              title="KYC"
              to="/settings/kyc/identity-verification/"
              // icon={<DashboardSVG color={/dashboard/.test(pathname) ? "#495ECA" : ""} />}
              active={/kyc/.test(pathname) ? true : false}
            />
          }
        </Box>
      </Box>
      <Box sx={{ px: "18px", bottom: "10px" }}>
        <DashboardMenuItem
          title="Back"
          to="/dashboard/"
          // icon={<SettingSVG />}
          active={false}
        />
      </Box>
    </Box>
  );
};

export const ReferralList = () => {
  // const { accessLists } = useSelector(state => state.auth)
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        {/* <DashboardMenuTitle title="Account" icon={<AccountSVG2 />} /> */}
        <Box
          sx={{
            p: "0 24px 0px 30px",
            display: "flex",
            // gap: "8px",
            flexDirection: "column",
          }}
        >
          <DashboardMenuItem
            title="Dashboard"
            to="/referral/"
            icon={<DashboardSVG color={/dashboard/.test(pathname) ? "#495ECA" : ""} />}
            active={/referral/.test(pathname) ? true : false}
          />


        </Box>


      </Box>
      <Box sx={{ px: "18px", bottom: "10px" }}>
        <DashboardMenuItem
          title="Back"
          to="/dashboard/"
          // icon={<SettingSVG />}
          active={false}
        />
      </Box>
    </Box>
  );
};
