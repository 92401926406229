import { accessKeys } from "../../components/Guard/accessKeys"
export const ACTION_LOGIN = "ACTION_LOGIN"
export const ACTION_FORGETPASS_INITIATE = "ACTION_FORGETPASS_INITIATE"
export const ACTION_FORGETPASS_VERIFY = "ACTION_FORGETPASS_VERIFY"
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN"
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN"
export const ACTION_SIGN_UP = "ACTION_SIGN_UP"
export const ACTION_VERIFIED_OTP = "ACTION_VERIFIED_OTP"
export const ACTION_VERIFIED_SUB_MERCHANT_OTP = "ACTION_VERIFIED_SUB_MERCHANT_OTP"
export const ACTION_ACCOUNT_SETUP = "ACTION_ACCOUNT_SETUP"
export const RESET_STORE = "RESET_STORE"
export const ACTION_SOCIAL_LOGIN_INITIATE = "ACTION_SOCIAL_LOGIN_INITIATE"
export const ACTION_SOCIAL_SIGNUP = "ACTION_SOCIAL_SIGNUP"

const initialState = {
  accessKey: null,
  accessLists: [],
  isLogin: null,
  email: null,
  name: null,
  firstname: null,
  lastname: null,
  phone: null,
  detailsExistance: false,
  isVerified: false,
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  forgotPass: false,
  googleLogin: false,
  isKyc: false,
  submerchantVerification: false
}

export const AuthReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        isVerified: true,
        detailsExistance: action?.payload?.detailsExistance,
        name: action?.payload?.firstname + ' ' + action?.payload?.lastname || null,
        email: action?.payload?.email,
        phone: action?.payload?.phone,
        accessKey: action?.payload?.submerchantVerificationRedirection ? accessKeys?.verifyRegisterEmail : action?.payload?.detailsExistance ? accessKeys?.marchant : accessKeys?.accountSetup,
        isLogin: action?.payload?.detailsExistance && true,
        isKyc: action?.payload?.isKyc,
        accessLists: action?.payload?.permissions,
        submerchantVerification: action?.payload?.submerchantVerificationRedirection || false
      }

    case ACTION_ACCOUNT_SETUP:
      return {
        ...state,
        email: action?.payload?.email,
        accessToken: action?.payload?.token?.token,
        refreshToken: action?.payload?.token?.refreshToken || null,
        isAuthenticated: true,
        isVerified: true,
        detailsExistance: true,
        name: action?.payload?.name || null,
        accessKey: accessKeys?.marchant,
        isKyc: action?.payload?.isKyc,
        accessLists: action?.payload?.permissions
      }

    case ACTION_SIGN_UP:
      return {
        ...state,
        email: action?.reqPayload?.email,
        accessToken: action?.payload?.token,
        isAuthenticated: true,
        isVerified: false,
        accessKey: accessKeys?.verifyRegisterEmail
      }

    case ACTION_SOCIAL_SIGNUP:
      return {
        ...state,
        accessToken: action?.payload?.token,
        isAuthenticated: true,
        isVerified: true
      }

    case ACTION_VERIFIED_OTP:
      return {
        ...state,
        isVerified: true,
        accessKey: accessKeys?.accountSetup
      }

    case ACTION_VERIFIED_SUB_MERCHANT_OTP:
      return {
        ...state,
        isVerified: true,
        accessKey: accessKeys?.marchant,
        submerchantVerification: false,
        isAuthenticated: true,
        detailsExistance: action?.payload?.detailsExistance,
        isLogin: true,
        accessLists: action?.payload?.permissions,
      }

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
        isAuthenticated: true,
      }

    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
        isAuthenticated: true,
      }

    case ACTION_SOCIAL_LOGIN_INITIATE:
      return {
        ...state,
        googleLogin: true
      }

    case ACTION_FORGETPASS_INITIATE:
      return {
        ...state,
        email: action?.reqPayload?.email,
        accessKey: accessKeys?.verifyForgetPassEmail
      }

    case ACTION_FORGETPASS_VERIFY:
      return {
        ...state,
        accessKey: accessKeys?.resetPassword
      }

    default:
      return state
  }
}

