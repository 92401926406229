import React from "react";
import { Box, Grid } from "@mui/material";
import Chart from "./chart";
import { useSelector } from "react-redux";
import { CardTitleComponentWithRightArrow } from "../../../../components/Cards";
import { CardLayout } from "../../../../components/Layouts/AllLayOuts";
import MyWatchListComponent from "./MyWatchListComponent";

const selectedTheme = localStorage.getItem('theme') || 'light';


export default function LeftGridComponentTwo() {
  const { previousbalances } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <CardLayout
      component={<Grid container>
        <Grid item xs={12} md={12} lg={9} xl={9} >
          <CardTitleComponentWithRightArrow mainTitle="Portfolio statistics" to="/wallet/" />
          <Box sx={{ pr: '10px' }}>
            <Chart data={previousbalances} />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={3} xl={3} sx={{
          borderLeft: selectedTheme === 'light' ? '1px solid #e2e8f0' : '1px solid #fff2',
          position: 'relative',

        }}>
          <CardTitleComponentWithRightArrow mainTitle="My Watch List" to="/wallet/" noArrow={true} />
          <MyWatchListComponent />
        </Grid>
      </Grid>}
    />
  );
}
