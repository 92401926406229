import { Box, Grid, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  LoaderCircular,
} from "../../components/Helpers/helperComponents";

import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import {
  Btc,
  DangerSvg,
  DefaultAvatar,
  NextSvg,
} from "../../components/Helpers/svg";
import { country } from "../../components/Helpers/country";
import AlertDialog from "../../components/Dialog";
import { ButtonLoader, PrimaryBlackButton } from "../../components/Buttons";
import { EmailDisableBox, PhoneInputDisableBox, PrimaryInputComponents, PrimarySelectComponent } from "../../components/Style";
import { Link } from "react-router-dom";
import moment from "moment";
import { hasAccess } from "../../utilities";
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";
const Profile = () => {
  const { accessLists } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state?.settings);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const dispatch = useDispatch();
  const handleChange = (e) => {
    if (e.target.name === "businessname" && e.target.value?.length > 30) return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "profile",
        action: "ACTION_GET_PROFILE",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsLoading(true);
      let data = {};
      if (inputs?.firstname !== profile?.firstname)
        data.firstname = inputs.firstname;
      if (inputs?.lastname !== profile?.lastname)
        data.lastname = inputs.lastname;
      if (inputs?.businessname !== profile?.businessname)
        data.businessname = inputs.businessname;
      if (inputs?.dob !== profile?.dob) data.dob = inputs.dob;
      if (inputs?.country !== profile?.country) data.country = inputs.country;

      if (inputs?.firstname !== profile?.firstname)
        data.firstname = inputs.firstname;
      const req = {
        url: "profile",
        payload: data,
      };

      await dispatch(PostHandler(req));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteRequest = async () => {
    try {
      setIsDeleting(true);
      const req = {
        url: "deleteRequest",
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) init();
      setIsDeleting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.newEmail ? false : true);
  }, [inputs]);

  useEffect(() => {
    setInputs({
      email: profile?.email,
      phone: profile?.phone,
      firstname: profile?.firstname,
      lastname: profile?.lastname,
      businessname: profile?.businessname,
      dob: moment(profile?.dob).format("YYYY-MM-DD"),
      country: profile?.country,
    });
  }, [profile]);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisable(
      profile?.firstname === inputs?.firstname &&
        profile?.lastname === inputs?.lastname &&
        profile?.businessname === inputs?.businessname &&
        profile?.dob === inputs?.dob &&
        profile?.country === inputs?.country
        ? true
        : false
    );
  }, [profile, inputs]);

  return (
    <Box

    >
      <Grid container>
        <Grid item md={3} lg={4}>
          <Box
            sx={{
              pt: {
                xs: "0px",
                md: "32px"
              },
              mb: "15px",
            }}
          >
            <Header5Layout text="Contact Info" fs="16px" />
            <AuthPRightLayout
              text="Manage your information"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={8}>
          {/* get apis */}
          <CardLayout
            component={<>
              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DefaultAvatar />
                <Box>
                  <Header5Layout
                    fs="24px"
                    text={
                      <>
                        {profile?.firstname && profile?.firstname}{" "}
                        {profile?.lastname && profile?.lastname}
                      </>
                    }
                  />
                  <AuthPRightLayout
                    mt="0px"
                    fs="16px"
                    text={profile?.email && profile?.email}
                  />


                </Box>
              </Box>

              <Box
                sx={{
                  p: {
                    xs: "15px",
                    md: "30px",
                  },
                  display: "flex",
                  alignItems: "center",
                  borderTop: "1px solid var(--neutral-200, #E3E3E8)",
                  borderBottom: "1px solid var(--neutral-200, #E3E3E8)",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "5px",
                    textDecoration: "none",
                    fontSize: "13px",
                    right: "15px",
                    color: "var(--neutral-500, #6B7280)",
                  }}
                  component={Link}
                  to="/settings/security/"
                >
                  click to change email, phone
                </Box>


                {isFetching ? (
                  <LoaderCircular />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      width: "100%",
                      pb: "10px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <InputLabels label="Email" mb="5px" />

                      <EmailDisableBox
                        email={inputs?.email || ""}
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <InputLabels label="Phone" mb="5px" />

                      <PhoneInputDisableBox phone={inputs?.phone} />

                    </Box>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  p: "25px",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignSelf: "stretch",
                }}
              >
                <AuthPRightLayout text="Crypto addresses" fs="14px" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pt: "6px",
                    }}
                  >
                    <Box sx={{ ml: "-4px" }}>
                      <Btc />
                    </Box>
                    <Box sx={{ ml: "-4px" }}>
                      <Btc />
                    </Box>
                    <Box sx={{ ml: "-4px" }}>
                      <Btc />
                    </Box>
                    <Box sx={{ ml: "-4px" }}>
                      <Btc />
                    </Box>
                    <Box sx={{ ml: "-4px" }}>
                      <Btc />
                    </Box>
                  </Box>
                  <NextSvg />
                </Box>
              </Box>
            </>}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: "30px" }}>
        <Grid item md={3} lg={4}>
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Header5Layout text="Personal info" fs="16px" />
            <AuthPRightLayout
              text="Manage your information"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={8}>
          {/* get apis */}
          <CardLayout
            p="25px"
            component={<>
              {isFetching ? (
                <LoaderCircular />
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <InputLabels label="First Name" mb="5px" />
                      <PrimaryInputComponents
                        id="firstname"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter first name"
                        name="firstname"
                        value={inputs?.firstname || ""}
                      />
                    </Box>
                    <Box sx={{ width: "100%" }}>
                      <InputLabels label="Last Name" mb="5px" />
                      <PrimaryInputComponents
                        id="lastname"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter last name"
                        name="lastname"
                        value={inputs?.lastname || ""}
                      />
                    </Box>
                  </Box>

                  <InputLabels label="Bussiness Name" mt="15px" mb="5px" />
                  <PrimaryInputComponents
                    id="businessname"
                    type="text"
                    onChange={handleChange}
                    placeholder="Enter bussiness name"
                    name="businessname"
                    value={inputs?.businessname || ""}
                  />
                  <AuthPRightLayout
                    text="maximum length 15-30 charecter"
                    fs="14px"
                    lh="22px"
                  />

                  <InputLabels label="Date of Birth" mt="15px" mb="5px" />
                  <PrimaryInputComponents
                    id="dob"
                    type="date"
                    onChange={handleChange}
                    placeholder="Enter email"
                    name="dob"
                    value={inputs?.dob || ""}
                    required
                  />

                  <InputLabels label="Country of residence" mt="15px" />
                  <PrimarySelectComponent
                    onChange={handleChange}
                    name="country"
                    value={inputs?.country || ""}
                    children={country?.map((item) => (
                      <MenuItem key={item?.name} value={item?.name}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  />


                  {hasAccess(["Profile-update"], accessLists) && (
                    <Box sx={{ mt: "20px" }}>
                      {isLoading ? (
                        <ButtonLoader />
                      ) : (
                        <PrimaryBlackButton
                          title="Save"
                          text="Save"
                          onClick={handleUpdate}
                          disabled={isDisable}
                        />
                      )}
                    </Box>
                  )}
                </>
              )}
            </>}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: "2rem" }}>
        <Grid item md={3} lg={4}>
          <Box
            sx={{
              pt: "32px",
              mb: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <DangerSvg />
              <Header5Layout text="Danger zone" fs="16px" mt="0px" />
            </Box>
            <AuthPRightLayout
              text="Close your account"
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={8}>
          {/* get apis */}
          <CardLayout
            p="20px"
            component={<>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Header5Layout text="Close account" fs="16px" mt="0px" />
                  <AuthPRightLayout
                    text="Closing your account can’t be undone. Please make sure your account balance is $0.00 before you begin."
                    fs="15px"
                    lh="20px"
                    mt="5px"
                  />
                </Box>

                <Box>
                  {/* {isLoading ? (
                  <Button variant="outlined" color="error" disabled>
                    <CircularProgress size="1.7rem" />
                  </Button>
                ) : ( */}
                  <AlertDialog
                    btnTitle="Close Account"
                    title="Are your sure to delete?"
                    content="Are you sure to close your account"
                    execute={handleDeleteRequest}
                    executeLoading={isDeleting}
                  />
                  {/* )} */}
                </Box>
              </Box>
            </>}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Profile;
