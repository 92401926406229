import React from "react";
import {
    Document,
    Page,
    View,
    Text,
    Image,
    StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../../assets/img/logo2.png";
import moment from "moment";


const styles = StyleSheet.create({
    page: {
        fontFamily: "Helvetica",
        padding: 15,
        fontSize: 8,
        backgroundColor: "#fff",
    },
    title: {
        fontSize: 8,
        textAlign: "right",
    },

    logo: {
        width: 40,
    },

    bold: {
        fontWeight: "bold",
    },
    alignRight: {
        textAlign: "right",
    },
    container: {
        width: "100%",
        position: "relative",
        marginBottom: '30px'
    },

    leftBox: {
        width: "20%",
        position: "absolute",
        left: 0,
        fontSize: 7
    },
    rightBox: {
        width: "80%",
        position: "absolute",
        right: 0,
        fontSize: 7
    },
    invoiceTopInfo: {
        marginTop: 10,
        marginBottom: 2,
        width: "100%",
        position: "relative",
        fontSize: "8px",
        borderRadius: '5px',
        background: "#f0f0f169"
    },

});

const PaymentInvoicePdf = ({ data }) => (
    <Document>
        <Page size={[300, 200]} style={styles.page}>
            <View style={styles.container}>
                <View style={styles.leftBox}>
                    <Image src={logo} style={styles.logo} />
                </View>
                <View style={styles.rightBox}>
                    <Text style={styles.title}>INVOICE</Text>
                    <Text style={{ fontSize: 6, textAlign: "right" }}>
                        Payment Receipt
                    </Text>
                </View>
            </View>



            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Merchant
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{data?.merchantName}</Text>
                </View>
            </View>

            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Order ID
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{data?.id}</Text>
                </View>
            </View>

            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Amount
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{data?.amount + " " + data?.currency}</Text>
                </View>
            </View>

            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Received
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{`${data?.cryptoAmount} ${data?.token} (${data?.network})`}</Text>
                </View>
            </View>

            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Date
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{moment(data?.completedAt).format("lll")}</Text>
                </View>
            </View>

            <View style={styles.invoiceTopInfo}>
                <View style={styles.leftBox}>
                    <Text>
                        Status
                    </Text>
                </View>
                <View style={{ ...styles.rightBox, textAlign: "right" }}>
                    <Text>{data?.status}</Text>
                </View>
            </View>

            <View style={{ textAlign: 'center' }}>
                <Text
                    style={{
                        fontSize: 6,
                        textAlign: "center",
                        marginTop: 58
                    }}
                >
                    © Invoice generated by Gafah
                </Text>
            </View>


        </Page>
    </Document>
);

export default PaymentInvoicePdf;


