import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextareaAutosize,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  CircularProgress,
} from "@mui/material";
import { PrimaryInputComponents } from "../../components/Style";
import { useDispatch } from "react-redux";
// import { PostHandler } from "../../Redux/RequestHandlers";
import { PrimaryBlackButton } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { ACTION_REFUND_SUBMIT } from "../../Redux/Reducers/pos.reducer";
import { validEmail } from "../../Validation/formValidations";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import axios from "axios";
import config from "../../config/config";
import { MerchantAndLogoComponent, OrderBox } from "./Component/PosComponents";


export default function RefundComponent({
  setRefundShow,
  setRedirect,
  setRefundSubmit,
  refundData
}) {

  const [inputs, setInputs] = useState({
    refund_required: "yes",
  });
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async (isYes) => {
    setErrMsg("")
    try {
      let k = isYes || inputs?.refund_required;
      let payload =
        k === "no"
          ? {
            refund_required: k,
          }
          : inputs;

      setIsSubmitting(true);
      // const req = {
      //   url: "refund",
      //   payload: payload,
      // };
      const res = await axios.post(
        `${config?.EXTERNAL_API_URL}paymentgateway/insertRefundDetails`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${refundData?.refundToken}`,
          },
        }
      );
      // const res = await dispatch(PostHandler(req));
      setIsSubmitting(false);

      if (res?.data?.success) {
        setInputs({});
        if (setRedirect) setRedirect(true);
        dispatch({ type: ACTION_REFUND_SUBMIT });
        if (setRefundShow) setRefundShow(false);
        if (setRefundSubmit) setRefundSubmit(true);
      }
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setErrMsg("Please try again later.")
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.refund_required === "no" ||
        (inputs?.refund_required === "yes" &&
          inputs?.address &&
          validEmail(inputs?.email))
        ? false
        : true
    );
  }, [inputs]);

  useEffect(() => {
    if (inputs?.email) {
      let vEmail = validEmail(inputs?.email);
      setErrors((prev) => ({ ...prev, validEmail: !vEmail }));
      setErrMsg(vEmail ? "" : "invalid Email");
    }
  }, [inputs?.email]);

  return (
    <Box
      sx={{
        // background: "#F1F5F966",
        // height: "95vh",
        p: '15px',
      }}
    >

      <Box sx={{ mt: '15px' }}>
        <Grid container sx={{ justifyContent: 'center' }}>

          <Grid item xs={12} md={5} lg={4}

            sx={{
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
              p: '15px',
            }}>


            <>
              <MerchantAndLogoComponent
                merchantName={
                  (
                    <Typography sx={{ fontSize: '16px', fontWeight: 900 }}>
                      Refund Details
                    </Typography>
                  )
                }
                bb="none"
              />
                  
                  {errMsg && <Box sx={{color: 'red', textAlign: 'center', mb: '15px'}}>
            {errMsg}
          </Box>}

              <OrderBox
                mainTitleText="Amount"
                amount={refundData?.refundAmount ? refundData?.refundAmount + " " + refundData?.token : 0}


              />

              {/* refund radio box */}
              <Box
                sx={{
                  mt: '15px',
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                  textAlign: "left",
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                Refund Required:
                <Box sx={{ display: "flex", gap: "30px" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={inputs?.refund_required}
                    name="radio-buttons-group"
                  >
                    <Box sx={{ display: "flex" }}>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        onChange={() =>
                          setInputs((prev) => ({
                            ...prev,
                            refund_required: "yes",
                          }))
                        }
                        label={<InputLabels label="Yes" mt="0px" />}
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        onChange={() =>
                          setInputs((prev) => ({
                            ...prev,
                            refund_required: "no",
                          }))
                        }
                        label={<InputLabels label="No" mt="0px" />}
                      />
                    </Box>
                  </RadioGroup>
                </Box>
              </Box>

              {/* refund input box */}
              {inputs?.refund_required === "yes" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <InputLabels label="Address *" />
                    <PrimaryInputComponents
                      id="address"
                      type="string"
                      name="address"
                      onChange={handleChange}
                      value={inputs?.address || ""}
                      placeholder="Enter your wallet address here"
                      sx={{ width: "100%" }}
                      required
                    />
                  </Box>
                  <Box sx={{ width: "100%", mt: '10px' }}>
                    <InputLabels label="Email" mt="0px" />
                    <PrimaryInputComponents
                      error={errors?.validEmail}
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={inputs?.email || ""}
                      sx={{ width: "100%" }}
                      placeholder="Enter your email address"
                    />
                    {errors?.validEmail && (
                      <Box
                        sx={{
                          mt: "5px",
                          fontSize: "12px",
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          fontFamily: "SF Pro Display",
                          flexWrap: "wrap",
                          color: "red",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <HighlightOffIcon
                            fontSize="14px"
                            sx={{ color: "red" }}
                          />
                          {errMsg}
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      mt: '10px'
                    }}
                  >
                    <InputLabels label="Comments" />
                    <TextareaAutosize
                      name="Comments"
                      minRows={8}
                      placeholder="Please mention details about the refund."
                      style={{
                        padding: "15px",
                        borderRadius: "5px",
                        fontFamily: "SF Pro Display",
                        fontSize: "15px",
                      }}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              )}

              {/* refund button box */}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  mt: '25px'
                }}
              >
                {isSubmitting ? (
                  <PrimaryBlackButton
                    text={<CircularProgress size="1.3rem" />}

                  />
                ) : (
                  <PrimaryBlackButton disabled={isDisable} onClick={handleSubmit} text="Initiate Refund" />
                )}

              </Box>
            </>


          </Grid>
         

        </Grid>

      </Box>

    </Box>
  );
}
