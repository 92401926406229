import React from "react";
import { Box } from "@mui/material";
import PortfolioRowComponent from "./portfolioRowComponent";
import { useSelector } from "react-redux";
import { Numberz, getPercentage, tokenHelpers } from "../../../../utilities";
import NotFound from "../../../../components/Layouts/NotFound";

export default function PortfolioComponent() {
  const { totalPortfolioValue, showCurrency, balancesData } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <Box sx={{ width: "100%" }}>

      {balancesData?.balances?.length > 0 ?
        balancesData?.balances?.map((item, index) => (
          <Box
            key={item?.logo + index + 'px'}
          >
            <PortfolioRowComponent
              icon={item?.logo}
              cryptoName={tokenHelpers[item?.token]}
              cryptoShortName={item?.token}
              percentage={getPercentage(
                Numberz(totalPortfolioValue?.USD),
                Numberz(item?.realTimePrice?.usd) * Numberz(item?.balance)
              ).toFixed(2) || 0}
              cryptoValue={item?.balance}
              fiatValue={
                showCurrency === "usd"
                  ? Numberz(item?.realTimePrice?.usd * item?.balance).toFixed(2)
                  : Numberz(item?.realTimePrice?.aed * item?.balance).toFixed(2)
              }
              fiatCurrency={showCurrency?.toUpperCase()}
            />
          </Box>


        )) :
        <NotFound />
      }
    </Box>
  );
}
