import React from "react";
import WalletTable from "./walletTable";
import { CardTitleComponentWithFilterComponent } from "../../../components/Cards";
import { CardLayout } from "../../../components/Layouts/AllLayOuts";

export default function WalletComponentTwo() {
  return (
    <CardLayout
    my="15px"
      component={<>
        <CardTitleComponentWithFilterComponent mainTitle="Your Holdings" />
        <WalletTable />
      </>}
    />
  );
}
