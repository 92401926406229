import { Box, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import { ContentRow } from "../../components/Dialog";
import { DeleteDialog } from "../../components/Dialog";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { hasAccess } from "../../utilities";
import { StyledTableCell, StyledTableRow } from "../../components/Tables";
import { PrimaryBlackButton, PrimaryIconButton } from "../../components/Buttons";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Header5Layout } from "../../components/Layouts/AllLayOuts";
import { CopyToClipboardButton } from "../../components/Helpers/helperComponents";
import { Countdown } from "../../components/Helpers/countdown";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";

const PosMachineApiKeys = ({ item, setUpdateData, setComponentShow, init }) => {
  const { accessLists } = useSelector((state) => state.auth);
  const { posAccessData } = useSelector((state) => state.apikey);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const [isOtpLoading, setIsOtpLoading] = useState(false)

  const [handleModals, setHandleModals] = useState({
    seeDetails: false,
    delApiKey: false,
    lockApiKey: false,
  });

  const handleLock = async (id, lock) => {
    try {
      setIsLoading(true)
      const req = {
        url: "lockApiKey",
        payload: {
          keyId: id,
          lock: lock,
        },
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false)
      if (res?.data?.success) init();
      setHandleModals((prev) => ({ ...prev, lockApiKey: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateAccess = async (item) => {
    try {
      setIsOtpLoading(true)
      const req = {
        url: "posAccess",
        payload: {
          keyId: item?.id,
        },
        reqPayload: item,
        action: "ACTION_POS_ACCESS"
      };
      await dispatch(PostHandler(req));
      setIsOtpLoading(false)
      setHandleModals((prev) => ({ ...prev, lockApiKey: false }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleExpire = (id) => {
    // Automatically remove expired OTP
    dispatch({
      type: "REMOVE_ACCESS_DATA",
      payload: id,
    });
  };

  return (
    <>
      <StyledTableRow key={item?.id}>
        <StyledTableCell align="left">
          <Header5Layout text={item?.name} fs="16px" mt="0" />
        </StyledTableCell>
        <StyledTableCell align="left">
          {moment(item?.createdAt).format("DD-MM-YY")}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <PrimaryIconButton
              title="See details"
              text={<VisibilityOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900 }} />}
              onClick={() => setHandleModals({ seeDetails: true })}
            />
            {hasAccess(["Apikey-update"], accessLists) && (
              <PrimaryIconButton
                title={item?.islocked ? "Click to unlock" : "Click to lock"}
                text={<LockOutlinedIcon color={item?.islocked ? "primary" : ""} sx={{ fontSize: '18px', fontWeight: 900 }} />}
                background={item?.islocked && "#a8abaa52"}
                onClick={() => setHandleModals({ lockApiKey: true })}
              />
            )}

            {posAccessData && posAccessData[item?.id] && (
              <>
                <Box>
                  <Box
                    sx={{
                      background: "#a8abaa52",
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      pl: '10px',
                      borderRadius: '5px',
                      fontWeight: 600
                    }}>
                    {posAccessData[item?.id]?.otp}
                    <CopyToClipboardButton
                      background="#a8abaa52"
                      content={posAccessData[item?.id]?.otp}
                    />
                  </Box>
                </Box>
                {posAccessData[item?.id]?.expireIn && (
                  <Countdown
                    expireTime={posAccessData[item?.id]?.expireIn}
                    onExpire={() => handleExpire(item?.id)}

                  />
                )}
              </>
            )}

            {hasAccess(["Apikey-update"], accessLists) ? (
              !isOtpLoading ?
                <PrimaryBlackButton
                  text={posAccessData && posAccessData[item?.id]?.otp ? "Regenerate Access" : "Generate Access"}
                  onClick={() => handleGenerateAccess(item)}
                /> :
                <PrimaryBlackButton
                  text={<CircularProgress size="1.3rem" />}
                />
            ) : null}
          </Box>
        </StyledTableCell>
      </StyledTableRow>

      {handleModals?.seeDetails && (


        <LeftToRightModal
          modalTitle="See Api Key Details"
          width="400px"
          modalBody={
            <Box sx={{
              p: '15px'
            }}>
              <ContentRow left="Name" right={item?.name} />
              <ContentRow left="Type" right={item?.type} />
              <ContentRow
                left="Generated At"
                right={moment(item?.createdAt).format("llll")}
              />
            </Box>
          }
          openTrigger={handleModals?.seeDetails}
          closeModal={() => setHandleModals({ seeDetails: false })}
        />
      )}

      {handleModals?.lockApiKey && (
        <DeleteDialog
          isShow={true}
          data={item}
          title={`Sure you want to ${item?.islocked ? "Unlock" : "lock"}?`}
          content={`Are you sure you want to ${item?.islocked ? "unlock" : "lock"} this?`}
          opens={handleModals?.lockApiKey}
          execute={() => handleLock(item?.id, item?.islocked ? false : true)}
          executeLoading={isLoading}
          close={() => setHandleModals({ lockApiKey: false })}
        />
      )}
    </>
  );
};

export default PosMachineApiKeys;
