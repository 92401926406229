import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { CardLayout, CardTitleLayout, LinkLayout, RightArrowComponent } from "../Layouts/AllLayOuts";

export const DashBoardBasicCardsBody = ({ icon, titletext, datavalue }) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {icon}
      <Typography
        sx={{
          color: "var(--neutral-400, #9C9CAB)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.3px",
        }}
      >
        {titletext}
      </Typography>
    </Box>
    <Typography
      sx={{
        color: "var(--main-colors-black, #0E0637)",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "56px",
        textTransform: "uppercase",
      }}
    >
      {datavalue}
    </Typography>
  </Box>
);

export const RectCard = ({
  icon,
  title,
  data,
  customData,
  absoluteComponent,
  to,
}) => (
  <CardLayout
    component={
      <LinkLayout
        sx={{
          display: 'flex',
          textTransform: 'none',
          textDecoration: 'none',
          width: "100%",
          p: "10px",
          fontSize: "14px",
          position: "relative",
          minHeight: "80px",
        }}
        component={to && Link}
        to={to}
        body={<>
          {absoluteComponent}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                justifyContent: "flex-start",
              }}
            >
              {icon}
              {title}
            </Box>
            

            {customData || (
              <Box
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  textTransform: "uppercase",
                  mt: "5px",
                }}
              >
                {data}
              </Box>
            )}
          </Box>
        </>}
      />

    }
  />
);


export const CardTitleComponentWithRightArrow = ({ mainTitle, to, noArrow }) => <CardTitleLayout
  component={<>
    <Box
      sx={{
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "27.9px",
      }}
    >
      {mainTitle}
    </Box>
    {!noArrow && <RightArrowComponent to={to} />}
  </>}
/>


export const CardTitleComponentWithFilterComponent = ({ mainTitle, to, arrow, component }) =>
  <CardTitleLayout
    component={<>
      <Box
        sx={{
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "27.9px",
        }}
      >
        {mainTitle}
      </Box>
      <Box sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center'
      }}>
        {component}
        {arrow && <RightArrowComponent to={to} />}
      </Box>
    </>}
  />