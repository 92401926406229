import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";
import NewTicket from "./components/NewTicket";
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import { InputLabels } from "../../components/Helpers/label";
import { GetHandler } from "../../Redux/RequestHandlers";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import TicketView from "./components/TicketView";
import TablePaginations from "../../components/Tables/TablePagination";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getTotalPage, hasAccess } from "../../utilities";
import { CardTitleComponentWithFilterComponent } from "../../components/Cards";
import { TableDataLoad } from "../../components/Tables";
import { NotFoundMaterials } from "../../components/Layouts/NotFound";
import { CardLayout } from "../../components/Layouts/AllLayOuts";


export const EmailList = ({ data, onClick, active }) => <Box sx={{
  p: '15px',
  borderBottom: '1px solid #0002',
  background: active && '#bcddf64a',
  cursor: 'pointer'
}}
  onClick={onClick}
>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      gap: '5px',
      justifyContent: 'space-between'
    }}>
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      textTransform: 'uppercase',
      gap: '5px',
    }}>
      {/Initiated/.test(data?.status) && <MarkEmailUnreadOutlinedIcon sx={{ fontSize: '20px', color: "#ffc107" }} />}
      {/* <EmailOutlinedIcon color="primary" sx={{ fontSize: '20px' }} /> */}
      {/Resolved/.test(data?.status) && <MarkEmailReadOutlinedIcon sx={{ fontSize: '20px', color: 'green' }} />}
      <InputLabels label="Email" mt="0px" />
    </Box>
    <InputLabels
      mt="0px"
      fw="900"
      fs="9px"
      label={moment(data?.createdAt).format('llll')} />

  </Box>



  <InputLabels
    fw="600"
    mt="10px"
    label={data?.subject?.length <= 60 ? data?.subject : (data?.subject?.substr(0, 60) + "...")}
  />

  <Box sx={{
    fontSize: '12px',
    // mt: '5px'
  }}>
    {data?.description?.length <= 140 ? data?.description : (data?.description?.substr(0, 140) + "...")}
  </Box>
</Box>

export const TicketLists = ({ tickets, selectedIndex, handleViewChange, setSelectedIndex, pages, totalPages, setPages }) => <Box>
  <Box sx={{
    height: 'calc(90vh - 110px)',
    overflowY: 'scroll'
  }}>
    {tickets?.rows?.length > 0 && tickets?.rows?.map((item, index) => (
      <Box key={item?.id + index + 'zx'}>
        <EmailList
          onClick={() => {
            handleViewChange("ticketdescription");
            setSelectedIndex(index);
          }}
          active={selectedIndex === index}

          data={item}
        />
      </Box>
    ))}
  </Box>
  <Box>
    {pages > 0 && (
      <TablePaginations
        pages={totalPages}
        page={pages}
        setPage={setPages}
      />
    )}
  </Box>
</Box>

export default function OngoingTicketComponent() {
  const { accessLists } = useSelector(state => state.auth)
  const { tickets } = useSelector((state) => state?.ticket);
  const [pages, setPages] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  const perPage = 10;
  const [selectedIndex, setSelectedIndex] = useState();
  const [rightPanelView, setRightPanelView] = useState('ticketlist');

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleViewChange = (view) => {
    setRightPanelView(view);
  };

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "tickets",
        action: "ACTION_GET_TICKET",
        params: {
          pageNumber: pages,
        },
      };
      const response = await dispatch(GetHandler(req));
      if (response?.data?.data?.count)
        setTotalPages(getTotalPage(Number(response?.data?.data?.count), perPage));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pages > 0) init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages]);

  // const navigate = useNavigate();

  // const handleBackNavigation = async () => {
  //   // Perform the API call here
  //   try {
  //     await fetch('/api/cancel-transaction', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ action: 'cancel' }),
  //     });
  //     // Redirect to the previous page (assuming the user was on a specific route)
  //     navigate(-1); // Go back to the previous page
  //   } catch (error) {
  //     console.error('API call error:', error);
  //   }
  // };

  return (

    <CardLayout
      component={<>
        <CardTitleComponentWithFilterComponent
          mainTitle="Ongoing Tickets"
          component={hasAccess(["Ticket-create"], accessLists) &&
            <LeftToRightModal
              width="400px"
              openButton={<Tooltip title="Create New Ticket" arrow>
                <IconButton aria-label="small" size="small" color="primary">
                  <AddIcon sx={{ color: '#000' }} />

                </IconButton>
              </Tooltip>}
              modalTitle="Create New Ticket"
              modalBody={
                <Box
                  sx={{
                    p: '5px'
                  }}>

                  {hasAccess(["Ticket-create"], accessLists) &&
                    <Box sx={{
                      p: '15px',
                      // mt: '20px'
                    }}>
                      <NewTicket init={init} />
                    </Box>
                  }
                </Box>
              }
            />

          }

        />
        {isMobile ?
          <>
            {rightPanelView === 'ticketlist' && <TicketLists
              tickets={tickets}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              handleViewChange={handleViewChange}
              pages={pages}
              totalPages={totalPages}
              setPages={setPages}
            />}

            {rightPanelView === 'ticketdescription' && (
              <Box sx={{
                p: '5px',
                height: '83vh',
              }}>
                <IconButton onClick={() => handleViewChange("ticketlist")} aria-label="back" size="small" color="primary">
                  <ArrowBackIcon />
                </IconButton>
                <Box sx={{
                  px: '15px',
                  mt: '20px'
                }}>
                  <TicketView data={tickets?.rows[selectedIndex] || {}} />
                </Box>
              </Box>
            )}


          </> :
          <Grid container sx={{
            maxWidth: '100%'
          }}>
            <Grid item xs={12} lg={4} md={5} sx={{
              borderRight: '1px solid #0002',
              display: {
                xs: 'none',
                md: 'block'
              }
            }}>
              <TicketLists
                tickets={tickets}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                handleViewChange={handleViewChange}
                pages={pages}
                totalPages={totalPages}
                setPages={setPages}
              />
            </Grid>

            <Grid item xs={12} lg={8} md={7} >

              <Box sx={{
                px: {
                  lg: '25px',
                  xs: '15px'
                },
                p: '15px',
                height: '100%',
                overflowY: 'scroll',
              }}>
                {isFetching ? <Box sx={{ display: 'grid', placeContent: 'center', height: '100%' }}>
                  <TableDataLoad />
                </Box> :
                  selectedIndex >= 0 && tickets?.rows?.length > 0 ?
                    <TicketView data={tickets?.rows[selectedIndex] || {}} />

                    : <Box
                      sx={{
                        display: "grid",
                        placeContent: "center",
                        height: '100%',
                        textAlign: "center !important",
                        fontFamily: "Inter !important",
                      }}><NotFoundMaterials /></Box>

                }
              </Box>


            </Grid>
          </Grid>}
      </>}
    />
  );
}
