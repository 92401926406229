import moment from 'moment';
import BigNumber from 'bignumber.js';
import CryptoJS from 'crypto-js';
import { paymentConfig } from '../config/config';
export const getPercentage = (totalvalue, myvalue) => {
  if (totalvalue === 0) {
    return 0; // Avoid division by zero
  }
  return Number(myvalue / totalvalue) * 100;
}

export const Numberz = num => Number(num) || 0;

export const hasObjectPropertyExist = (obj, property) => obj && typeof obj === "object" && obj.hasOwnProperty(property) ? true : false;

export const joinStringAndConvertLowerCase = str => {
  const join = str.split(/\s+/).join('');
  return join.toLowerCase();
}


export const tokenHelpers = {
  ETH: "ethereum",
  USDT: "tether",
  TRX: "tron",
  BNB: "binance"
}
export const getTotalPage = (totalCount, perPage) => parseInt(totalCount % perPage === 0
  ? totalCount / perPage
  : totalCount / perPage + 1);

export const getSecondsDifference = (localDateTime, zuluDateTime) => {
  const local = moment(localDateTime);
  const zulu = moment(zuluDateTime).utc();
  // Check if zuluDateTime is greater than the current time
  if (local.isAfter(zulu)) return 0; // Zulu time is greater, return 0
  return Math.abs(local.diff(zulu, 'seconds'));
};




export const Decrypt = (word, key) => {
  return CryptoJS.AES.decrypt(word, key).toString(CryptoJS.enc.Utf8);
};

export const isAppInstalled = (appScheme) => {
  const isInstalled = window.navigator.userAgent.match(appScheme);
  return !!isInstalled;
};

export const checkAndOpenApp = (appScheme, deepLink) => {
  // if (isAppInstalled(appScheme))
  window.location.href = deepLink;
};

export const calculatePriceDifferencePercentage = (originalPrice, newPrice) => {
  // Calculate the difference between the new price and the original price
  var priceDifference = newPrice - originalPrice;

  // Calculate the percentage difference
  var percentageDifference = (priceDifference / originalPrice) * 100;

  // Determine the sign
  var sign = (percentageDifference >= 0) ? '+' : '-';

  // Return the result with the sign
  return [sign, Math.abs(percentageDifference).toFixed(2)];
}

export const calculateRTPs = (rtp, amount) => Numberz(rtp) * Numberz(amount)

export const tableHeadBreakPoint = (minIn, currentIndex, maxValue) => (currentIndex - minIn) % maxValue === 0 ? true : false;
export const isOdd = (currentIndex) => currentIndex % 2 === 0 ? false : true;

// 0, 13, 13+17 =30, 

export const calculateRecievedFiatBalance = (amount, currency, vat, fee) => {
  let finalAmount = vat ?
    Numberz(
      amount -
      amount * (fee / 100) +
      amount * (5 / 100)
    ).toFixed(paymentConfig?.fiatDecimals)
    : Numberz(
      amount -
      amount * (fee / 100)
    ).toFixed(paymentConfig?.fiatDecimals)


  // converting usd to aed
  if (currency.toLowerCase() === "usd") finalAmount = Numberz(finalAmount * paymentConfig?.usdToAedPrice).toFixed(paymentConfig?.fiatDecimals);

  return Numberz(finalAmount).toFixed(paymentConfig?.fiatDecimals);
}

export const hasAccess = (accKey, accessLists) => accessLists?.length > 0 ? [...accKey, "superMerchant"].some(item => accessLists?.includes(item)) : false;


export const addBigNumber = (x, y) => new BigNumber(x).plus(new BigNumber(y)).toString();
export const subtractBigNumber = (x, y) => new BigNumber(x).minus(new BigNumber(y)).toString();
export const multiplyBigNumber = (x, y) => new BigNumber(x).times(new BigNumber(y)).toString();
export const divideBigNumber = (x, y) => new BigNumber(x).dividedBy(new BigNumber(y)).toString();

export const isExpiredDate = expiryDate => {
  const now = new Date(); // Get the current date and time
  const expiry = new Date(expiryDate); // Convert the expiry date string to a Date object

  return now > expiry; // Check if the current date is after the expiry date
}

export const calculateComparePrice = (amount, value) =>Numberz(divideBigNumber(amount, value)).toFixed(6);