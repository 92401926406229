import * as React from "react";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Numberz } from "../../../../utilities";

// const theme = createTheme();

const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  width: "100px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#495ECA",
  },
});

export default function PercentageSlider({ percent }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Typography
        sx={{
          color: "var(--neutral-500, #b0b0b0)",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "16.2px",
          letterSpacing: "0.06px",
        }}
      >
        {Numberz(percent).toFixed(2)}%
      </Typography>
      <BorderLinearProgress
        variant="determinate"
        value={Numberz(percent)}
      />
    </Box>
  );
}
