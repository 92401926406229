import React from "react";
import PortfolioComponent from "./portfolioComponent";
import { CardTitleComponentWithRightArrow } from "../../../../components/Cards";
import { CardLayout } from "../../../../components/Layouts/AllLayOuts";
export default function LeftGridComponentThree() {

  return (
    <CardLayout
      component={<>
        <CardTitleComponentWithRightArrow mainTitle="My Portfolio" to="/wallet/"/>
        <PortfolioComponent />
      </>}
    />
  );
}
