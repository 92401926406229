import React from 'react'
import DashboardDrawer from '../Drawer/drawer';
import { Box, Typography, IconButton } from '@mui/material';
import themes from '../Theme';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from 'react-router-dom';
const selectedTheme = localStorage.getItem('theme') || 'light';

const BodyLayout = ({ mainComponent, innerHead }) => {
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: themes[selectedTheme]?.colors?.body,
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
            }}
        >
            <DashboardDrawer innerHead={innerHead} />
            <Box sx={{ p: "15px" }}>{mainComponent}</Box>
        </Box>

    );
};

export const SideNavLayout = ({ component }) => <Box
    sx={{
        display: { xs: "none", sm: "none", md: "block" },
        p: "20px 8px",
        minWidth: '60px',
        height: '100vh',
        borderRight: themes[selectedTheme]?.colors?.border,
        background: themes[selectedTheme]?.colors?.sideNav
    }}>
    {component}
</Box>

export default BodyLayout;

export const CardLayout = ({ component, my, p, ovf }) => <Box
    sx={{
        borderRadius: "8px",
        border: themes[selectedTheme]?.colors?.border,
        background: themes[selectedTheme]?.colors?.card,
        overflow: ovf || 'hidden',
        textDecoration: 'none',
        color: themes[selectedTheme]?.text?.primary,
        my: my,
        p: p,
        
    }}
>
    {component}
</Box>

export const LinkLayout = ({ component, body, sx, to }) => <Box
    sx={{ ...sx, color: themes[selectedTheme]?.text?.primary }}
    component={component}
    to={to}
>
    {body}
</Box>

export const CardTitleLayout = ({ component }) => <Box
    sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        background: themes[selectedTheme]?.colors?.label,
        p: '7px 15px',
        color: themes[selectedTheme]?.text?.primary,
        fontFamily: "SF Pro Display",
        fontFeatureSettings: `'ss06' on`,
    }}
>
    {component}
</Box>


export const Header5Layout = ({ text, mt, fs, fw, clr }) => (
    <Typography
        sx={{
            mt: mt || "15px",
            color: clr || themes[selectedTheme]?.text?.primary,
            fontSize: fs || "20px",
            fontStyle: "normal",
            fontWeight: fw || 500,
            letterSpacing: " -0.4px",
        }}
    >
        {text}
    </Typography>
);

export const AuthPRightLayout = ({ text, mt, fs, ta }) => (
    <Typography
        sx={{
            mt: mt || "5px",
            color: themes[selectedTheme]?.text?.primary,
            fontSize: fs || "18px",
            fontStyle: "normal",
            fontWeight: 400,
            textAlign: ta
        }}
    >
        {text}
    </Typography>
);


export const BadgeLayout = ({ text }) => <Typography
    sx={{
        color: "var(--neutral-400, #FFF)",
        textAlign: "center",
        fontFeatureSettings: `'ss06' on`,
        fontFamily: "SF Pro Display",
        fontSize: "11px",
        fontStyle: "normal",
        lineHeight: "16.2px",
        letterSpacing: "0.06px",
        background: '#000',
        p: '1px 4px',
        borderRadius: '3px',
        fontWeight: 600
    }}
>
    {text}
</Typography>

export const RightArrowComponent = ({ to }) => <Box
    component={Link}
    to={to}
    sx={{
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16.2px",
        letterSpacing: "0.06px", textDecoration: "none",
        color: themes[selectedTheme]?.text?.primary,
        "&:hover": {
            color: "#495ECA",
        }
    }}
>
    <ArrowRightAltIcon />
</Box>

export const IconButton1Layout = ({ onClick, component }) => <IconButton
    onClick={onClick}
    edge="end"
    sx={{
        color: themes[selectedTheme]?.text?.primary,

    }}
>
    {component}
</IconButton>

export const Hamburger = () => <Box
    sx={{
        width: '30px',
        height: '30px',
        p: '5px',
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column'
    }}
>
    <Box sx={{ width: '16px', height: '2.5px', borderRadius: '5px', background: themes[selectedTheme]?.text?.primary }} />
    <Box sx={{ width: '20px', height: '2.5px', borderRadius: '5px', background: themes[selectedTheme]?.text?.primary }} />
    <Box sx={{ width: '14px', height: '2.5px', borderRadius: '5px', background: themes[selectedTheme]?.text?.primary }} />

</Box>

export const MobileSideNavLayout = ({ component }) => <Box
    sx={{
        background: themes[selectedTheme]?.colors?.sideNav,
    }}
>
    {component}
</Box>