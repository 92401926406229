import React from "react";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Box } from "@mui/material";

export const NotFoundMaterials = () => <Box>
  <BarChartIcon
    sx={{
      color: "#ffb800",
      fontSize: "60px",
    }}
  />
  <Box
    sx={{
      fontSize: "16px",
      fontWeight: 600,
    }}
  >
    No Records Found
  </Box>
  <Box
    sx={{
      mt: "10px",
      fontSize: '14px'
    }}
  >
    We could not find any related data
  </Box>
</Box>
const NotFound = () => {
  return (
    <>
      <Box
        sx={{
          p: "30px",
          display: "grid",
          placeContent: "center",
          textAlign: "center !important",
          fontFamily: "Inter !important",
        }}
      >
        <NotFoundMaterials />
      </Box>
    </>
  );
};

export default NotFound;
