import React from "react";
import { Box } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import { PrimaryBlackButton } from "../../../components/Buttons";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Link } from "react-router-dom";

const KYCPending = ({ kyc, to, title }) => {
  return (
    <Box
      sx={{
        height: "75vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >

      <Box sx={{}}>

        <Box
          elevation={0}
          sx={{
            padding: "20px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: "10px",
            width: "100%"
          }}
        >
          {/Completed/.test(kyc) ?
            <VerifiedIcon sx={{
              color: 'green',
              fontSize: '100px'
            }} /> :
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="145"
              height="126"
              viewBox="0 0 145 126"
              fill="none"
            >
              <path
                d="M60.8807 63.7125C63.2761 58.4488 66.685 53.7086 70.9127 49.7626C75.1403 45.8165 80.104 42.742 85.5201 40.7146C90.9362 38.6873 96.6986 37.7467 102.478 37.9468C108.258 38.1468 113.942 39.4835 119.204 41.8804C124.468 44.2758 129.208 47.6847 133.154 51.9124C137.1 56.14 140.175 61.1037 142.202 66.5198C144.23 71.9359 145.17 77.6983 144.97 83.4779C144.77 89.2576 143.434 94.9412 141.037 100.204C137.548 107.906 131.911 114.436 124.802 119.012C117.692 123.588 109.413 126.014 100.959 126C83.9712 126 68.4307 116.185 61.1324 100.833H0.291992V88.25C0.669492 81.0775 5.57699 75.2263 15.0145 70.5075C24.452 65.7888 36.2803 63.335 50.6253 63.0834C54.2116 63.0834 57.6091 63.3979 60.8807 63.7125ZM50.6253 0.166687C57.672 0.355437 63.5862 2.80919 68.3049 7.52794C73.0237 12.2467 75.3516 18.1609 75.3516 25.3334C75.3516 32.5059 73.0237 38.42 68.3049 43.1388C63.5862 47.8575 57.672 50.1854 50.6253 50.1854C43.5787 50.1854 37.6645 47.8575 32.9457 43.1388C28.227 38.42 25.8991 32.5059 25.8991 25.3334C25.8991 18.1609 28.227 12.2467 32.9457 7.52794C37.6645 2.80919 43.5787 0.355437 50.6253 0.166687ZM100.959 113.417C109.302 113.417 117.303 110.102 123.203 104.203C129.103 98.3032 132.417 90.3016 132.417 81.9584C132.417 73.6151 129.103 65.6135 123.203 59.714C117.303 53.8144 109.302 50.5 100.959 50.5C92.6154 50.5 84.6138 53.8144 78.7143 59.714C72.8147 65.6135 69.5003 73.6151 69.5003 81.9584C69.5003 90.3016 72.8147 98.3032 78.7143 104.203C84.6138 110.102 92.6154 113.417 100.959 113.417ZM94.667 63.0834H104.104V80.8259L119.456 89.6971L114.737 97.8763L94.667 86.2996V63.0834Z"
                fill="#110C2C"
              />
            </svg>
          }
          <Box
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              mt: "30px",
            }}
          >
            Your verification status is: - {kyc}.
          </Box>
          <Box
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              m: "15px 0px 25px",
            }}
          >{/Completed/.test(kyc) ?
            "You are verified" :
            "Your KYC verification is still in process."
            }
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>

            <Box
              component={Link}
              to={to || "/settings/kyc/company-verification/"}
            >
              <PrimaryBlackButton
                text={<>{to === "/dashboard" ? "Go to Dashboard" : "Next"} <ArrowRightAltIcon /></>}
                title={title || "Go to Next Step"}
              />

            </Box>


          </Box>
        </Box>

      </Box>
    </Box>
  );
};

export default KYCPending;
