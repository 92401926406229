import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import StatusShow, { PaymentTypeShow } from "../../../../components/Tables/StatusShow";
import { CardTitleComponentWithRightArrow } from "../../../../components/Cards";
import BasicTableWithBody, { NoRecordInTable, StyledTableCell, StyledTableRow } from "../../../../components/Tables";
import { CardLayout } from "../../../../components/Layouts/AllLayOuts";

export const UncheckedStep = () => (
  <Box
    sx={{
      zIndex: 1,
      color: "#fff",
      width: 45,
      minHeight: 45,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      background: "var(--neutral-50, #F9F9FA)",
    }}
  >
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 1.16675C10.2765 1.16675 10.5003 1.39061 10.5003 1.66675V12.1263L12.9801 9.64653C13.1754 9.45127 13.492 9.45127 13.6872 9.64653C13.8825 9.84179 13.8825 10.1584 13.6872 10.3536L10.3539 13.687C10.1586 13.8822 9.84203 13.8822 9.64677 13.687L6.31344 10.3536C6.11818 10.1584 6.11818 9.84179 6.31344 9.64653C6.5087 9.45127 6.82528 9.45127 7.02055 9.64653L9.50033 12.1263V1.66675C9.50033 1.39061 9.72418 1.16675 10.0003 1.16675ZM6.26646 2.53124C6.40473 2.77027 6.32305 3.07613 6.08402 3.2144C3.74133 4.56958 2.16699 7.10127 2.16699 10.0001C2.16699 14.3263 5.6741 17.8334 10.0003 17.8334C14.3266 17.8334 17.8337 14.3263 17.8337 10.0001C17.8337 7.10127 16.2593 4.56958 13.9166 3.2144C13.6776 3.07613 13.5959 2.77027 13.7342 2.53124C13.8725 2.29221 14.1783 2.21053 14.4174 2.3488C17.0563 3.87537 18.8337 6.72987 18.8337 10.0001C18.8337 14.8786 14.8788 18.8334 10.0003 18.8334C5.12181 18.8334 1.16699 14.8786 1.16699 10.0001C1.16699 6.72987 2.94431 3.87537 5.5833 2.3488C5.82233 2.21053 6.12819 2.29221 6.26646 2.53124Z"
        fill="#495ECA"
      />
    </svg>
  </Box>
);

const RecentTransactions = () => {
  const { recentTransactions } = useSelector((state) => state?.dashboard);
  return (

    <CardLayout
      my="15px"
      component={<>
        <CardTitleComponentWithRightArrow mainTitle="Recent Transactions" to="/transactions/" />
        <BasicTableWithBody
          tableHead={[
            "CreatedAt",
            "Type",
            "Network",
            "Token",
            "Amount",
            "Status",
          ]}
          tableBody={
            <>
              {recentTransactions?.length === 0 ? (
                <NoRecordInTable />
              ) : recentTransactions?.length > 0 ? (
                recentTransactions.map((row, index) => (
                  <StyledTableRow key={row?.createdAt + index + 'xtd'} sx={{height: '75px'}}>
                    <StyledTableCell align="left">
                      {moment(row?.createdAt).format("lll")}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      <PaymentTypeShow type={row?.type} />
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {row?.network}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {row?.token}
                    </StyledTableCell>

                    <StyledTableCell align="left">
                      {row?.currency + " " + row?.requestedfiatamount}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <StatusShow status={row?.status} />
                    </StyledTableCell>


                  </StyledTableRow>
                ))
              ) : (
                <NoRecordInTable />
              )}
            </>
          }
        />
      </>}
    />


  );
};

export default RecentTransactions;
