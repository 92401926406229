import React from "react";
import { Box } from "@mui/material";
import WalletComponentOne from "./components/WalletComponentOne";
import Chart from "../Dashboard/components/Left/chart";
import WalletComponentTwo from "./components/WalletComponentTwo";
import { CardTitleComponentWithRightArrow } from "../../components/Cards";
import { CardLayout } from "../../components/Layouts/AllLayOuts";

export default function WalletComponent() {
  return (
    <Box>
      <WalletComponentOne />


      <CardLayout
        component={<>
          <CardTitleComponentWithRightArrow mainTitle="Portfolio statistics" noArrow={true} />
          <Box sx={{ pr: '15px' }}>
            <Chart />
          </Box>
        </>}
      />


      <WalletComponentTwo />
    </Box>
  );
}
