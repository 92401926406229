import "./assets/css/style.css";
import AccessGuard, { AccessGuardWithLayout, PermissionGuard } from "./components/Guard";
import LoaderComponent, { LoaderComponentMin } from "./components/Guard/LoaderComponent";
import { accessKeys } from "./components/Guard/accessKeys";
import { GafahPay, AboutComponent, ContactUsComponent, PricingComponent, SupportComponent, FAQComponent, LoginComponent, SignUpComponent, Dashboard, Wallet, InitiateForgetPassword, VerifyForgetPassword, UpdateProfile, OtpVerification, ResetPassword, APIKeyLists, ChangePassword, Profile, Notification, Transaction, Prices, Security, IdentityVerificationComponent, CompanyVerification, POSSelect, POSSuccess, GafahWallet, ExternalWallet, SupportDetails, POSFailed, InitiateRefund, Vat, OngoingTicket, CookiesPolicy, PrivacyPolicy, TermsNCondition, SmartLink, SmartLinks, LastStep, WithdrawalRequests, ExternalCurrency, Referral, Roles, NotFound } from "./containers";
import React from "react";
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RapidocExample from "./containers/Documentation";
import { Box } from "@mui/material";
function App() {
  return (
    <BrowserRouter>
      <Box sx={{
        fontFamily: "SF Pro Display",
      }}>
        <ToastContainer position="bottom-center" />
      </Box>


      <Routes>
        <Route exact path="/" element={<LoaderComponentMin component={<GafahPay />} />} />
        <Route path="/aboutus" element={<AboutComponent />} />
        <Route path="/contactus" element={<ContactUsComponent />} />
        <Route path="/pricing" element={<LoaderComponent component={<PricingComponent />} />} />
        <Route path="/support" element={<LoaderComponent component={<SupportComponent />} />} />
        <Route path="/support/:type" element={<LoaderComponent component={<SupportDetails />} />} />
        <Route path="/faq" element={<LoaderComponent component={<FAQComponent />} />} />
        <Route path="/cookiespolicy" element={<LoaderComponent component={<CookiesPolicy />} />} />
        <Route path="/privacypolicy" element={<LoaderComponent component={<PrivacyPolicy />} />} />
        <Route path="/termsandconditions" element={<LoaderComponent component={<TermsNCondition />} />} />
        <Route path="/signup" element={<LoaderComponent component={<SignUpComponent />} />} />
        <Route path="/account/setup" element={<AccessGuard accKey={accessKeys?.accountSetup} component={<UpdateProfile />} />} />
        <Route path="/account/verify" element={<AccessGuard accKey={accessKeys?.verifyRegisterEmail} component={<OtpVerification />} />} />
        <Route path="/login" element={<LoaderComponent component={<LoginComponent />} />} />
        <Route path="/notification" element={<Notification />} />

        <Route path="/dashboard" element={<PermissionGuard innerHead={["Dashboard"]} accKey={["Dashboard-read"]} component={<Dashboard />} />} />

        {/* configurations */}
        <Route path="/api-keys" element={<PermissionGuard innerHead={["Api Keys"]} accKey={['Apikey-read']} component={<APIKeyLists />} />} />
        <Route path="/smartlinks" element={<PermissionGuard innerHead={["Smartlinks"]} accKey={['Smartlink-read']} component={<SmartLinks />} />} />
        <Route path="/vat" element={<PermissionGuard innerHead={["Vat"]} accKey={['Vat-read']} component={<Vat />} />} />
        <Route path="/configurations/external" element={<PermissionGuard innerHead={["Dashboard"]} accKey={['ExternalCurrency-read']} component={<ExternalCurrency />} />} />
        {/* configurations end */}

        <Route path="/wallet" element={<PermissionGuard innerHead={["Wallet"]} accKey={["Dashboard-read"]} component={<Wallet />} />} />
        <Route path="/transactions" element={<PermissionGuard innerHead={["Transactions"]} accKey={["TransactionBasic-read", "TransactionAdvance-read"]} component={<Transaction />} />} />
        <Route path="/prices" element={<PermissionGuard innerHead={["Prices"]} accKey={["Prices-read"]} component={<Prices />} />} />
        {/* <Route path="/withdrawal" element={<AccessGuard accKey={accessKeys?.merchant} component={<Withdrawal />} />} /> */}
        <Route path="/withdrawal-request" element={<PermissionGuard innerHead={["Withdrawal"]} accKey={["WithdrawalRequest-read"]} component={<WithdrawalRequests />} />} />
        <Route path="/ongoing-ticket" element={<PermissionGuard innerHead={["Tickets"]} accKey={["Ticket-read"]} component={<OngoingTicket />} />} />

        {/* settings */}
        <Route path="/settings/profile" element={<PermissionGuard innerHead={["Profile"]} accKey={["Profile-read"]} component={<Profile />} />} />
        <Route path="/settings/change-password" element={<PermissionGuard innerHead={["Password"]} accKey={["SettingsChangePassword-update"]} component={<ChangePassword />} />} />
        <Route path="/settings/security" element={<PermissionGuard innerHead={["Security"]} accKey={["Security-update"]} component={<Security />} />} />
        {/* <Route path="/settings/privacy-notifications" element={<AccessGuardWithLayout accKey={accessKeys} component={<PrivacyNotifications />} />} /> */}
        {/* <Route path="/settings/payment-methods" element={<AccessGuardWithLayout accKey={accessKeys} component={<PaymentMethod />} />} /> */}
        <Route path="/settings/kyc/identity-verification" innerHead={["KYC"]} element={<PermissionGuard accKey={["Kyc-read"]} component={<IdentityVerificationComponent />} />} />
        <Route path="/settings/kyc/company-verification" innerHead={["KYC"]} element={<PermissionGuard accKey={["Kyc-read"]} component={<CompanyVerification />} />} />
        <Route path="/settings/kyc/withdrawal-verification" innerHead={["KYC"]} element={<PermissionGuard accKey={["Kyc-read"]} component={<LastStep />} />} />

        {/* POS */}
        <Route path="/smartlink/:businessname/:code" element={<SmartLink />} />
        <Route path="/invoice" element={<POSSelect />} />
        <Route path="/invoice/gafah" element={<GafahWallet />} />
        <Route path="/invoice/external" element={<ExternalWallet />} />
        <Route path="/invoice/success/:id" element={<POSSuccess />} />
        <Route path="/invoice/failed/:id" element={<POSFailed />} />
        <Route path="/pos/initiate-refund" element={<InitiateRefund />} />
        <Route path="/pos/refund-success/:id" element={<POSSuccess />} />

        {/* reset password */}
        <Route path="/reset-password/initiate" element={<InitiateForgetPassword />} />
        <Route path="/reset-password/verify-email" element={<AccessGuard accKey={accessKeys?.verifyForgetPassEmail} component={<VerifyForgetPassword />} />} />
        <Route path="/reset-password/set-password" element={<AccessGuard accKey={accessKeys?.resetPassword} component={<ResetPassword />} />} />

        <Route path="/documentation" element={<RapidocExample />} />

        {/* referral */}
        <Route path="/referral/dashboard" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<Referral />} />} />
        <Route path="/referral/earnings" element={<AccessGuardWithLayout accKey={accessKeys?.marchant} component={<Referral />} />} />

        <Route path="/accessibility" element={<PermissionGuard innerHead={["Accessibility"]} accKey={["Accessibility-read"]} component={<Roles />} />} />
        <Route path="/*" element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
