import { Box } from "@mui/material";
import React, { useState } from "react";
import { CopyToClipboardButton } from "../../components/Helpers/helperComponents";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { DeleteDialog } from "../../components/Dialog";
import config from "../../config/config";
import { hasAccess, joinStringAndConvertLowerCase } from "../../utilities";
import { StyledTableCell } from "../../components/Tables";
import { PrimaryIconButton } from "../../components/Buttons";
const SmartLinkList = ({ item }) => {
  const { accessLists } = useSelector(state => state.auth)

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    try {
      setLoading(true)
      const req = {
        url: "removeSmartLink",
        action: "ACTION_GET_RETURNED_SMARTLINK",
        payload: {
          sid: id,
        },
      };
      await dispatch(PostHandler(req));
      setLoading(false)
    } catch (error) {
      console.error(error);
    }
  };

  return (

    <>
      <StyledTableCell align="left">
        {item?.currency + " " + item?.amount}
      </StyledTableCell>
      <StyledTableCell align="left">
        {item?.code}
      </StyledTableCell>
      <StyledTableCell align="left">
        {moment(item?.expiryAt).format("lll")}
      </StyledTableCell>

      <StyledTableCell align="left">
        <Box sx={{ display: 'flex', gap: '5px' }}>
          {item?.merchantDetail?.businessname && <CopyToClipboardButton fontsize="30px" background="#a8abaa52" content={`${config.APP_URL}smartlink/${joinStringAndConvertLowerCase(item?.merchantDetail?.businessname)}/${item?.code}/`} />}



          {hasAccess(["Smartlink-delete"], accessLists) &&
            <DeleteDialog
              isShow={true}
              customComponent={
                <PrimaryIconButton
                  background="#cf6679"
                  title="Click to delete"
                  text={<DeleteOutlineOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900 }} />}
                />
              }
              title="Are sure want to delete"
              execute={() => handleDelete(item?.id)}
              executeLoading={loading}
            />
          }


        </Box>
      </StyledTableCell>
    </>








  );
};

export default SmartLinkList;
