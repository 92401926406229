import React from 'react'
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import LogoImg from "../../../assets/img/logo2.png";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import themes from '../../../components/Theme';
const selectedTheme = 'light';


export const MenuItemBox = ({ text, active, complete }) => <Box
    sx={{
        p: '30px 15px',
        borderBottom: '1px solid #0003',
        fontSize: '14px',
        fontWeight: 500,
        background: active ? '#495ECA' : '#fff',
        color: active && '#fff',
        display: 'flex',
        alignItems: 'center',
        gap: '5px'

    }}>
    {complete && <DoneAllIcon sx={{ color: '#495ECA' }} />} {text}
</Box>

export const MerchantAndLogoComponent = ({ merchantName, bb }) => <Box sx={{
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    borderBottom: bb || "1px solid var(--neutral-200, #E3E3E8)",
    pb: '10px'
}}>
    <Box
        sx={{
            fontSize: '14px', fontWeight: 900,
            color: themes[selectedTheme]?.text?.primary
        }}

    >{merchantName}</Box>
    <Box
        component="img"
        src={LogoImg}
        sx={{ height: "20px", width: "auto" }}
    />
</Box>

export const OrderBox = ({ fs, amount, mainTitleText, subTitleText, rightSubTitleText }) => <Box sx={{
    display: 'flex',
    gap: '10px',
    justifyContent: 'space-between',
    border: "1px solid var(--neutral-200, #E3E3E8)",
    background: "#f0f0f169",
    borderRadius: '8px',
    p: '10px',
    mt: '10px'
}}>
    <Box>
        <Typography sx={{ fontSize: fs || '14px', fontWeight: 900 }}>{mainTitleText}</Typography>
        {subTitleText && <Typography sx={{ fontSize: '12px' }}>{subTitleText}</Typography>}
    </Box>

    <Box sx={{ textAlign: 'right' }}>
        <Typography sx={{ fontSize: fs || '14px', fontWeight: 600 }}>
            {amount}
        </Typography>
        {rightSubTitleText && <Box sx={{ fontSize: '12px' }}>{rightSubTitleText}</Box>}

    </Box>

</Box>

export const PaymentButton = ({ component, to, icon, titleText, rightIcon, bs, border, onClick }) => <Box
    component={component}
    to={to}
    sx={{
        borderRadius: "5px",
        border: border || 'none',
        width: '100%',
        textAlign: "center",
        boxShadow: bs || 'none',
        textDecoration: "none",
        cursor: 'pointer',
    }}
    onClick={onClick}
>
    <Box
        sx={{
            height: '65px',
            p: "0px 16px",
            color: themes[selectedTheme]?.text?.primary,
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "5px",
            "@media (max-width:600px)": { fontSize: "16px" },
        }}
    >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            {icon} {titleText}
        </Box>
        {rightIcon || <ArrowRightAltIcon />}
    </Box>
</Box>

export const PaymentFooter = () => <Grid container sx={{ justifyContent: "center", pb: '15px' }}>
    <Grid
        item
        xs={12}
        md={12}
        sx={{
            fontSize: "12px",
            fontWeight: "700",
            mt: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "SF Pro Display",
            gap: "3px",
        }}
    >
        <Box
            component="img"
            src={LogoImg}
            sx={{ height: "20px", width: "auto" }}
        />
        <Box
            sx={{
                fontFamily: "SF Pro Display",
                // color: themes[selectedTheme]?.text?.primary


            }}
        >
            Secure Payment
        </Box>
    </Grid>
</Grid>


const PaymentLayout = ({ bodyComponent, selectCrypto, active, selectWalletComplete, selectCurrencyComplete }) =>
    <Box
        sx={{
            background: themes[selectedTheme]?.colors?.body || "#F1F5F966",
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center'
        }}>
        <Box
            sx={{
                mt: {
                    xs: '0px',
                    md: '40px'
                },
            }}>
            <Box sx={{
                display: 'flex',
                borderRadius: "5px",
                border: "1px solid var(--neutral-200, #E3E3E8)",
                overflow: 'hidden',
                background: '#fff'
            }}>
                <Box sx={{
                    background: '#f0f0f169',
                    minWidth: '250px',
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}>
                    <MenuItemBox text="Select Wallet" active={active === 'selectWallet'} complete={selectWalletComplete} />
                    {selectCrypto && <MenuItemBox text="Select Crypto" active={active === 'selectCurrency'} complete={selectCurrencyComplete} />}
                    <MenuItemBox text="Payment Checkout" active={active === 'paymentCheckout'} />
                </Box>
                <Box sx={{
                    borderLeft: "1px solid var(--neutral-200, #E3E3E8)",
                    minWidth: '400px',
                    width: '100%',
                    maxWidth: '500px',
                    // width: '100%',
                    minHeight: {
                        xs: 'calc(100vh - 30px)',
                        md: '50vh'
                    }
                }}>
                    {bodyComponent}
                    <PaymentFooter />
                </Box>
            </Box>

        </Box>


    </Box>

export default PaymentLayout;
