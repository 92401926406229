import React from "react";
import { Box, Typography } from "@mui/material";
import PercentageSlider from "./slider";
import { Numberz } from "../../../../utilities";

const selectedTheme = localStorage.getItem('theme') || 'light';

export default function PortfolioRowComponent(props) {
  const {
    cryptoName,
    cryptoShortName,
    icon,
    percentage,
    cryptoValue,
    fiatValue,
    fiatCurrency,
  } = props;
  return (
    <Box
      sx={{
        width: "100%",
        borderTop: selectedTheme === "light" && "1px solid #E3E3E8",
        p: "12px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <img src={icon} alt={icon} width="25px" style={{ minWidth: "25px" }} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box
              sx={{
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
            >
              {cryptoName}
            </Box>
            <Typography
              sx={{
                color: "var(--neutral-400, #b0b0b0)",
                textAlign: "center",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: "0.06px",
              }}
            >
              {cryptoShortName}
            </Typography>
          </Box>
          <PercentageSlider percent={Numberz(percentage).toFixed(2)} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          // gap: "4px",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {Numberz(cryptoValue).toFixed(6) + " " + cryptoShortName}
        </Typography>

        <Typography
          sx={{
            color: "var(--neutral-500, #b0b0b0)",
            fontFeatureSettings: `'ss06' on`,
            textAlign: "right",
            fontFamily: "SF Pro Display",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
          }}
        >
          {fiatCurrency} {fiatValue}
        </Typography>
      </Box>
    </Box>
  );
}
