import React from "react";
import POSStepper from "./posStepper";
import PaymentLayout from "./Component/PosComponents";


const POSSelect = (props) => <PaymentLayout active="selectWallet" bodyComponent={<POSStepper />}/>

export default POSSelect;




