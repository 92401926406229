import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  AuthHeader,
  AuthP,
} from "../../../components/Helpers/helperComponents";
import { InputLabels } from "../../../components/Helpers/label";
import { GoogleStoreSvg } from "../../../components/Helpers/svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../../Redux/RequestHandlers";
import applestore from "../../../assets/img/signup/applestore.png";
import { ButtonLoader, PrimaryButton } from "../../../components/Buttons";
import {
  PasswordValidation,
  validEmail,
} from "../../../Validation/formValidations";
import { PrimaryInputComponents } from "../../../components/Style";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SocialAppLogin from "../../../components/Firebase/Sso";
export default function SignUpFormComponent(props) {
  const { radioInput, setRadioInput } = props;
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisable, setIsDisable] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { num, lc, uc, sp, len } = PasswordValidation(inputs.password || "");
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let payload = inputs;
      if (props?.refCode) payload.refCode = props?.refCode;
      const req = {
        url: "registration",
        payload: payload,
        action: "ACTION_SIGN_UP",
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (!res?.data?.success) {
        setErrors((prev) => ({ validEmail: true }));
        setErrMsg(res?.data?.message[0]?.message);
      }
      if (res?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          res?.data?.data?.access_token
        );
        navigate("/account/verify/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(
      validEmail(inputs?.email) &&
        inputs?.password &&
        inputs?.confirmPassword &&
        !errors?.criteria &&
        !errors?.matchPass
        ? false
        : true
    );
  }, [errors?.criteria, errors?.matchPass, inputs]);

  useEffect(() => {
    if (inputs?.email) {
      let vEmail = validEmail(inputs?.email);
      setErrors((prev) => ({ ...prev, validEmail: !vEmail }));
      setErrMsg(vEmail ? "" : "invalid Email");
    }
  }, [inputs?.email]);

  useEffect(() => {
    if (inputs?.password && inputs.confirmPassword)
      setErrors((prev) => ({
        ...prev,
        matchPass: inputs.password === inputs.confirmPassword ? false : true,
      }));
  }, [inputs?.password, inputs?.confirmPassword]);

  useEffect(() => {
    if (inputs.password?.length > 0)
      setErrors((prev) => ({
        ...prev,
        criteria: num && lc && uc && sp && len ? false : true,
      }));
  }, [inputs?.password, num, lc, uc, len, sp]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          width: "100%",
          maxWidth: "508px",
          height: "100%",
        }}
      >
        <Box
        sx={{
          fontSize: {
            xs: '20px',
            lg: '24px'
          },
          fontWeight: 600,
          mt: '10px'
        }}>
          Create an account
        </Box>

        <Box sx={{
          fontSize: {
            xs: '12px',
            lg: '14px'
          }
        }}>
        Great to see you! Just a few quick steps to sign up.
        </Box>
        <AuthHeader text="" />
        <AuthP text="" />

        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={radioInput}
          name="radio-buttons-group"
        >
          <Box sx={{ display: "flex" }}>
            <FormControlLabel
              value="bussiness"
              control={<Radio />}
              onChange={() => setRadioInput("bussiness")}
              label={<InputLabels label="Business" />}
            />
            <FormControlLabel
              value="personal"
              control={<Radio />}
              onChange={() => setRadioInput("personal")}
              label={<InputLabels label="Personal" />}
            />
          </Box>
        </RadioGroup>

        {radioInput === "bussiness" ? (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "15px",
                width: "100%",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
              >
                <InputLabels label="Email" mt="0px" />
                <PrimaryInputComponents
                  error={errors?.validEmail}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={inputs?.email || ""}
                  placeholder="Enter your email address"
                />
                {errors?.validEmail && (
                  <Box
                    sx={{
                      fontSize: "12px",
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      fontFamily: "SF Pro Display",
                      flexWrap: "wrap",
                      color: "red",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                      {errMsg}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
              >
                <InputLabels label="Password" mt="0px" />
                <PrimaryInputComponents
                  error={errors?.criteria}
                  id="password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={inputs?.password || ""}
                  placeholder="Enter your password"
                />
                <Box
                  sx={{
                    fontSize: "12px",
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    fontFamily: "SF Pro Display",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {len ? (
                      <CheckCircleOutline
                        fontSize="14px"
                        sx={{ color: "green" }}
                      />
                    ) : (
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                    )}
                    8 Characters
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {uc ? (
                      <CheckCircleOutline
                        fontSize="14px"
                        sx={{ color: "green" }}
                      />
                    ) : (
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                    )}
                    1 Uppercase
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {lc ? (
                      <CheckCircleOutline
                        fontSize="14px"
                        sx={{ color: "green" }}
                      />
                    ) : (
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                    )}
                    1 Lowercase
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {sp ? (
                      <CheckCircleOutline
                        fontSize="14px"
                        sx={{ color: "green" }}
                      />
                    ) : (
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                    )}
                    1 Special Character
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {num ? (
                      <CheckCircleOutline
                        fontSize="14px"
                        sx={{ color: "green" }}
                      />
                    ) : (
                      <HighlightOffIcon fontSize="14px" sx={{ color: "red" }} />
                    )}
                    1 Number
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "3px" }}
              >
                <InputLabels label="Confirm Password" mt="0px" />
                <PrimaryInputComponents
                  error={errors?.matchPass}
                  id="confirmPassword"
                  type="password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={inputs?.confirmPassword || ""}
                  placeholder="Re-enter password"
                />

                <Box
                  sx={{
                    fontSize: "12px",
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    fontFamily: "SF Pro Display",
                    flexWrap: "wrap",
                  }}
                >
                  {inputs?.confirmPassword && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {!errors?.matchPass ? (
                        <CheckCircleOutline
                          fontSize="14px"
                          sx={{ color: "green" }}
                        />
                      ) : (
                        <HighlightOffIcon
                          fontSize="14px"
                          sx={{ color: "red" }}
                        />
                      )}
                      Matched Password
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "20px",
                width: "100%",
              }}
            >
              {isLoading ? (
                <ButtonLoader />
              ) : (
                <PrimaryButton
                  title="Create Account"
                  onClick={handleSubmit}
                  disabled={isDisable}
                />
              )}
              <SocialAppLogin {...props} />
            </Box>
            <Typography
              sx={{
                mt: "20px",
                color: "#0E0637",
                fontFeatureSettings: `'ss06' on`,
                fontFamily: "SF Pro Display",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20.6px",
                lineSpacing: "0.08px",
                textAlign: "center",
                width: "100%",
              }}
            >
              Have an account already?{" "}
              <Link
                to="/login/"
                style={{
                  color: "var(--main-colors-primary, #5235E8)",
                  textDecoration: "none",
                }}
              >
                Sign in
              </Link>
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              py: "90px",
              display: "flex",
              flexDirection: "column",
              gap: "80px",
            }}
          >
            <Link to="https://apps.apple.com/ae/app/gafah/id6478121453">
              <img src={applestore} alt="apple store" />
            </Link>
            <Link to="/">
              <GoogleStoreSvg />
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
}
