import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import POSCryptoSearchBoxes from "./posCryptoSearchBoxComponent";
import { useSelector, useDispatch } from "react-redux";
import { CountdownComponent2 } from "../../../components/Helpers/countdown";
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { GetHandlerForPaymentGateway } from "../../../Redux/RequestHandlers";

export default function POSCryptoComponent({
  currency,
  amount,
  setNetwork,
  setToken,
  token,
  shouldContinue,
  invoiceId,
  invoiceDetails,
  setSwapLoading
}) {
  const { listofcurrencies } = useSelector((state) => state?.pos);
  const [timer, setTimer] = useState(15);
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadingId, setLoadingId] = useState('');

  const handleClick = (item) => {
    setSelectedItem(item);
    setTimer(15); // Reset timer when a new item is selected
  };

  const getRealTimePrice = async () => {
    setLoadingId(selectedItem?.id);
    setSwapLoading(true);
    try {
      const req = {
        url: "getRealTimePrice",
        action: "ACTION_GET_REAL_TIME_PRICE",
        params: {
          pricesymbol: selectedItem?.pricesymbol,
          amount: amount,
          currency: currency

        },
        state: invoiceId,
        reqPayload: selectedItem,
      };
      await dispatch(GetHandlerForPaymentGateway(req));
      setLoadingId('');
      setSwapLoading(false);
      // if (!result?.data?.success) setShouldContinue(false);
    } catch (error) {
      console.error(error);
      setSwapLoading(false);
    }
  };

  useEffect(() => {
    let intervalId;

    if (selectedItem && shouldContinue) {
      setNetwork(selectedItem?.network)
      setToken(selectedItem?.token)
      getRealTimePrice(); // Fetch real-time price immediately on selection

      intervalId = setInterval(() => {
        getRealTimePrice();
        setTimer(15); // Reset timer every time the price is fetched
      }, 15000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId); // Clear interval on component unmount or when selectedItem changes
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, shouldContinue]);

  return (
    <Box key={currency + amount + token} sx={{ mt: '15px' }}>

      <>
        <Grid container sx={{ width: "100%" }}>
          {listofcurrencies?.map((item, index) => (
            <Grid
              key={item?.id + index + "3e" + index}
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              onClick={() => handleClick(item)}
              sx={{
                display: "flex",
                p: '5px',
                mt: '10px',
                width: "100%",
              }}
            >
              <POSCryptoSearchBoxes
                data={item}
                active={selectedItem && item?.id === selectedItem?.id}
                loading={loadingId}
                swapValue={selectedItem && (
                  <Box sx={{
                    display: 'flex',
                    fontSize: '11px',
                    mt: '5px',
                    pl: '5px'
                  }}>
                    1 {selectedItem?.token}
                    <SwapHorizOutlinedIcon sx={{ fontSize: '12px' }} />
                    {invoiceDetails?.realTimePrice && invoiceDetails?.realTimePrice[invoiceDetails?.selectedCurrency?.pricesymbol][invoiceDetails?.currency === "AED" ? "aed" : "usd"] + " " + invoiceDetails?.currency}
                  </Box>
                )}
              />
            </Grid>
          ))}
        </Grid>

        {token && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: 'center' }}>
            <CircularProgress size="1rem" />
            <CountdownComponent2
              content="refreshing in "
              colour="red"
              expireIn={timer}
              setTimer={setTimer}
            />
          </Box>
        )}
      </>

      {/* <Box sx={{
          my: '2rem',
          py: '2rem',
          background: 'red',
          px: '15px',
          borderRadius: '10px'
        }}>
          <Typography sx={{ color: '#fff' }}>
            External payment service is down for maintenance. Please go back and try a different method like Gafah or try again later.
          </Typography>
        </Box> */}


    </Box>
  );
}
