import React, { useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import PendingIcon from "@mui/icons-material/Pending";
import StatusShow from "../../../components/Tables/StatusShow";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { hasAccess, Numberz } from "../../../utilities";
import BasicTableWithBody, {
  StyledTableCell,
  StyledTableRow,
} from "../../../components/Tables";
import { useSelector } from "react-redux";
import { ContentRow } from "../../../components/Dialog";




const TransactionModal = (props) => {
  const { data } = props;
  const { accessLists } = useSelector(state => state.auth)

  const [viewMore, setViewMore] = useState(false);

  return (
    <>
      <Box sx={{
        p: '15px',
        // textAlign: 'center'
      }}>
        Order ID - {data?.id}
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/confirmed|completed/.test(data?.status?.toLowerCase()) && (
          <VerifiedIcon
            sx={{
              color: "green",
              fontSize: "100px",
            }}
          />
        )}

        {/initiated|processing/.test(data?.status?.toLowerCase()) && (
          <PendingIcon
            sx={{
              color: "#f6b819",
              fontSize: "100px",
            }}
          />
        )}

        {/declined|failed/.test(data?.status?.toLowerCase()) && (
          <AnnouncementIcon
            sx={{
              color: "red",
              fontSize: "100px",
            }}
          />
        )}
        <StatusShow status={data?.status} />
      </Box>

      <Box
        sx={{
          width: "100%",
          borderRadius: "5px",
          p: '15px',
          // my: "4rem",
        }}
      >



        {/* <ContentRow left="Name" right={data?.name} /> */}
        {data?.mode === "Gafah" && data?.fromuserDetails && (
          <ContentRow
            left="Payment From"
            right={
              <>{`${data?.fromuserDetails?.firstname} ${data?.fromuserDetails?.lastname}`}</>
            }
          />
        )}

        <ContentRow
          left="Crypto Token"
          right={
            <>
              {Numberz(data?.amount)} {data?.token} ({data?.network})
            </>
          }
        />
        <ContentRow left="Type" right={data?.type} />
        {["Gafah", "External"].includes(data?.mode) && (
          <ContentRow left="Mode" right={data?.mode} />
        )}

        <ContentRow
          left="Amount"
          right={
            <>{`${data?.currency} ${Numberz(data?.requestedfiatamount)}`}</>
          }
        />
        {hasAccess(["TransactionAdvance-read"], accessLists) &&
          data?.fee && (
            <ContentRow
              left="Fee"
              right={
                <>
                  {`- ${data?.currency} ${Numberz(
                    data?.requestedfiatamount * (data?.fee / 100)
                  ).toFixed(4)} (${data?.fee}%)`}
                </>
              }
            />
          )}
        {data?.vat && (
          <ContentRow
            left="Vat"
            right={
              <>
                {`+ ${data?.currency} ${Numberz(
                  data?.requestedfiatamount * (5 / 100)
                ).toFixed(4)} (5 %)`}
              </>
            }
          />
        )}
        {hasAccess(["TransactionAdvance-read"], accessLists) &&
          <ContentRow
            left="Payment Amount"
            right={
              <>
                {`${data?.currency} ${data?.vat
                  ? Numberz(
                    data?.requestedfiatamount -
                    data?.requestedfiatamount * (data?.fee / 100) +
                    data?.requestedfiatamount * (5 / 100)
                  ).toFixed(4)
                  : Numberz(
                    data?.requestedfiatamount -
                    data?.requestedfiatamount * (data?.fee / 100)
                  ).toFixed(4)
                  }`}
              </>
            }
          />
        }
        <ContentRow
          left="Transaction Initiate Timing"
          right={moment(data?.createdAt).format("llll")}
        />
        {data?.status === "Completed" && (
          <ContentRow
            left="Transaction Complete Timing"
            right={moment(data?.updatedAt).format("llll")}
          />
        )}

        {data?.mode === "External" && data?.externalTransactions?.length > 0 && (
          <Box
            sx={{
              cursor: "pointer",
              color: "green",
              textAlign: "right",
            }}
            onClick={() => setViewMore(!viewMore)}
          >
            View {viewMore ? "less" : "more"} details about external
            transactions
          </Box>
        )}
        {viewMore && data?.externalTransactions && (
          <Box>
            External Transaction Details:
            <BasicTableWithBody
              tableHead={[
                "From Publickey",
                "Transactionhash",
                "Amount",
                "Status",
              ]}
              tableBody={
                <>
                  {data?.externalTransactions?.map((row) => (
                    <StyledTableRow key={row?.transactionhash}>
                      <StyledTableCell component="th" scope="row">
                        {row?.frompublickey}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.transactionhash}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.amount}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <StatusShow status={row?.status} />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </>
              }
            />
          </Box>
        )}
      </Box>

    </>
  );
};

export default TransactionModal;
