export const ACTION_INVOICE_DETAILS = "ACTION_INVOICE_DETAILS";
export const ACTION_INITIATE_INVOICE_PAYMENT = "ACTION_INITIATE_INVOICE_PAYMENT";
export const ACTION_CLEAR_GAFAH_WALLET_PAYMENT = "ACTION_CLEAR_GAFAH_WALLET_PAYMENT";
export const ACTION_GET_LIST_OF_CURRENCIES = "ACTION_GET_LIST_OF_CURRENCIES";
export const ACTION_GET_SMARTLINK_DETAIL = "ACTION_GET_SMARTLINK_DETAIL";
export const ACTION_GET_REAL_TIME_PRICE = "ACTION_GET_REAL_TIME_PRICE";
export const ACTION_SET_SOCKET_DATA = "ACTION_SET_SOCKET_DATA";
export const ACTION_CLEAR_PAYMENT_DETAILS = "ACTION_CLEAR_PAYMENT_DETAILS";
export const ACTION_REFUND_SUBMIT = "ACTION_REFUND_SUBMIT";
export const ACTION_REFUND_INITIATE = "ACTION_REFUND_INITIATE";
export const ACTION_REFUND_INITIATEX = "ACTION_REFUND_INITIATEX";
export const ACTION_REFUND_INITIATE1 = "ACTION_REFUND_INITIATE1";
export const TRANSACTION_POOLING = "TRANSACTION_POOLING";
export const ACTION_PAYLOAD_SET = "ACTION_PAYLOAD_SET";

const initialState = {
  smartLinkDetail: null,
  invoices: null,
};

export const POSReducer = (state = initialState, action) => {
  // const returnObj = { ...state }
  switch (action.type) {
    case ACTION_PAYLOAD_SET:
      return {
        ...state,
        invoices: {
          ...state?.invoices,
          [action?.reqState]: action.payload
        }
      };

    case ACTION_GET_SMARTLINK_DETAIL:
      if (!action?.state?.code) return state;
      return {
        ...state,
        smartLinkDetail: {
          ...state?.smartLinkDetail,
          [action?.state?.code]: action?.payload?.data
        }
      };

    case ACTION_INVOICE_DETAILS:
      if (!action?.payload?.data?.id?.id) return state;

      const invoiceData = {
        paymentState: 'active',
        amount: action?.payload?.data?.amount,
        currency: action?.payload?.data?.currency,
        id: action?.payload?.data?.id?.id,
        jwt: action?.payload?.data?.jwt_token,
        paymentMethod: null,
        paymentLogs: [],
        redirectLink: null,
        expireIn: action?.payload?.data?.jwt_token?.expiresIn,
        refundSubmit: null,
        currentPage: 'invoice'
      }
      if (action?.payload?.data?.merchantName) invoiceData.merchantName = action?.payload?.data?.merchantName;
      if (action?.payload?.data?.exchangeDetails) invoiceData.exchangeDetails = action?.payload?.data?.exchangeDetails;

      return {
        ...state,
        invoices: {
          ...state?.invoices,
          [action?.payload?.data?.id?.id]: invoiceData,
        },
      };

    case ACTION_REFUND_INITIATE:
      return {
        ...state,
        paymentDetails: action?.payload?.id,
        socketPayment: action?.payload,
        socketLogs: [...state?.socketLogs, action?.payload]
      };

    case ACTION_REFUND_INITIATEX:
      return {
        ...state,
        socketPayment: action?.payload,
      };

    case ACTION_CLEAR_PAYMENT_DETAILS:
      let tempInvoices = state.invoices;
      if (action?.payload) delete tempInvoices[action?.payload];
      let kk = {
        ...state,
        invoices: tempInvoices,
        smartLinkDetail: null
      }
      return kk;

    case ACTION_REFUND_SUBMIT:
      if (!action?.payload?.id) return state;
      let invoicesData = state.invoices[action?.payload?.id]
      let refundDetails = state.invoices[action?.payload?.id]?.refundDetails;
      refundDetails.refundSubmit = true;
      invoicesData.refundDetails = refundDetails;
      return {
        ...state,
        invoices: {
          ...state?.invoices,
          [action?.payload?.id]: invoicesData
        },
      };

    case ACTION_SET_SOCKET_DATA:
      let tempState = {};
      if (action?.reqState) {
        tempState = state.invoices[action?.reqState];
        const dx = action?.payload;
        let paymentLogs =
          dx?.status === "priceChange"
            ? [dx, ...tempState?.paymentLogs]
            : [...tempState?.paymentLogs];
        let kk = {
          ...tempState,
          ...action?.payload
        }
        if (paymentLogs?.length > 0) kk.paymentLogs = paymentLogs;
        tempState = kk;
      }
      return {
        ...state,
        invoices: {
          ...state.invoices,
          [action?.reqState]: tempState
        }
      };

    case ACTION_INITIATE_INVOICE_PAYMENT:
      return {
        ...state,
        selectedWallet: action?.reqPayload?.wallet,
        paymentDetails: action?.payload
      };

    case ACTION_CLEAR_GAFAH_WALLET_PAYMENT:
      return {
        ...state,
        selectedWallet: null,
        paymentDetails: null
      };

    case ACTION_GET_LIST_OF_CURRENCIES:

      const temporaryState = { ...state };
      if (action?.invoiceId) {
        const k = temporaryState.invoices[action?.invoiceId];
        k.currentPage = 'selectCurrency';
        k.paymentMethod = "External"
        temporaryState.invoices = {
          ...state?.invoices,
          [action?.invoiceId]: k
        }
      }
      temporaryState.listofcurrencies = action?.payload?.data?.tokens
      return temporaryState;

    case TRANSACTION_POOLING:
      return {
        ...state,
        socketPayment: action?.payload?.data
      }

    case ACTION_GET_REAL_TIME_PRICE:
      const tState = { ...state };

      if (action?.state) {
        const j = tState.invoices[action?.state];
        j.realTimePrice = action?.payload?.data?.price
        j.selectedCurrency = action?.reqPayload
        tState.invoices = {
          ...state?.invoices,
          [action?.state]: j
        }
      }

      return tState

    default:
      return state;
  }
};
