import React, { useEffect, useState } from "react";
import { PrimaryBlackButton } from "../../components/Buttons";
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import KYCBLOCK from "../../components/Kyc";
import BankAccountInfo, {
  WalletInfo,
} from "../../components/Kyc/BankAccountInfo";
import { hasAccess } from "../../utilities";
import { CardTitleComponentWithRightArrow } from "../../components/Cards";
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";

export default function Vat() {
  const { accessLists } = useSelector((state) => state.auth);
  const { vat } = useSelector((state) => state?.apikey);
  const [isLoading, setIsLoading] = useState(false);
  const [radioInput, setRadioInput] = useState("no");
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();

  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "vat",
        action: "ACTION_GET_VAT",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "updateVat",
        payload: {
          vat: radioInput,
        },
      };
      await dispatch(PostHandler(req));
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <Grid container>
      <Grid item md={3} lg={4}>
        <Box
          sx={{
            pt: "32px",
            mb: "15px",
          }}
        >
          <Header5Layout text="Manage Vat" fs="16px" />
          <AuthPRightLayout
            text="Manage vat for every transaction and additional charge percentage here"
            fs="16px"
            lh="22px"
            mt="10px"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9} lg={8}>
        {isFetching ? (
          <Box
            sx={{
              p: {
                xs: "15px",
                md: "30px",
              },
              display: "grid",
              placeContent: "center",
              height: "50vh",
              borderRadius: "8px",
              border: "1px solid var(--neutral-200, #E3E3E8)",
              background: "var(--main-colors-white, #FFF)",
            }}
          >
            <CircularProgress />
          </Box>
        ) : vat?.kycdetails?.status !== "Completed" ? (

          <KYCBLOCK />
        ) : (
          <CardLayout
            component={<>
              <CardTitleComponentWithRightArrow mainTitle="Vat" noArrow={true} />

              <Box sx={{
                p: {
                  xs: "15px",
                  md: "30px",
                },
              }}>
                <AuthPRightLayout
                  text="Want the system to automatically add 5% VAT?"
                  fs="16px"
                  lh="22px"
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <RadioGroup
                    defaultValue={vat?.merchant?.vat ? "yes" : "no"}
                    name="radio-buttons-group"
                  >
                    <Box
                      defaultValue="yes"
                      name="radio-buttons-group"
                      sx={{
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        onChange={() => setRadioInput("yes")}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                              },
                            }}
                          />
                        }
                        label={<InputLabels label="Yes" mt="0px" />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            // color: "var(--Neutral-900, #131316)",
                            fontFeatureSettings: `'ss06' on`,
                            fontFamily: "SF Pro Display",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: " -0.4px",
                          },
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        onChange={() => setRadioInput("no")}
                        control={
                          <Radio
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 20,
                              },
                            }}
                          />
                        }
                        label={<InputLabels label="No" mt="0px" />}
                        sx={{
                          ".MuiFormControlLabel-label": {
                            // color: "var(--Neutral-900, #131316)",
                            fontFeatureSettings: `'ss06' on`,
                            fontFamily: "SF Pro Display",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            letterSpacing: " -0.4px",
                          },
                        }}
                      />
                    </Box>
                  </RadioGroup>
                </Box>

                {hasAccess(["Vat-update"], accessLists) && (
                  <Box sx={{ my: "10px" }}>
                    {isLoading ? (
                      <Button variant="contained" title="submit" disabled>
                        <CircularProgress size="1.7rem" />
                      </Button>
                    ) : (
                      <PrimaryBlackButton
                        title="Save"
                        text="Save"
                        onClick={handleSubmit}
                      //   disabled={isDisable}
                      />
                    )}
                  </Box>
                )}

                {/* bank detail */}
                {vat?.kycdetails?.bankname && (
                  <BankAccountInfo
                    bankName={vat?.kycdetails?.bankname}
                    name={vat?.kycdetails?.fullnamebank}
                    accountNo={vat?.kycdetails?.accountnumber}
                    ifsc={vat?.kycdetails?.ifsc}
                  />
                )}

                {vat?.kycdetails?.tron_address && (
                  <WalletInfo
                    eth={vat?.kycdetails?.ethereum_address}
                    tron={vat?.kycdetails?.tron_address}
                    bnb={vat?.kycdetails?.binance_address}
                  />
                )}

              </Box>
            </>}
          />
        )}
      </Grid>
    </Grid>
  );
}
