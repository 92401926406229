import { Box } from "@mui/material";

export const InputLabels = ({ label, mt, mb, fs, bg, fw, ta }) => (
  <Box
    sx={{
      mt: mt || "5px",
      fontSize: fs || "14px",
      fontStyle: "normal",
      fontWeight: fw || 500,
      lineHeight: "20.3px",
      mb: mb || "0px",
      background: bg,
      textAlign: ta || 'left'
    }}
  >
    {label}
  </Box>
);
