import * as React from "react";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import POSQRGafah from "./posQRGafahPage";
import { useDispatch, useSelector } from "react-redux";
import UserSocketService from "../../../utilities/UserSocketService";
import axios from "axios";

import {
  Failed,
  UrlReject,
} from "../../../components/Helpers/helperComponents";
import { useState } from "react";
import { ErrorButton, LinkButton, PrimaryBlackButton } from "../../../components/Buttons";
import { DeleteDialog } from "../../../components/Dialog";
import {
  ACTION_CLEAR_GAFAH_WALLET_PAYMENT,
  ACTION_PAYLOAD_SET,
  ACTION_SET_SOCKET_DATA,
} from "../../../Redux/Reducers/pos.reducer";
import {
  checkAndOpenApp,
  hasObjectPropertyExist,
  isAppInstalled,
} from "../../../utilities";
import { Button } from "@mui/material";
import api from "../../../config/api";
import { findUrl } from "../../../Redux/RequestHandlers";
import PaymentLayout, { MerchantAndLogoComponent, OrderBox } from "../Component/PosComponents";

export default function GafahWallet() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoices } = useSelector((state) => state?.pos);
  const [loadingQR, setLoadingQR] = React.useState(false);
  const [isRejected, setIsRejected] = useState(null);
  const [canceled, setCanceled] = useState(false);
  const [hasShiftedToPolling, setHasShiftedToPolling] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const handleGafahClick = async () => {
    setLoadingQR(true);
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;
      let payload = {
        id: param1,
        mode: "Gafah",
      };

      const result = await axios.post(
        findUrl(api, "initiateInvoicePayment"),
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt?.accessToken}`,
          },
        }
      );

      if (result?.data?.success) {
        const dx = result?.data?.data;
        if (dx?.id === invoices[param1]?.id)
          dispatch({
            type: ACTION_PAYLOAD_SET,
            reqState: param1,
            payload: {
              ...invoices[param1],
              url: dx?.url,
              amount: dx?.amount,
              currency: dx?.currency,
              vendorName: dx?.vendorName,
              paymentMethod: "Gafah",
              jwt_token: dx?.jwt_token,
            },
          });
      }

      setLoadingQR(false);
      setIsRejected(result?.data?.success ? false : true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;
      setCanceled(true);
      let payload = {
        id: invoices[param1]?.id,
      };
      const cancelres = await axios.post(
        // config?.API_URL + api?.cancelTransaction,
        findUrl(api, "cancelTransaction"),
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );
      //   const cancelres = await dispatch(PostHandlerForPaymentGateway(req));
      setCanceled(false);
      if (cancelres?.data?.success) {
        setIsRejected(true);
        setIsCanceled(true);
        dispatch({
          type: "ACTION_CLEAR_PAYMENT_DETAILS",
          payload: param1,
        });
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    dispatch({
      type: ACTION_CLEAR_GAFAH_WALLET_PAYMENT,
    });
    navigate(`/invoice?id=${param1}`, { replace: true });
  };

  const handleTransactionPolling = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;

      const res = await axios.get(
        `${findUrl(api, "transactionPolling")}?id=${invoices[param1]?.id}`,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );

      if (res?.data?.success) {
        const dx = res?.data?.data;
        dispatch({
          type: ACTION_SET_SOCKET_DATA,
          reqState: param1,
          payload: dx,
        });
      }

      if (!res?.data?.success) setIsRejected(true);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    // Connect to the socket when component mounts

    let clientId = localStorage.getItem("clientId");
    localStorage.setItem("clientId", param1);
    clientId = param1;
    UserSocketService.connect();
    UserSocketService.emitEvent("clientId", clientId);
    UserSocketService.emitEvent("client-message", "hello");
    UserSocketService.subscribeToEvent("serverMessage", (data) => {
      // console.log("user socket data", data);
      if (isJSON(data)) {
        let result = JSON.parse(data);
        if (result?.data) {
          dispatch({
            type: ACTION_SET_SOCKET_DATA,
            reqState: param1,
            payload: result?.data,
          });
        }
      }
    });

    UserSocketService.subscribeToEvent("kafka-message", (data) => {
      // const messagesContainer = document.getElementById("messages");
      // messagesContainer.innerHTML += `<p>${data}</p>`;

      let result = JSON.parse(data);
      if (result?.data) {
        dispatch({
          type: ACTION_SET_SOCKET_DATA,
          reqState: param1,
          payload: result?.data,
        });
      }

      // setReceivedMessage(result.data.status);
    });
    UserSocketService.subscribeToEvent("connect", () => {
      // setIsSocketConnected(true);
      console.log("user kafka connected")
    });

    UserSocketService.subscribeToEvent("disconnect", () => {
      setHasShiftedToPolling(true);
      console.log("user kafka disconnected")

    });
    return () => {
      // Disconnect from the socket when component unmounts
      UserSocketService.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!hasObjectPropertyExist(invoices, param1)) return;
    // Initialize intervalId variable to keep track of the interval
    let intervalId;

    // Function to set up the interval
    const setupInterval = () => {
      // Set up interval only when payment details are available, shifted to polling, and not rejected
      if (invoices[param1]?.url && hasShiftedToPolling && !isRejected) {
        intervalId = setInterval(handleTransactionPolling, 15000);
      }
    };

    // Function to clear the interval
    const clearIntervalIfRejected = () => {
      // If rejected becomes true and intervalId exists (meaning the interval is set), clear the interval
      if (isRejected && intervalId) {
        clearInterval(intervalId);
      }
    };

    // Set up interval initially
    setupInterval();

    // Clear the interval if rejected becomes true after the interval has already been set up
    clearIntervalIfRejected();

    // Clean up the interval when the component unmounts or when the conditions for setting up the interval change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, hasShiftedToPolling, isRejected]);

  React.useEffect(() => {
    // if invoice not exist return
    if (!hasObjectPropertyExist(invoices, param1))
      return navigate(`/invoice?id=${param1}`, { replace: true });

    // if invoice exits in redux status is completed or expired.
    if (["Completed", "Expired"].includes(invoices[param1]?.status))
      return navigate(
        invoices[param1]?.status === "Completed"
          ? `/invoice/success/${invoices[param1]?.id}/`
          : `/invoice/failed/${invoices[param1]?.id}`,
        { replace: true }
      );

    //if invoice is selected as external payment
    if (
      invoices[param1]?.paymentMethod === "External" &&
      invoices[param1]?.token
    )
      return navigate(`/invoice/external?id=${param1}`, { replace: true });

    // if app exist open in app
    if (invoices[param1]?.paymentMethod === "Gafah" && invoices[param1]?.url)
      checkAndOpenApp(
        "gafahapp://",
        `gafahapp://PaymentConfirmScreen/:${invoices[param1]?.vendorName},${invoices[param1]?.currency},${invoices[param1]?.amount},${invoices[param1]?.id},${invoices[param1]?.url}`
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  React.useEffect(() => {
    if (hasObjectPropertyExist(invoices, param1) && param1)
      if (!invoices[param1]?.paymentMethod) handleGafahClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  return (
    <>
      <PaymentLayout
        selectWalletComplete={true}
        active="paymentCheckout"
        bodyComponent={
          <Box sx={{ p: '20px' }}>
            {param1 && <>
              {/* merchant Name */}
              <MerchantAndLogoComponent merchantName={invoices[param1]?.merchantName} bb="0px" />


              {/* order box */}
              <OrderBox
                amount={invoices[param1]?.currency + " " + invoices[param1]?.amount}
                mainTitleText="Amount"
                subTitleText="Gafah Wallet"
              />
            </>}

            {!param1 ? (
              <UrlReject
                my="100px"
                text={`url should be ${location?.pathname}?id=xxxxx-xxx-xxxx...`}
              />
            ) : isRejected ? (
              <Failed
                title={isCanceled ? "Canceled" : ""}
                my="100px"
                text={
                  isCanceled
                    ? "Looks like the transaction has been canceled, please try to Restart the Payment"
                    : "Looks like the transaction has expired, please try to Restart the Payment"
                }
                backComponent={
                  <LinkButton to={`/invoice?id=${param1}`} title="Go Back" />
                }
              />
            ) : (
              <>
                <POSQRGafah
                  loading={loadingQR}
                  currency={invoices[param1]?.currency}
                  amount={invoices[param1]?.amount}
                  paymentUrl={invoices[param1]?.url}
                  status={invoices[param1]?.status}
                />
                {!loadingQR && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      // pt: 2,
                      width: "100%",
                      gap: "15px",
                    }}
                  >
                    <DeleteDialog
                      customComponent={<PrimaryBlackButton text="Go back" />}
                      title="Are your sure to go back?"
                      content="Please make sure you have not initiated any payments."
                      execute={handleGoBack}
                      executeLoading={canceled}
                    />

                    <DeleteDialog
                      // btnTitle="Cancel"
                      customComponent={<ErrorButton />}
                      title="Are your sure to cancel?"
                      content="Are you sure to cancel this transaction"
                      execute={handleCancelClick}
                      executeLoading={canceled}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    mt: {
                      md: '20px',
                      xs: '60px'
                    },
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (
                        isAppInstalled("gafahapp://") &&
                        hasObjectPropertyExist(invoices, param1) &&
                        invoices[param1]?.vendorName &&
                        invoices[param1]?.currency &&
                        invoices[param1]?.amount &&
                        invoices[param1]?.id &&
                        invoices[param1]?.url
                      )
                        window.location.href = `gafahapp://PaymentConfirmScreen/:${invoices[param1]?.vendorName},${invoices[param1]?.currency},${invoices[param1]?.amount},${invoices[param1]?.id},${invoices[param1]?.url}`;
                      else
                        window.location.href =
                          "https://apps.apple.com/ae/app/gafah/id6478121453";
                    }}
                    sx={{
                      borderRadius: "10px",
                      textTransform: "none",
                      mt: "15px",
                    }}
                  >
                    Open in Gafah App
                  </Button>
                </Box>
              </>
            )}

          </Box>}
      />
    </>

  );
}
