export const GettingStartedSvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_17512)">
        <circle cx="40" cy="40" r="40" fill="#0E0637" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_17512)">
                <path
                    d="M27.5594 40.4722L21.8432 -188.809C21.6359 -197.127 32.6856 -200.195 36.7963 -192.961L236.558 158.587C240.669 165.821 232.377 173.743 225.338 169.308L31.2922 47.0413C29.0272 45.6141 27.6261 43.1485 27.5594 40.4722Z"
                    fill="url(#paint0_linear_1472_17512)"
                    fillOpacity="0.05"
                />
                <path
                    d="M27.5594 40.4722L21.8432 -188.809C21.6359 -197.127 32.6856 -200.195 36.7963 -192.961L236.558 158.587C240.669 165.821 232.377 173.743 225.338 169.308L31.2922 47.0413C29.0272 45.6141 27.6261 43.1485 27.5594 40.4722Z"
                    stroke="white"
                    strokeOpacity="0.5"
                />
            </g>
            <g filter="url(#filter1_b_1472_17512)">
                <path
                    d="M56.6287 23.9539L50.9126 -205.328C50.7052 -213.645 61.755 -216.713 65.8656 -209.479L265.627 142.069C269.738 149.303 261.446 157.225 254.407 152.789L60.3615 30.5229C58.0965 29.0958 56.6955 26.6302 56.6287 23.9539Z"
                    fill="url(#paint1_linear_1472_17512)"
                    fillOpacity="0.2"
                />
                <path
                    d="M56.6287 23.9539L50.9126 -205.328C50.7052 -213.645 61.755 -216.713 65.8656 -209.479L265.627 142.069C269.738 149.303 261.446 157.225 254.407 152.789L60.3615 30.5229C58.0965 29.0958 56.6955 26.6302 56.6287 23.9539Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            d="M53 43C53 50.1812 47.1812 56 40 56C32.8187 56 27 50.1812 27 43C27 36.5 31.7688 31.1125 38 30.1562V28H36.25C35.8375 28 35.5 27.6625 35.5 27.25V24.75C35.5 24.3375 35.8375 24 36.25 24H43.75C44.1625 24 44.5 24.3375 44.5 24.75V27.25C44.5 27.6625 44.1625 28 43.75 28H42V30.1562C44.3438 30.5188 46.4813 31.5063 48.2313 32.9438L49.95 31.225C50.2438 30.9313 50.7188 30.9313 51.0125 31.225L52.7812 32.9937C53.075 33.2875 53.075 33.7625 52.7812 34.0562L50.9438 35.8938L50.9062 35.9312C52.2313 37.9562 53 40.3875 53 43ZM42 45.25V35.7812C42 35.3687 41.6625 35.0312 41.25 35.0312H38.75C38.3375 35.0312 38 35.3687 38 35.7812V45.25C38 45.6625 38.3375 46 38.75 46H41.25C41.6625 46 42 45.6625 42 45.25Z"
            fill="white"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_17512"
            x="-28.6597"
            y="-247.525"
            width="316.815"
            height="468.619"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17512"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17512"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_17512"
            x="-19.5903"
            y="-284.043"
            width="356.815"
            height="508.619"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17512"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17512"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_17512"
            x1="136.677"
            y1="-17.1871"
            x2="27.6662"
            y2="44.7566"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
            id="paint1_linear_1472_17512"
            x1="165.746"
            y1="-33.7054"
            x2="56.7356"
            y2="28.2383"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_17512">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>

export const ManagingMyAccountSvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_10257)">
        <circle cx="40" cy="40" r="40" fill="#5235E8" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_10257)">
                <path
                    d="M107.484 2.14159L309.654 110.447C316.988 114.376 314.197 125.499 305.876 125.499L-98.4635 125.499C-106.784 125.499 -109.575 114.376 -102.241 110.447L99.9287 2.1416C102.289 0.877382 105.124 0.877375 107.484 2.14159Z"
                    fill="url(#paint0_linear_1472_10257)"
                    fillOpacity="0.05"
                />
                <path
                    d="M107.484 2.14159L309.654 110.447C316.988 114.376 314.197 125.499 305.876 125.499L-98.4635 125.499C-106.784 125.499 -109.575 114.376 -102.241 110.447L99.9287 2.1416C102.289 0.877382 105.124 0.877375 107.484 2.14159Z"
                    stroke="white"
                    strokeOpacity="0.5"
                />
            </g>
            <g filter="url(#filter1_b_1472_10257)">
                <path
                    d="M107.484 35.5764L309.654 143.882C316.988 147.811 314.197 158.934 305.876 158.934L-98.4635 158.934C-106.784 158.934 -109.575 147.811 -102.241 143.882L99.9287 35.5764C102.289 34.3122 105.124 34.3122 107.484 35.5764Z"
                    fill="url(#paint1_linear_1472_10257)"
                    fillOpacity="0.2"
                />
                <path
                    d="M107.484 35.5764L309.654 143.882C316.988 147.811 314.197 158.934 305.876 158.934L-98.4635 158.934C-106.784 158.934 -109.575 147.811 -102.241 143.882L99.9287 35.5764C102.289 34.3122 105.124 34.3122 107.484 35.5764Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.9998 32C33.9998 30.4087 34.6319 28.8826 35.7572 27.7574C36.8824 26.6321 38.4085 26 39.9998 26C41.5911 26 43.1172 26.6321 44.2424 27.7574C45.3677 28.8826 45.9998 30.4087 45.9998 32C45.9998 33.5913 45.3677 35.1174 44.2424 36.2426C43.1172 37.3679 41.5911 38 39.9998 38C38.4085 38 36.8824 37.3679 35.7572 36.2426C34.6319 35.1174 33.9998 33.5913 33.9998 32ZM29.0011 50.8067C29.0461 47.9193 30.2247 45.1654 32.2824 43.1394C34.3402 41.1135 37.1121 39.9779 39.9998 39.9779C42.8875 39.9779 45.6594 41.1135 47.7172 43.1394C49.7749 45.1654 50.9535 47.9193 50.9985 50.8067C51.0019 51.001 50.9487 51.1922 50.8452 51.3568C50.7417 51.5214 50.5925 51.6522 50.4158 51.7333C47.148 53.2316 43.5947 54.0049 39.9998 54C36.2851 54 32.7558 53.1893 29.5838 51.7333C29.4071 51.6522 29.2579 51.5214 29.1544 51.3568C29.0509 51.1922 28.9977 51.001 29.0011 50.8067Z"
            fill="white"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_10257"
            x="-156.977"
            y="-49.3066"
            width="521.367"
            height="225.305"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_10257"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_10257"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_10257"
            x="-176.977"
            y="-35.8718"
            width="561.367"
            height="265.305"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_10257"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_10257"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_10257"
            x1="103.706"
            y1="125.499"
            x2="103.706"
            y2="0.117785"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
            id="paint1_linear_1472_10257"
            x1="103.706"
            y1="158.934"
            x2="103.706"
            y2="33.5526"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_10257">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>

export const TransactionsSvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_20893)">
        <circle cx="40" cy="40" r="40" fill="#CDEC09" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_20893)">
                <path
                    d="M41.327 34.8L264.64 87.0895C272.741 88.9864 272.923 100.453 264.886 102.606L-125.676 207.257C-133.713 209.411 -139.288 199.389 -133.221 193.696L34.029 36.7555C35.9812 34.9236 38.7204 34.1896 41.327 34.8Z"
                    fill="url(#paint0_linear_1472_20893)"
                    fillOpacity="0.7"
                />
                <path
                    d="M41.327 34.8L264.64 87.0895C272.741 88.9864 272.923 100.453 264.886 102.606L-125.676 207.257C-133.713 209.411 -139.288 199.389 -133.221 193.696L34.029 36.7555C35.9812 34.9236 38.7204 34.1896 41.327 34.8Z"
                    stroke="white"
                    strokeOpacity="0.7"
                />
            </g>
            <g filter="url(#filter1_b_1472_20893)">
                <path
                    d="M49.9804 62.0959L273.293 114.385C281.394 116.282 281.576 127.749 273.54 129.902L-117.023 234.553C-125.06 236.706 -130.635 226.685 -124.567 220.992L42.6823 64.0514C44.6345 62.2195 47.3737 61.4855 49.9804 62.0959Z"
                    fill="white"
                />
                <path
                    d="M49.9804 62.0959L273.293 114.385C281.394 116.282 281.576 127.749 273.54 129.902L-117.023 234.553C-125.06 236.706 -130.635 226.685 -124.567 220.992L42.6823 64.0514C44.6345 62.2195 47.3737 61.4855 49.9804 62.0959Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            d="M34.5 28C33.675 28 33 28.675 33 29.5V31H31.5C30.675 31 30 31.675 30 32.5V47.5C30 48.325 30.675 49 31.5 49H33V50.5C33 51.325 33.675 52 34.5 52C35.325 52 36 51.325 36 50.5V49H37.5C38.325 49 39 48.325 39 47.5V32.5C39 31.675 38.325 31 37.5 31H36V29.5C36 28.675 35.325 28 34.5 28ZM49.5 34H48V29.5C48 28.675 47.325 28 46.5 28C45.675 28 45 28.675 45 29.5V34H43.5C42.675 34 42 34.675 42 35.5V43C42 43.825 42.675 44.5 43.5 44.5H45V50.5C45 51.325 45.675 52 46.5 52C47.325 52 48 51.325 48 50.5V44.5H49.5C50.325 44.5 51 43.825 51 43V35.5C51 34.675 50.325 34 49.5 34Z"
            fill="#0E0637"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_20893"
            x="-186.289"
            y="-15.9109"
            width="507.604"
            height="273.968"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_20893"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_20893"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_20893"
            x="-197.636"
            y="-8.61499"
            width="547.604"
            height="313.968"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_20893"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_20893"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_20893"
            x1="69.6052"
            y1="154.932"
            x2="37.1542"
            y2="33.8229"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_20893">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>

export const FundingSvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_8689)">
        <circle cx="40" cy="40" r="40" fill="#5235E8" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_8689)">
                <path
                    d="M41.327 34.8L264.64 87.0895C272.741 88.9864 272.923 100.453 264.886 102.606L-125.676 207.257C-133.713 209.411 -139.288 199.389 -133.221 193.696L34.029 36.7555C35.9812 34.9236 38.7204 34.1896 41.327 34.8Z"
                    fill="url(#paint0_linear_1472_8689)"
                    fillOpacity="0.05"
                />
                <path
                    d="M41.327 34.8L264.64 87.0895C272.741 88.9864 272.923 100.453 264.886 102.606L-125.676 207.257C-133.713 209.411 -139.288 199.389 -133.221 193.696L34.029 36.7555C35.9812 34.9236 38.7204 34.1896 41.327 34.8Z"
                    stroke="white"
                    strokeOpacity="0.5"
                />
            </g>
            <g filter="url(#filter1_b_1472_8689)">
                <path
                    d="M49.9804 62.0959L273.293 114.385C281.394 116.282 281.576 127.749 273.54 129.902L-117.023 234.553C-125.06 236.706 -130.635 226.685 -124.567 220.992L42.6823 64.0514C44.6345 62.2195 47.3737 61.4855 49.9804 62.0959Z"
                    fill="url(#paint1_linear_1472_8689)"
                    fillOpacity="0.2"
                />
                <path
                    d="M49.9804 62.0959L273.293 114.385C281.394 116.282 281.576 127.749 273.54 129.902L-117.023 234.553C-125.06 236.706 -130.635 226.685 -124.567 220.992L42.6823 64.0514C44.6345 62.2195 47.3737 61.4855 49.9804 62.0959Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            d="M52 32.5C52 36.0893 46.6267 39 40 39C33.3733 39 28 36.0893 28 32.5C28 28.9107 33.3733 26 40 26C46.6267 26 52 28.9107 52 32.5Z"
            fill="white"
        />
        <path
            d="M40 40.9999C43.58 40.9999 46.92 40.2186 49.4373 38.8546C50.3638 38.3627 51.2155 37.7414 51.9667 37.0093C51.988 37.1706 52 37.3346 52 37.4999C52 41.0893 46.6267 43.9999 40 43.9999C33.3733 43.9999 28 41.0893 28 37.4999C28 37.3346 28.012 37.1706 28.0333 37.0093C28.7846 37.7414 29.6362 38.3627 30.5627 38.8546C33.0787 40.2186 36.42 40.9999 40 40.9999Z"
            fill="white"
        />
        <path
            d="M40 45.9999C43.58 45.9999 46.92 45.2186 49.4373 43.8546C50.3638 43.3628 51.2155 42.7414 51.9667 42.0093C51.988 42.1706 52 42.3346 52 42.4999C52 46.0893 46.6267 48.9999 40 48.9999C33.3733 48.9999 28 46.0893 28 42.4999C28 42.3346 28.012 42.1706 28.0333 42.0093C28.7845 42.7414 29.6362 43.3627 30.5627 43.8546C33.0787 45.2186 36.42 45.9999 40 45.9999Z"
            fill="white"
        />
        <path
            d="M40 50.9999C43.58 50.9999 46.92 50.2186 49.4373 48.8546C50.3638 48.3628 51.2155 47.7414 51.9667 47.0093C51.988 47.1706 52 47.3346 52 47.4999C52 51.0893 46.6267 53.9999 40 53.9999C33.3733 53.9999 28 51.0893 28 47.4999C28 47.3346 28.012 47.1706 28.0333 47.0093C28.7845 47.7414 29.6362 48.3627 30.5627 48.8546C33.0787 50.2186 36.42 50.9999 40 50.9999Z"
            fill="white"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_8689"
            x="-186.289"
            y="-15.9109"
            width="507.605"
            height="273.968"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_8689"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_8689"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_8689"
            x="-197.636"
            y="-8.61499"
            width="547.605"
            height="313.968"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_8689"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_8689"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_8689"
            x1="69.6052"
            y1="154.932"
            x2="37.1542"
            y2="33.8229"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
            id="paint1_linear_1472_8689"
            x1="78.2585"
            y1="182.228"
            x2="45.8075"
            y2="61.1188"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_8689">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>

export const PrivacyAndSecuritySvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_17534)">
        <circle cx="40" cy="40" r="40" fill="#CDEC09" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_17534)">
                <path
                    d="M124.476 -4.77071L-101.129 135.227L132.915 -270.15L124.476 -4.77071Z"
                    fill="url(#paint0_linear_1472_17534)"
                    fillOpacity="0.7"
                />
                <path
                    d="M124.476 -4.77071L-101.129 135.227L132.915 -270.15L124.476 -4.77071Z"
                    stroke="white"
                    strokeOpacity="0.5"
                />
            </g>
            <g filter="url(#filter1_b_1472_17534)">
                <path
                    d="M95.5204 -21.4882L-130.085 118.51L103.96 -286.867L95.5204 -21.4882Z"
                    fill="white"
                />
                <path
                    d="M95.5204 -21.4882L-130.085 118.51L103.96 -286.867L95.5204 -21.4882Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.688 26.8934C40.5022 26.7173 40.256 26.6191 40 26.6191C39.744 26.6191 39.4978 26.7173 39.312 26.8934C36.4817 29.5813 32.7122 31.0552 28.8093 31.0001C28.5967 30.9973 28.3888 31.0624 28.2156 31.1859C28.0425 31.3094 27.9133 31.4848 27.8467 31.6868C27.284 33.4016 26.9982 35.1953 27 37.0001C27 44.9228 32.4187 51.5774 39.7507 53.4641C39.9142 53.5063 40.0858 53.5063 40.2493 53.4641C47.5813 51.5774 53 44.9228 53 37.0001C53 35.1468 52.7027 33.3601 52.1533 31.6868C52.087 31.4846 51.9578 31.3088 51.7847 31.1851C51.6116 31.0614 51.4035 30.9961 51.1907 30.9988L51 31.0001C47.0053 31.0001 43.3773 29.4401 40.688 26.8934ZM44.8133 37.5814C44.8933 37.4748 44.9512 37.3533 44.9836 37.224C45.016 37.0947 45.0222 36.9603 45.0018 36.8286C44.9815 36.6969 44.935 36.5705 44.8651 36.457C44.7953 36.3435 44.7034 36.2451 44.595 36.1676C44.4866 36.0901 44.3638 36.0351 44.2338 36.0057C44.1037 35.9764 43.9692 35.9733 43.838 35.9967C43.7068 36.0201 43.5816 36.0695 43.4697 36.142C43.3579 36.2145 43.2616 36.3086 43.1867 36.4188L38.872 42.4588L36.7067 40.2934C36.5171 40.1168 36.2664 40.0206 36.0073 40.0252C35.7482 40.0298 35.5011 40.1347 35.3178 40.3179C35.1346 40.5012 35.0297 40.7483 35.0251 41.0074C35.0205 41.2665 35.1167 41.5172 35.2933 41.7068L38.2933 44.7068C38.396 44.8093 38.5197 44.8883 38.656 44.9383C38.7922 44.9882 38.9377 45.0079 39.0824 44.996C39.227 44.9841 39.3673 44.9408 39.4935 44.8692C39.6197 44.7976 39.7289 44.6994 39.8133 44.5814L44.8133 37.5814Z"
            fill="#0E0637"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_17534"
            x="-151.562"
            y="-320.4"
            width="334.978"
            height="506.052"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17534"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17534"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_17534"
            x="-200.518"
            y="-357.117"
            width="374.978"
            height="546.052"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17534"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17534"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_17534"
            x1="15.8928"
            y1="-67.4612"
            x2="124.476"
            y2="-4.77071"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_17534">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>

export const OtherTopicsSvg = () => <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <g clipPath="url(#clip0_1472_17534)">
        <circle cx="40" cy="40" r="40" fill="#CDEC09" />
        <g opacity="0.4">
            <g filter="url(#filter0_b_1472_17534)">
                <path
                    d="M124.476 -4.77071L-101.129 135.227L132.915 -270.15L124.476 -4.77071Z"
                    fill="url(#paint0_linear_1472_17534)"
                    fillOpacity="0.7"
                />
                <path
                    d="M124.476 -4.77071L-101.129 135.227L132.915 -270.15L124.476 -4.77071Z"
                    stroke="white"
                    strokeOpacity="0.5"
                />
            </g>
            <g filter="url(#filter1_b_1472_17534)">
                <path
                    d="M95.5204 -21.4882L-130.085 118.51L103.96 -286.867L95.5204 -21.4882Z"
                    fill="white"
                />
                <path
                    d="M95.5204 -21.4882L-130.085 118.51L103.96 -286.867L95.5204 -21.4882Z"
                    stroke="white"
                />
            </g>
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.688 26.8934C40.5022 26.7173 40.256 26.6191 40 26.6191C39.744 26.6191 39.4978 26.7173 39.312 26.8934C36.4817 29.5813 32.7122 31.0552 28.8093 31.0001C28.5967 30.9973 28.3888 31.0624 28.2156 31.1859C28.0425 31.3094 27.9133 31.4848 27.8467 31.6868C27.284 33.4016 26.9982 35.1953 27 37.0001C27 44.9228 32.4187 51.5774 39.7507 53.4641C39.9142 53.5063 40.0858 53.5063 40.2493 53.4641C47.5813 51.5774 53 44.9228 53 37.0001C53 35.1468 52.7027 33.3601 52.1533 31.6868C52.087 31.4846 51.9578 31.3088 51.7847 31.1851C51.6116 31.0614 51.4035 30.9961 51.1907 30.9988L51 31.0001C47.0053 31.0001 43.3773 29.4401 40.688 26.8934ZM44.8133 37.5814C44.8933 37.4748 44.9512 37.3533 44.9836 37.224C45.016 37.0947 45.0222 36.9603 45.0018 36.8286C44.9815 36.6969 44.935 36.5705 44.8651 36.457C44.7953 36.3435 44.7034 36.2451 44.595 36.1676C44.4866 36.0901 44.3638 36.0351 44.2338 36.0057C44.1037 35.9764 43.9692 35.9733 43.838 35.9967C43.7068 36.0201 43.5816 36.0695 43.4697 36.142C43.3579 36.2145 43.2616 36.3086 43.1867 36.4188L38.872 42.4588L36.7067 40.2934C36.5171 40.1168 36.2664 40.0206 36.0073 40.0252C35.7482 40.0298 35.5011 40.1347 35.3178 40.3179C35.1346 40.5012 35.0297 40.7483 35.0251 41.0074C35.0205 41.2665 35.1167 41.5172 35.2933 41.7068L38.2933 44.7068C38.396 44.8093 38.5197 44.8883 38.656 44.9383C38.7922 44.9882 38.9377 45.0079 39.0824 44.996C39.227 44.9841 39.3673 44.9408 39.4935 44.8692C39.6197 44.7976 39.7289 44.6994 39.8133 44.5814L44.8133 37.5814Z"
            fill="#0E0637"
        />
    </g>
    <defs>
        <filter
            id="filter0_b_1472_17534"
            x="-151.562"
            y="-320.4"
            width="334.978"
            height="506.052"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="25" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17534"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17534"
                result="shape"
            />
        </filter>
        <filter
            id="filter1_b_1472_17534"
            x="-200.518"
            y="-357.117"
            width="374.978"
            height="546.052"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="35" />
            <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_1472_17534"
            />
            <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_1472_17534"
                result="shape"
            />
        </filter>
        <linearGradient
            id="paint0_linear_1472_17534"
            x1="15.8928"
            y1="-67.4612"
            x2="124.476"
            y2="-4.77071"
            gradientUnits="userSpaceOnUse"
        >
            <stop stopColor="white" stopOpacity="0.2" />
            <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_1472_17534">
            <rect width="80" height="80" rx="40" fill="white" />
        </clipPath>
    </defs>
</svg>