import React from "react";
import { useLocation } from "react-router-dom";
import { DesktopMenuLists, DeskTopSettingList } from "./MenuLists";
import { Box } from "@mui/material";
import { SideNavLayout } from "../../../Layouts/AllLayOuts";
import { Logo } from "../../Logo";
// import { LogoComponent } from "../Logo";

const SideNav = () => {
  const { pathname } = useLocation();

  return (<SideNavLayout
    component={<>
      <Box sx={{ mb: '15px', ml: '5px' }}>
        {/* <LogoComponent /> */}
        <Logo />
      </Box>

      {/* {/referral/.test(pathname) ? (
            <ReferralList />
          ) :  */}
      {/settings/.test(pathname) ? (
        <DeskTopSettingList />
      ) : (
        <DesktopMenuLists />
      )}
    </>}
  />);
};

export default SideNav;
