import React from 'react'
import { LogoSvg } from '../Helpers/svg';
import lightLogo from '../../assets/img/logo/light-logo.png'
import lightLogo2 from '../../assets/img/logo/logo-light2.png'
import darkLogo from '../../assets/img/logo/logo-dark.png'
import { Box } from '@mui/material';
const selectedTheme = localStorage.getItem('theme') || 'light';

export const logoType = {
    svg: <LogoSvg height={32} width={32} />,
    png: (source) => <Box
        component="img"
        sx={{
            height: "30px",
        }}
        alt="The house from the offer."
        style={{ cursor: "pointer" }}
        // onClick={handleMainLogoClick}
        src={source}
    />
}

export const Logo = ({ mobileView }) => selectedTheme === 'light' ?
    mobileView ?
        logoType?.png(darkLogo) :
        logoType?.svg :
    logoType.png(mobileView ? lightLogo2 : lightLogo)
