import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
import { CloseIcon } from "../Helpers/svg";
import themes from "../Theme";
import { CardTitleComponentWithFilterComponent } from "../Cards";
const selectedTheme = localStorage.getItem('theme') || 'light';

export const ModalNav = ({ title, closeModal }) =>
  <Box sx={{
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    overflow: 'hidden'
  }}>
    <CardTitleComponentWithFilterComponent
      mainTitle={title}
      component={<Box
        sx={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          background: "#000",
          cursor: "pointer",
          display: "grid",
          placeContent: "center",
        }}
        onClick={closeModal}
      >
        <CloseIcon fill="#fff" />
      </Box>}
    />
  </Box>


export default function LeftToRightModal(props) {
  const { closeTrigger, openTrigger, closeModal } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (closeModal) closeModal();
    setOpen(false);
  }

  React.useEffect(() => {
    if (closeTrigger) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeTrigger])

  React.useEffect(() => {
    if (openTrigger) handleOpen()
  }, [openTrigger])



  return (
    <>
      {[true, false].includes(openTrigger) ? <></> : props?.openButton ? (
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleOpen}
        >
          {props?.openButton}
        </Box>
      ) : (
        <Button onClick={handleOpen}>{props?.btnTitle || "Open modal"}</Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        keepMounted
      >
        <Slide
          direction="left"
          in={open}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              right: "0px",
              width: {
                sm: props?.width || "70%",
                xs: "100%",
              },
              height: {
                xs: '100%',
                sm: "calc(100% - 30px)",
              },
              background: themes[selectedTheme]?.colors?.card,
              boxShadow: 24,
              color: themes[selectedTheme]?.text?.primary,
              m: {
                xs: '0px',
                sm: '15px',
              },
              borderRadius: '7px'

            }}
          >
            <ModalNav title={props?.modalTitle} closeModal={handleClose} />
            {props?.modalBody ? <Box sx={{ height: '100%', overflowY: 'scroll' }}>{props?.modalBody}</Box>

              : (
                <>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    Text in a modal
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Duis mollis, est non commodo luctus, nisi erat porttitor
                    ligula.
                  </Typography>
                </>
              )}
          </Box>
        </Slide>
      </Modal>
    </>
  );
}
