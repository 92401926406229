import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import TablePaginations from "./TablePagination";
import { CloseIcon } from "../Helpers/svg";
import CustomizedSwitches from "../Helpers/SwitchComponent";
import NotFound from "../Layouts/NotFound";
import SoundWaveLoader from "../Loader/SoundWaveLoader";
import themes from "../Theme";

const selectedTheme = localStorage.getItem('theme') || 'light';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: themes[selectedTheme]?.text?.primary,
    background: themes[selectedTheme]?.color?.label,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: themes[selectedTheme]?.text?.primary,

  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "td, th": {
    border: selectedTheme === 'dark' && 0,
    background: themes[selectedTheme]?.color?.label,

  },

}));

export const NoRecordInTable = ({ colSpan }) => (
  <TableRow>
    <StyledTableCell colSpan={colSpan || 15} >
      <NotFound />
    </StyledTableCell>
  </TableRow>
);

export const TableDataLoad = ({ colSpan }) => (
  <TableRow>
    <StyledTableCell colSpan={colSpan || 10}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: "100px",
        }}
      >
        <SoundWaveLoader />
      </Box>
    </StyledTableCell>
  </TableRow>
);

export default function BasicTableWithBody(props) {
  const { minWid, maxWid, tableHead, tableBody, pages, page, setPage } = props;

  return (
    <>
      <TableContainer >
        <Table
          sx={{ minWidth: minWid || 700, maxWidth: maxWid }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <StyledTableRow style={{
              background: themes[selectedTheme]?.color?.label,
              borderBottom: themes[selectedTheme]?.color?.border
            }}>
              {tableHead?.length > 0 &&
                tableHead?.map((item) => (
                  <td key={item} align="left" style={{ fontWeight: 900, padding: '15px', borderTop: '' }}>
                    {item}
                  </td>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
      {page > 0 && (
        <TablePaginations pages={pages} page={page} setPage={setPage} />
      )}
    </>
  );
}

export const BasicTable = (props) => {
  const { tableHead, tableData, tableCell, pages, page, setPage } = props;
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <StyledTableRow>
              {tableHead?.length > 0 &&
                tableHead?.map((item) => (
                  <td key={item} align="left">
                    {item}
                  </td>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row, index) => (
              <StyledTableRow
                key={row?.id + index}
                sx={{
                  height: "70px",
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {tableCell?.map((item, index) => (
                  <StyledTableCell key={item + index}>
                    {row[item]}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {page > 0 && (
        <TablePaginations pages={pages} page={page} setPage={setPage} />
      )}
    </>
  );
};

export const BasicDataTable = (props) => {
  const {
    tableHead,
    tableBody,
    pages,
    page,
    setPage,
    setColumns,
    callback,
    sortShowParams,
    totalSelectedItem,
    handlePrimeSelectClicked,
    primeCheck,
  } = props;
  const [open, setOpen] = useState([]);
  const [menubarShow, setMenubarShow] = useState("menuitem");
  const handleClick = (index) => {
    let arr = open;
    arr.map((item, ind) => {
      if (index !== ind) arr[ind] = false;
      return arr;
    });
    arr[index] = !open[index];
    setOpen((prev) => [...arr]);
  };
  const handleClickAway = () => {
    // setOpen(false);
  };
  const handleShow = (item) => {
    let k = tableHead[item];
    k.show = !tableHead[item]?.show;
    let b = {
      ...tableHead,
      item: { ...tableHead[item], ...k },
    };
    delete b.item;
    setColumns(b);
  };
  useEffect(() => {
    let y = [];
    Object?.keys(tableHead)?.length > 0 &&
      Object.keys(tableHead)?.map((item) => y.push(false));
    setOpen(y);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableHead]);
  return (
    <>
      <TableContainer sx={{ background: themes[selectedTheme]?.colors?.card }}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <StyledTableRow
              style={{
                background: themes[selectedTheme]?.color?.label,
              }}>
              {Object.keys(tableHead).length > 0 &&
                Object.keys(tableHead)?.map((item, index) => (
                  <td
                    key={item}
                    align="left"
                    style={{
                      display: tableHead[item]?.show ? "" : "none",
                      zIndex: '1 !important',
                      position: 'relative',
                      padding: '5px 15px',
                      background: themes[selectedTheme]?.color?.label + ' !important'
                    }}
                  >
                    {item === "checkbox" ? (
                      <Checkbox
                        checked={primeCheck?.checked}
                        indeterminate={primeCheck?.indeterminate}
                        onClick={handlePrimeSelectClicked}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "max-content",
                            fontSize: "11px",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "12.8px",
                            letterSpacing: "0.352px",
                            textTransform: "uppercase",
                          }}
                        >
                          {item}
                        </Typography>
                        {sortShowParams?.sortBy === tableHead[item]?.col && (
                          <>
                            {sortShowParams?.sOrder === "ASC" ? (
                              <ArrowUpwardIcon
                                sx={{ fontSize: "17px", color: "#495ECA" }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{ fontSize: "17px", color: "#495ECA" }}
                              />
                            )}
                          </>
                        )}

                        <ClickAwayListener onClickAway={handleClickAway}>
                          <Box>
                            <IconButton
                              onClick={() => handleClick(index)}
                              sx={{
                                fontSize: "10px",
                                width: "30px",
                                height: "30px",
                                color: themes[selectedTheme]?.text?.primary
                              }}
                            >
                              {open[index] ? (
                                <CloseIcon fill={themes[selectedTheme]?.text?.primary} />
                              ) : (
                                <MoreVertIcon sx={{ fontSize: "17px" }} />
                              )}
                            </IconButton>

                            {open[index] ? (
                              <Paper
                                sx={{
                                  display: open[index] ? "block" : "none",
                                  position: "absolute",
                                  zIndex: "99999!important",
                                  right: "0px",
                                  left: "5px",
                                  width: "200px",
                                  background: themes[selectedTheme]?.colors?.card,
                                  border: themes[selectedTheme]?.colors?.border
                                }}
                              >
                                {menubarShow === "menuitem" && (
                                  <>
                                    <MenuItem
                                      key={tableHead[item]?.col + "x"}
                                      onClick={() =>
                                        callback((prev) => ({
                                          ...prev,
                                          pageNumber: 1,
                                          sortBy: tableHead[item]?.col,
                                          sOrder: "ASC",
                                        }))
                                      }
                                      sx={{
                                        color: themes[selectedTheme]?.text?.primary,
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "13px",
                                        p: "10px 15px",
                                        width: "100%",
                                      }}
                                    >
                                      <ArrowUpwardIcon
                                        sx={{ fontSize: "14px" }}
                                      />
                                      Sort ASC
                                    </MenuItem>
                                    <MenuItem
                                      key={tableHead[item]?.col + "y"}
                                      onClick={() =>
                                        callback((prev) => ({
                                          ...prev,
                                          pageNumber: 1,
                                          sortBy: tableHead[item]?.col,
                                          sOrder: "DESC",
                                        }))
                                      }
                                      sx={{
                                        color: themes[selectedTheme]?.text?.primary,
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "13px",
                                        p: "10px 15px",
                                        width: "100%",
                                        borderBottom: themes[selectedTheme]?.colors?.border,
                                      }}
                                    >
                                      <ArrowDownwardIcon
                                        sx={{ fontSize: "14px" }}
                                      />
                                      Sort DSC
                                    </MenuItem>
                                    <MenuItem
                                      key={"hideColumn" + index + item + "z"}
                                      onClick={() => handleShow(item)}
                                      sx={{
                                        color: themes[selectedTheme]?.text?.primary,
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "13px",
                                        p: "10px 15px",
                                        width: "100%",
                                      }}
                                    >
                                      <VisibilityOff
                                        sx={{ fontSize: "14px" }}
                                      />
                                      Hide Column
                                    </MenuItem>

                                    <MenuItem
                                      key={"hideColumn" + index + item + "mk"}
                                      onClick={() =>
                                        setMenubarShow("managecol")
                                      }
                                      sx={{
                                        color: themes[selectedTheme]?.text?.primary,
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "13px",
                                        p: "10px 15px",
                                        width: "100%",
                                      }}
                                    >
                                      <AssessmentIcon
                                        sx={{ fontSize: "14px" }}
                                      />
                                      Manage Column
                                    </MenuItem>
                                  </>
                                )}

                                {menubarShow === "managecol" && (
                                  <>
                                    {Object.keys(tableHead).length > 0 &&
                                      Object.keys(tableHead)?.map(
                                        (item, index) => (
                                          <MenuItem
                                            key="hideColumn"
                                            sx={{
                                              display: "flex",
                                              gap: "10px",
                                              fontSize: "13px",
                                              p: "10px 15px",
                                            }}
                                          >
                                            <CustomizedSwitches
                                              checked={tableHead[item]?.show}
                                              onClick={() => handleShow(item)}
                                            />
                                            {item}
                                          </MenuItem>
                                        )
                                      )}
                                    <MenuItem
                                      key="hideColumn"
                                      onClick={() => setMenubarShow("menuitem")}
                                      sx={{
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "13px",
                                        p: "10px 15px",
                                        width: "100%",
                                      }}
                                    >
                                      <ArrowBackIcon
                                        sx={{ fontSize: "14px" }}
                                      />
                                      Back
                                    </MenuItem>
                                  </>
                                )}

                                {/* <MenuItem key="delApiKey">
                                Manage Column
                              </MenuItem> */}
                              </Paper>
                            ) : null}
                          </Box>
                        </ClickAwayListener>
                      </Box>
                    )}
                  </td>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>
      {page > 0 && (
        <TablePaginations
          pages={pages}
          page={page}
          setPage={setPage}
          totalSelectedItem={totalSelectedItem}
        />
      )}
    </>
  );
};

export function NewBasicTableWithBody(props) {
  const { minWid, maxWid, tableHead, tableBody } = props;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: minWid || 700, maxWidth: maxWid }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow sx={{ fontWeight: 900 }}>
              {tableHead?.length > 0 &&
                tableHead?.map((item) => (
                  <td key={item} align="left">
                    {item}
                  </td>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableBody}</TableBody>
        </Table>
      </TableContainer>

    </>
  );
}

export const PermissionTC = ({ data }) => <TableCell sx={{ border: 'none', fontWeight: 500, color: themes[selectedTheme]?.text.primary }}>{data}</TableCell>
