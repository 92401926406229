import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  AuthHeader,
  AuthP,
} from "../../../components/Helpers/helperComponents";
import { PostHandler } from "../../../Redux/RequestHandlers";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { InputLabels } from "../../../components/Helpers/label";
import {
  ButtonLoader,
  PrimaryButton,
} from "../../../components/Buttons";
import { PrimaryInputComponents } from "../../../components/Style";
import SocialAppLogin from "../../../components/Firebase/Sso";
export default function LoginFormComponent() {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [errors, setErrors] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setErrors(false);
      setIsLoading(true);
      const req = {
        url: "login",
        payload: inputs,
        action: "ACTION_LOGIN",
      };
      const res = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (!res?.data?.success) setErrors(true);
      if (res?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          res?.data?.data?.token?.access_token
        );
        if (res?.data?.data?.token?.refresh_token)
          window.localStorage.setItem(
            "refreshToken",
            res?.data?.data?.token?.refresh_token
          );
        navigate(
          res?.data?.data?.submerchantVerificationRedirection ? "/account/verify/" :
            res?.data?.data?.detailsExistance ? "/dashboard/" : "/account/setup/"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisable(inputs?.email && inputs?.password ? false : true);
  }, [inputs]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
        maxWidth: "508px",
        height: "100%",
      }}
    >
      <AuthHeader text="Sign in to Gafah" />
      <AuthP text="We're glad you are back" />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "20px",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
          <InputLabels label="Email" />
          <PrimaryInputComponents
            color="#000"
            error={errors}
            id="email"
            name="email"
            onChange={handleChange}
            value={inputs?.email || ""}
            placeholder="Enter your email address"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
          <InputLabels label="Password" />
          <PrimaryInputComponents
            color="#000"
            error={errors}
            id="password"
            type="password"
            name="password"
            onChange={handleChange}
            value={inputs?.password || ""}
            placeholder="Enter your password"
          />
        </Box>
        <Typography
          sx={{
            mt: "5px",
            color: "var(--main-colors-primary, #5235E8)",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.3px",
            textDecoration: "none",
            width: "max-content",
          }}
          component={Link}
          to="/reset-password/initiate/"
        >
          Forgot password?
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "40px",
          width: "100%",
        }}
      >
        {isLoading ? (
          <ButtonLoader />
        ) : (
          <PrimaryButton
            title="Sign In"
            onClick={handleSubmit}
            disabled={isDisable}
          />
        )}


        <SocialAppLogin />
      </Box>
      <Typography
        sx={{
          mt: "40px",
          color: "#0E0637",
          fontFeatureSettings: `'ss06' on`,
          fontFamily: "SF Pro Display",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20.6px",
          lineSpacing: "0.08px",
          textAlign: "center",
          width: "100%",
        }}
      >
        Don't have an account already?{" "}
        <Link
          to="/signup/"
          style={{
            color: "var(--main-colors-primary, #5235E8)",
            textDecoration: "none",
          }}
        >
          Sign up now
        </Link>
      </Typography>
    </Box>
  );
}
