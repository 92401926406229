import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { PostHandler } from "../../Redux/RequestHandlers";
import AddIcon from "@mui/icons-material/Add";
import { InputLabels } from "../../components/Helpers/label";
import { PrimaryBlackButton } from "../../components/Buttons";
import LeftToRightModal from "../../components/Dialog/LeftToRightModal";
import { PrimaryInputComponents } from "../../components/Style";



const AddRoles = (props) => {
  const { init } = props;
  const [inputs, setInputs] = useState({
    readonly: false,
  });
  const [isLoadings, setIsLoadings] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

 

  const handleClose = () => {
    setInputs({});
    setOpen(true);

  };
  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleSubmit = async () => {
    try {
      setIsLoadings(true);
      const req = {
        url: "roles",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setOpen(true)
        init();
      }
      setIsLoadings(false);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsDisable(inputs?.name ? false : true);
  }, [inputs]);
  return (
    <LeftToRightModal

      openButton={
        <PrimaryBlackButton
          title="Add new role"
          onClick={()=>setOpen(false)}
          sx={{ textTransform: "capitalize" }}
          text={<><AddIcon sx={{ fontSize: '20px' }} /> Add Role </>}
        />
      }
      width="35%"
      modalTitle="Add new role"
      modalBody={
        <Box sx={{ px: '15px' }}>
          <InputLabels label="Role name" />
          <PrimaryInputComponents
            id="Name"
            name="name"
            variant="outlined"
            onChange={handleChange}
            value={inputs?.name || ""}
            sx={{
              width: "100%",
              mt: "5px",
              "@media (max-width:600px)": {
                width: "100%",
              },
            }}
            required
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              my: "10px",
            }}
          >
            <input
              type="checkbox"
              onChange={(e) =>
                setInputs((prev) => ({ ...prev, readonly: !inputs?.readonly }))
              }
              style={{ marginTop: "10px" }}
            />
            <InputLabels label="Read Only" />
          </Box>

          {isLoadings ? (
            <PrimaryBlackButton
              text={<CircularProgress size="1.3rem" />}
            />
          ) : (
            <PrimaryBlackButton
              text="Yes, confirm"
              onClick={handleSubmit}
              disabled={isDisable}
            />
          )}

        </Box>

      }
      closeTrigger={open}
    />
  );
};

export default AddRoles;
