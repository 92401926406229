import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadHandler } from "../../../Redux/RequestHandlers";
import { InputLabels } from "../../../components/Helpers/label";
import { PrimaryInputComponents } from "../../../components/Style";
import { ButtonLoader, PrimaryBlackButton } from "../../../components/Buttons";
import KYCPending from "./Pending";
import { CardLayout } from "../../../components/Layouts/AllLayOuts";

const BankVerification = () => {
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const [inputs, setInputs] = useState({
    documentType: "aadharcard",
  });
  const [images, setImages] = useState({});
  const [rawFiles, setRawFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setRawFiles((prev) => ({
        ...prev,
        [e.target.name]: e.target.files[0],
      }));
      setImages((prev) => ({
        ...prev,
        [e.target.name]: URL.createObjectURL(e.target.files[0]),
      }));
    }
  };

  useEffect(() => {
    setIsDisable(
      inputs?.fullnamebank &&
        inputs?.bankname &&
        inputs?.accountnumber &&
        inputs?.confirmaccountnumber &&
        inputs?.ifsc &&
        rawFiles?.bankdocument
        ? false
        : true
    );
  }, [inputs, rawFiles]);

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (isDisable) return setLoading(false);
      const data = new FormData();
      data.append("fullnamebank", inputs?.fullnamebank);
      data.append("bankname", inputs?.bankname);
      data.append("accountnumber", inputs?.accountnumber);
      data.append("ifsc", inputs?.ifsc);
      data.append("bankdocument", rawFiles?.bankdocument);
      const req = {
        url: "bankVerification",
        payload: data,
        action: "ACTION_CHANGE_KYC_BANK",
      };

      await dispatch(uploadHandler(req));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!kycStatus?.company) navigate("/settings/kyc/company-verification/");
  }, [kycStatus, navigate]);

  return (
    <>
      <Container>
        <Box
          sx={{
            display: "flex",
            pb: "20px",
          }}
        >
          <Grid container>
            <Grid item xs={12} lg={12}>
              <CardLayout
                p="15px"
                component={
                  <>
                    {/Requested|Pending|Completed/.test(kycStatus?.status) ||
                      kycStatus?.bank ? (
                      <KYCPending
                        kyc={kycStatus?.status}
                        to="/dashboard/"
                        title="Ok, Thanks"
                      />
                    ) : (
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels
                              label="Full Name (as per bank passbook)"
                              mb="5px"
                            />
                            <PrimaryInputComponents
                              id="fullname"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter full name"
                              name="fullnamebank"
                              value={inputs?.fullnamebank || ""}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Bank Name" mb="5px" />
                            <PrimaryInputComponents
                              id="bankname"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter full bank name"
                              name="bankname"
                              value={inputs?.bankname || ""}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: "10px" }}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Account Number" mb="5px" />
                            <PrimaryInputComponents
                              id="accountnumber"
                              type="password"
                              onChange={handleChange}
                              placeholder="Enter account number"
                              name="accountnumber"
                              value={inputs?.accountnumber || ""}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Confirm Account Number" mb="5px" />
                            <PrimaryInputComponents
                              id="confirmaccountnumber"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter account number"
                              name="confirmaccountnumber"
                              value={inputs?.confirmaccountnumber || ""}
                            />
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ mt: "10px" }}>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="IFSC code" mb="5px" />
                            <PrimaryInputComponents
                              id="ifsc"
                              type="text"
                              onChange={handleChange}
                              placeholder="Enter IFSC code"
                              name="ifsc"
                              value={inputs?.ifsc || ""}
                            />
                          </Grid>
                        </Grid>

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px",
                            mt: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <InputLabels
                              label="Upload Check/Bank statement"
                              mb="20px"
                            />
                            <Box
                              className="modal_input_image"
                              sx={{
                                height: "350px",
                                borderRadius: "40px",
                                border: "1px dashed var(--Gray-4, #BDBDBD)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden",
                                // placeContent: "center",
                              }}
                            >
                              <label className="mt-3">
                                {images?.bankdocument ? (
                                  <Box
                                    sx={{
                                      maxHeight: "100%",
                                      backgroundSize: "contain",
                                    }}
                                    component={"img"}
                                    src={images?.bankdocument}
                                  />
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="62"
                                      height="62"
                                      viewBox="0 0 62 62"
                                      fill="none"
                                    >
                                      <path
                                        d="M3.875 15.5H17.4375L23.25 7.75H38.75L44.5625 15.5H58.125V50.375H3.875V15.5Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M31 40.6875C36.3503 40.6875 40.6875 36.3503 40.6875 31C40.6875 25.6497 36.3503 21.3125 31 21.3125C25.6497 21.3125 21.3125 25.6497 21.3125 31C21.3125 36.3503 25.6497 40.6875 31 40.6875Z"
                                        stroke="#005EFE"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                    Click to upload image
                                  </>
                                )}
                                <input
                                  type="file"
                                  name="bankdocument"
                                  onChange={onImageChange}
                                  accept="image/*"
                                  required
                                />
                              </label>
                            </Box>
                            <Typography
                              sx={{
                                mt: "20px",
                                fontFamily: "SF Pro Display",
                                fontSize: "16px",
                                fontWeight: 400,
                              }}
                            >
                              Provide files in JPG or PDF format, 10 MB maximum
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ mt: "20px" }}>
                          {loading ? (
                            <ButtonLoader maxWidth="100px" />
                          ) : (
                            <PrimaryBlackButton
                              text="Save"
                              onClick={handleSubmit}
                              disabled={isDisable}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </>}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default BankVerification;
