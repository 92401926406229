import React from "react";
import { Box } from "@mui/material";
import HorizontalGafahQRPageStepper from "./posQRGafahStepper";
import { QRCode } from "react-qrcode-logo";
import qrlogo from "../../../assets/img/pos/logo.png";
import { ResourceLoader } from "../../../components/Helpers/svg";

export default function POSQRGafah({
  status,
  loading,
  currency,
  amount,
  paymentUrl,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        mb: "33px",
        alignItems: "center",
      }}
    >
    <Box variant="h4" sx={{ mt: '20px', fontSize: '14px', color: 'grey', fontWeight: 600 }}>Please pay with Gafah App.</Box>
      <Box
        sx={{
          borderRadius: "15px",
          background: "#FFF",
          // boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.18)",
          p: "10px",
          display: "flex",
          justifyContent: "center",
          width: "fit-content",
        }}
      >
        {loading ? (
          <Box sx={{ m: "50px" }}>
            <ResourceLoader />
          </Box>
        ) : (
          <QRCode
            value={paymentUrl}
            logoImage={qrlogo}
            padding={0}
            size={260}
            // logoOpacity={0.9}
            logoWidth={60}
          />
        )}
      </Box>
      <HorizontalGafahQRPageStepper status={status} />
    </Box>
  );
}
