import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler } from "../../Redux/RequestHandlers";
import { CardTitleComponentWithRightArrow } from "../../components/Cards";
import BasicTableWithBody, {
  TableDataLoad, NoRecordInTable,
  StyledTableCell,
  StyledTableRow,
} from "../../components/Tables";
import { useState } from "react";
import { CardLayout } from "../../components/Layouts/AllLayOuts";

const Prices = () => {
  const { prices } = useSelector((state) => state?.transactions);
  const { showCurrency } = useSelector((state) => state?.dashboard);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);

  const init = async () => {
    setIsFetching(true);
    try {
      const req = {
        url: "prices",
        action: "ACTION_GET_ALL_PRICES",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false)
    } catch (error) {
      console.error(error);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CardLayout
      component={<>
        <CardTitleComponentWithRightArrow
          mainTitle="Real Time Prices"
          noArrow={true}
        />

        <BasicTableWithBody
          // data={prices}
          tableHead={["Token", "Network", "Type", `Price (${showCurrency && showCurrency?.toUpperCase()})`]}
          minWid="100%"
          maxWid="100%"
          tableBody={
            <>
              {isFetching ? (
                <TableDataLoad />
              ) : prices?.length > 0 ? (
                prices.map((row, index) => (
                  <StyledTableRow key={index + "price"}>
                    <StyledTableCell component="th" scope="row">
                      <Box sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px'
                      }}>
                        <Box sx={{ width: '25px', display: 'grid', placeContent: 'center' }}>
                          <img src={row?.logo} alt={row?.logo} height="25px" />
                        </Box>
                        {row?.token}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.network}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.type}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {showCurrency === "usd"
                        ? row?.realTimePrice?.usd
                        : row?.realTimePrice?.aed}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <NoRecordInTable />
              )}
            </>
          }
        />
      </>}
    />
  );
};

export default Prices;
