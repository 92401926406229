import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import POSCryptoComponent from "./posCryptoCurrencyComponent";
import POSQRExternal from "./posQRExternalPage";
import { useDispatch, useSelector } from "react-redux";
import ExternalSocketService from "../../../utilities/ExternalSocketService";
import axios from "axios";
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';


import {
  AuthHeader,
  AuthPRight,
  Failed,
  GoDownAnimation,
  UrlReject,
} from "../../../components/Helpers/helperComponents";
import { useState } from "react";
import {
  InfoDialog,
  CautionDialog,
  DeleteDialog,
} from "../../../components/Dialog";
import {
  ACTION_GET_LIST_OF_CURRENCIES,
  ACTION_PAYLOAD_SET,
  ACTION_SET_SOCKET_DATA,
} from "../../../Redux/Reducers/pos.reducer";
import { ResourceLoader } from "../../../components/Helpers/svg";
import moment from "moment";
import CountdownComponent from "../../../components/Helpers/countdown";
import { addBigNumber, calculateComparePrice, hasObjectPropertyExist } from "../../../utilities";
import api from "../../../config/api";
import { findUrl } from "../../../Redux/RequestHandlers";
import { useEffect } from "react";
import PaymentLayout, { MerchantAndLogoComponent, OrderBox } from "../Component/PosComponents";
import { ButtonLoader, ErrorButton, PrimaryBlackButton } from "../../../components/Buttons";
import BasicPopover from "../../../components/Style/PopOver";

export const ComponentHeader = (props) => {
  const tokenText = props?.invoiceToken || "Select a Wallet";
  const networkText = props?.invoiceNetwork || "";

  return (
    <>
      <AuthHeader
        text={`${tokenText} ${networkText && `(${networkText})`}`}
        fs="34px"
        fw="700"
      />
      <AuthPRight
        text={
          networkText
            ? `Please send ${tokenText}(${networkText}) only to this address.`
            : "Please select a wallet from the options given below to proceed."
        }
      />
    </>
  );
};

export default function ExternalWallet() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { invoices } = useSelector((state) => state?.pos);
  // console.log("invoicesExternal", invoices);

  const [canceled] = useState(false);
  // const [showComponent, setShowComponent] = useState(true);
  const [network, setNetwork] = React.useState(null);
  const [token, setToken] = React.useState(null);
  const [loadingQR, setLoadingQR] = React.useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isRejected, setIsRejected] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isSocketConnected, setIsSocketConnected] = useState(true);
  const [hasShiftedToPolling, setHasShiftedToPolling] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const param1 = queryParams.get("id");
  const exchange = queryParams.get("exchange");
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [shouldContinue, setShouldContinue] = useState(true);
  const [swapLoading, setSwapLoading] = useState(false)


  // api calls
  const init = async () => {
    try {
      // return;
      const res = await axios.get(findUrl(api, "getCurrencies"), {
        headers: {
          Authorization: `Bearer ${invoices[param1]?.jwt?.accessToken}`,
        },
      });
      if (res?.data?.success)
        await dispatch({
          type: ACTION_GET_LIST_OF_CURRENCIES,
          payload: res?.data,
          invoiceId: param1
        });
    } catch (error) {
      setIsRejected(true);
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (param1 && hasObjectPropertyExist(invoices, param1)) {
      if (!invoices[param1]?.paymentMethod && invoices[param1]?.currentPage !== "externalPaymentCheckout") init();
      // setShowComponent(invoices[param1]?.token ? false : true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  const handleExternalClick = async () => {
    setLoadingQR(true);
    setShouldContinue(false)
    try {
      const payload = {
        id: param1,
        mode: "External",
        network: network,
        token: token,
      };
      if (exchange) payload.exchange = exchange;
      const result = await axios.post(
        findUrl(api, "initiateInvoicePayment"),
        // config?.API_URL + api?.initiateInvoicePayment,
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt?.accessToken}`,
          },
        }
      );
      if (result?.data?.success) {
        if (result?.data?.data?.jwt_token)
          window.localStorage.setItem("paymentToken", result?.data?.data?.jwt_token?.accessToken)
        const dx = result?.data?.data;
        let tempInv = invoices[param1];
        const kk = {
          ...tempInv,
          ...dx,
        };
        if (tempInv?.paymentMethod === "Gafah") {
          delete kk.url;
          delete kk.paymentMethod;
        }
        kk.paymentMethod = "External";
        kk.currentPage = "externalPaymentCheckout"

        // tempInv.expire = dx?.network;
        if (dx?.id === invoices[param1]?.id)
          dispatch({
            type: ACTION_PAYLOAD_SET,
            reqState: param1,
            payload: kk,
          });
      }
      setLoadingQR(false);
      setIsRejected(result?.data?.success ? false : true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelClick = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;

      setLoadingQR(true);

      let payload = {
        id: invoices[param1]?.id,
      };
      const cancelres = await axios.post(
        findUrl(api, "cancelTransaction"),

        // config?.API_URL + api?.cancelTransaction,
        payload,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );

      if (cancelres?.data?.success) {
        setIsRejected(true);
        setIsCanceled(true);
        dispatch({
          type: "ACTION_CLEAR_PAYMENT_DETAILS",
          payload: param1,
        });
        navigate("/");
      }
    } catch (error) {

      console.error(error);
    }
  };

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  React.useEffect(() => {
    // Connect to the socket when component mounts
    let clientId = localStorage.getItem("clientId");
    localStorage.setItem("clientId", param1);
    clientId = param1;
    ExternalSocketService.connect();
    ExternalSocketService.emitEvent("clientId", clientId);
    ExternalSocketService.emitEvent("client-message", "hello");
    ExternalSocketService.subscribeToEvent("serverMessage", (data) => {
      console.log("sockets data", data);
      if (isJSON(data)) {
        let result = JSON.parse(data);
        if (result?.data) {
          dispatch({
            type: ACTION_SET_SOCKET_DATA,
            reqState: param1,
            payload: result?.data,
          });
        }
      }
    });
    ExternalSocketService.subscribeToEvent("connect", () => {
      setIsSocketConnected(true);
      console.log("socket");
    });

    ExternalSocketService.subscribeToEvent("disconnect", () => {
      setIsSocketConnected(false);
      setHasShiftedToPolling(true);
    });

    setIsSocketConnected(ExternalSocketService.isConnected());

    return () => {
      // Disconnect from the socket when component unmounts
      ExternalSocketService.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transactionPolling = async () => {
    try {
      if (!hasObjectPropertyExist(invoices, param1)) return;

      const res = await axios.get(
        `${findUrl(api, "transactionPolling")}?id=${invoices[param1]?.id}`,
        // `${config?.API_URL + api?.transactionPolling}?id=`,
        {
          headers: {
            Authorization: `Bearer ${invoices[param1]?.jwt_token?.accessToken}`,
          },
        }
      );
      if (res?.data?.success) {
        const dx = res?.data?.data;
        dispatch({
          type: ACTION_SET_SOCKET_DATA,
          reqState: param1,
          payload: dx,
        });
      }

      if (!res?.data?.success) setIsRejected(true);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!hasObjectPropertyExist(invoices, param1)) return;
    // Initialize intervalId variable to keep track of the interval
    let intervalId;

    // Function to set up the interval
    const setupInterval = () => {
      // Set up interval only when payment details are available, shifted to polling, and not rejected
      if (invoices[param1]?.token && hasShiftedToPolling && !isRejected) {
        intervalId = setInterval(transactionPolling, 15000);
      }
    };

    // Function to clear the interval
    const clearIntervalIfRejected = () => {
      // If rejected becomes true and intervalId exists (meaning the interval is set), clear the interval
      if (isRejected && intervalId) {
        clearInterval(intervalId);
      }
    };

    // Set up interval initially
    setupInterval();

    // Clear the interval if rejected becomes true after the interval has already been set up
    clearIntervalIfRejected();

    // Clean up the interval when the component unmounts or when the conditions for setting up the interval change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, hasShiftedToPolling, isRejected]);

  React.useEffect(() => {
    // if invoice not exits return
    if (!hasObjectPropertyExist(invoices, param1))
      return navigate(`/invoice?id=${param1}/`);

    // if invoice completed return to success page
    if (invoices[param1]?.status === "Completed")
      return navigate(`/invoice/success/${invoices[param1]?.id}/`);

    // if invoice Expired return to failed page
    if (invoices[param1]?.status === "Expired")
      return navigate(`/invoice/failed/${invoices[param1]?.id}`, {
        replace: true,
      });

    // checking is transaction expire or not
    if (invoices[param1]?.expireDate) {
      const local = moment();
      const PAYMENTEXP = invoices[param1]?.expireDate;
      if (local.isAfter(PAYMENTEXP)) return setIsRejected(true);
      // Set the exact time when you want the event to occur
      const eventTime = new Date(PAYMENTEXP).getTime(); // Replace with your desired time

      // Calculate the delay until the event time
      const delay = eventTime - new Date().getTime();
      // Check if the event time has already passed
      if (delay > 0) {
        // Set a timeout to trigger the event
        const timeoutId = setTimeout(() => {
          // Your event logic here

          // handleExpireTransaction()
          setIsRejected(true);
        }, delay);

        // Clean up the timeout on component unmount (optional)
        return () => clearTimeout(timeoutId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, param1]);

  useEffect(() => {
    setIsSubmitDisabled(network && token && shouldContinue ? false : true);
  }, [network, token, shouldContinue]);

  return (
    <>
      <PaymentLayout
        selectCrypto={true}
        selectWalletComplete={true}
        selectCurrencyComplete={invoices[param1]?.currentPage === "externalPaymentCheckout"}
        active={invoices[param1]?.currentPage === "selectCurrency" ? "selectCurrency" : invoices[param1]?.currentPage === "externalPaymentCheckout" && "paymentCheckout"}
        bodyComponent={
          <Box
            sx={{
              p: "20px",
            }}
          >
            {/* merchant Name */}
            <MerchantAndLogoComponent merchantName={invoices[param1]?.merchantName} bb="0px" />
            {/* order box */}
            <OrderBox
              amount={invoices[param1]?.currency + " " + invoices[param1]?.amount}
              mainTitleText="Amount"
              subTitleText={exchange ? `${exchange} exchange` : "External Wallet"}
              rightSubTitleText={swapLoading ? <Box><Box className="loader" /> </Box> :
                invoices[param1]?.currentPage === "selectCurrency" ? invoices[param1]?.realTimePrice &&
                  <Box sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                    {invoices[param1]?.amount + " " + invoices[param1]?.currency}
                    <SwapHorizOutlinedIcon sx={{ fontSize: '12px' }} />
                    {calculateComparePrice(invoices[param1]?.amount, invoices[param1]?.realTimePrice[invoices[param1]?.selectedCurrency?.pricesymbol][["AED", "aed"].includes(invoices[param1]?.currency)? "aed" : "usd"])+ " " + invoices[param1]?.selectedCurrency?.token}
                  </Box>
                  :
                  invoices[param1]?.currentPage === "externalPaymentCheckout" && invoices[param1]?.exchange &&
                  <BasicPopover
                    title="click to view breakup"
                    openButton={
                      <Typography
                        sx={{
                          background: "#495eca",
                          p: "2px 5px",
                          fontSize: "10px",
                          display: "inline-block",
                          borderRadius: "5px",
                          color: '#fff',
                        }}
                      >
                        View breakup
                      </Typography>
                    }
                    body={<Box sx={{ p: '10px', borderRadius: '5px' }}>
                      <Box sx={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                        {invoices[param1]?.amount + " " + invoices[param1]?.currency} <SwapHorizOutlinedIcon sx={{ fontSize: '12px' }} /> {invoices[param1]?.cryptoAmount + " " + invoices[param1]?.token}
                      </Box>
                      <Typography sx={{ fontSize: '12px' }}>Exchange: {invoices[param1]?.exchange} </Typography>

                      <Typography sx={{ fontSize: '12px' }}>{invoices[param1]?.exchange} Withdrawal Fee: {invoices[param1]?.exchangeFee + " " + invoices[param1]?.token}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>Total: {invoices[param1]?.cryptoAmountWithFee + " " + invoices[param1]?.token}</Typography>
                    </Box>}
                  />
              }

            />

            {hasObjectPropertyExist(invoices, param1) && (
              <>
                <Box>
                  {/* <AuthPRight text /> */}
                  {!param1 ? (
                    <UrlReject
                      my="100px"
                      text={`url should be ${location?.pathname}?id=xxxxx-xxx-xxxx...`}
                    />
                  ) : isRejected ? (
                    <Failed
                      title={isCanceled ? "Canceled" : ""}
                      my="100px"
                      showComponent={
                        <CountdownComponent
                          content="This page will redirect in "
                          colour="red"
                          expireIn={3}
                        />
                      }
                      text={
                        isCanceled
                          ? "Looks like the transaction has been canceled, please try to Restart the Payment"
                          : "Looks like the transaction has expired, please try to Restart the Payment"
                      }
                    />
                  ) : invoices[param1]?.currentPage === "selectCurrency" ? (
                    <>
                      <Box sx={{
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                        fontWeight: 600,
                        justifyContent: 'center',
                        mt: '2rem'
                      }}>
                        <InfoDialog description="The amount of digital assets is dependent on the market and is subject to change. In case of a major change in the asset price, the amount can be changed mid transaction also." />
                        Select Payment Currency
                      </Box>
                      <POSCryptoComponent
                        setNetwork={setNetwork}
                        setToken={setToken}
                        token={token}
                        amount={invoices[param1]?.amount}
                        currency={invoices[param1]?.currency}
                        shouldContinue={shouldContinue}
                        setShouldContinue={setShouldContinue}
                        invoiceId={param1}
                        invoiceDetails={invoices[param1]}
                        setSwapLoading={setSwapLoading}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: shouldContinue ? "space-between" : "flex-end",
                          pt: 2,
                          width: "100%",
                        }}
                      >
                        {shouldContinue &&
                          <ErrorButton
                            color="inherit"
                            component={Link}
                            to={`/invoice?id=${param1}`}
                            text="Go Back"
                          />
                        }

                        {shouldContinue ?
                          <CautionDialog
                            disabled={isSubmitDisabled}
                            titleHeading="Important"
                            description="Exchange withdrawals may incur fees and they may deduct the fee from the entered withdrawal amount. Check your exchange's fee structure to ensure that you send the correct amount."
                            nextButton={
                              <PrimaryBlackButton
                                color="inherit"
                                disabled={isSubmitDisabled}
                                onClick={() => {
                                  handleExternalClick();
                                  // setShowComponent(false);
                                }}
                                text="Continue"
                              />
                            }
                          /> :
                          <ButtonLoader maxWidth="100px" />
                        }
                      </Box>
                    </>
                  ) : invoices[param1]?.currentPage === "externalPaymentCheckout" && (
                    <>
                      {loadingQR ? (
                        <Box sx={{ py: "130px", width: '100%', display: 'grid', placeContent: 'center' }}>
                          {" "}
                          <ResourceLoader />
                        </Box>
                      ) : (
                        <POSQRExternal
                          loading={loadingQR}
                          showLog={() => setShowLog(!showLog)}
                          paymentData={invoices[param1]}
                        />
                      )}
                      {!loadingQR && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            // pt: 2,
                            width: "100%",
                            gap: "15px",
                          }}
                        >
                          <DeleteDialog
                            isShow={true}
                            customComponent={
                              <ErrorButton
                                title="Click to cancel"
                              // text={<DeleteOutlineOutlinedIcon sx={{ fontSize: '18px', fontWeight: 900, color: '#cf6679' }} />}
                              />
                            }
                            title="Are your sure to cancel?"
                            content="Are you sure to cancel this transaction"
                            execute={handleCancelClick}
                            executeLoading={canceled}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Box>

                {invoices[param1]?.status === "priceChange" && (
                  <>
                    <Box
                      sx={{
                        mt: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowLog(!showLog)}
                    >
                      <GoDownAnimation />
                    </Box>
                  </>
                )}

                {showLog && (
                  <Box
                    sx={{
                      borderRadius: "5px",
                      border: "1px solid var(--Neutral-300, #EFF0F6)",
                      background: "var(--Neutral-100, #FFF)",
                      width: "100%",
                      p: '5px',
                      // display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      mt: "15px",
                      // backgroundColor: 'blue',
                      // "@media (max-width:570px)": { width: "60%" },
                    }}
                  >
                    {invoices[param1]?.socketLogs?.length > 0 &&
                      invoices[param1]?.socketLogs?.map((item, index) => (
                        <Box
                          key={item?.id + index}
                          sx={{
                            mt: "10px",
                            border: "1px solid red",
                            borderRadius: "5px",
                            fontSize: "10px !important",
                            color: "red !blue",
                          }}
                        >
                          Looks like real time price of {invoices[param1]?.token} (
                          {invoices[param1]?.network}) has changed to{" "}
                          {item?.newRealTimePrice} ({item?.percentChange}%). Now your
                          new amount is {invoices[param1]?.exchange ? addBigNumber(invoices[param1]?.exchangeFee, item?.newAmount) : item?.newAmount} and pending amount is{" "}
                          {item?.pendingAmount}.
                        </Box>
                      ))}
                  </Box>
                )}
              </>
            )}
          </Box>
        }
      />
    </>

  );
}
