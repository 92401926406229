import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Logo from "../../assets/img/logo/logo-light2.png";
import ResponsiveAppBar from "../../components/appbar";
import ComponentOne from "./components/componentOne";
import ComponentThree from "./components/componentThree";
import ComponentFive from "./components/componentFive";
import ComponentSix from "./components/ComponentSix";
import ComponentSeven from "./components/componentSeven";
import ComponentEight from "./components/componentEight";
import ComponentFour from "./components/componentFour";
import { useNavigate } from "react-router-dom";
export default function GafahPay() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/", { replace: true });
  };
  useEffect(() => {
    // Reset the scroll position to the top on page load
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main">
      <Box
        sx={{
          margin: "20px 15px 0px",
          background: "black",
          height: "100%",
          color: "white",
          borderRadius: "30px 0px 30px 30px",
          padding: "0px 0px 0px 80px ",
          zIndex: 2,
          "@media (max-width:800px)": {
            p: "0!important",
            margin: "10px 8px 0px",
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            component="img"
            src={Logo}
            onClick={handleClick}
            sx={{
              height: "50px",
              mt: "22px",
              "@media (max-width:800px)": {
                pl: "40px!important",
              },
              "@media (max-width:500px)": {
                height: "40px!important",
                mt: "18px",
              },
              cursor: "pointer",
            }}
          />
          <Box
            sx={{
              background: "#FFF",
              color: "black!important",
              maxWidth: "800px",
              maxHeight: "65px",
              borderRadius: "0px 0px 0px 30px!important",

              "@media (max-width:800px)": {
                pl: 0,
              },
              "@media (max-width:500px)": {
                height: "60px!important",
              },
            }}
          >
            <ResponsiveAppBar />
          </Box>
        </Box>
        <ComponentOne />
      </Box>

      {/* <ComponentTwo /> */}
      <ComponentThree mt="120px" />
      <ComponentFour />
      <ComponentFive />
      <ComponentSix />
      <ComponentSeven />
      <ComponentEight />
      
    </div>
  );
}
