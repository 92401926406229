import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";
import SideNav from "./Layout/Desktop/SideNav";
import BodyLayout from "../Layouts/AllLayOuts";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function DashboardLayout(props) {
  // const { role } = useSelector((state) => state?.auth);

  const { component, innerHead } = props;

  // Backdrop use states and functions
  const [openbackdrop, setOpenbackdrop] = React.useState(false);
  const handleClose = () => {
    setOpenbackdrop(false);
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openbackdrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {/* <DashboardDrawer /> */}
        <SideNav />

        <BodyLayout innerHead={innerHead} mainComponent={component} />
        
      </Box>
    </ThemeProvider>
  );
}
