import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import { ButtonLoader, ErrorButton, PrimaryBlackButton } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import SmartLinkList from "./SmartLinkList";
import KYCBLOCK from "../../components/Kyc";
import { getTotalPage, hasAccess } from "../../utilities";
import { CardTitleComponentWithFilterComponent, CardTitleComponentWithRightArrow } from "../../components/Cards";
import BasicTableWithBody, { NoRecordInTable, StyledTableRow, TableDataLoad } from "../../components/Tables";
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";
import { PrimaryInputComponents, PrimarySelectComponent } from "../../components/Style";

export default function SmartLinks() {
  const { accessLists } = useSelector((state) => state.auth);
  const { smartLinks } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [inputs, setInputs] = useState({
    currency: "USD",
    expiryAt: "1",
  });

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const perPage = 10;

  const handleChange = (e) => {
    if (e.target.name === "amount" && isNaN(e.target.value)) return;
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const generateSmartLink = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "smartLink",
        action: "ACTION_GET_RETURNED_SMARTLINK",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setInputs({
          currency: "USD",
          expiryAt: "1",
        });
        setIsShow(false);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  // api calls
  const init = async () => {
    try {
      setIsFetching(true);
      let req = {
        url: "smartLink",
        action: "ACTION_GET_SMARTLINKS",
      };
      const res = await dispatch(GetHandler(req));
      if (res?.data?.data?.count)
        setTotalPages(
          parseInt(
            getTotalPage(res?.data?.data?.count, perPage)
          )
        );
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  useEffect(() => {
    setIsDisableSubmit(
      inputs?.currency &&
        inputs?.amount &&
        inputs?.amount > 0 &&
        inputs?.expiryAt
        ? false
        : true
    );
  }, [inputs]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Grid container>
          <Grid item md={3} lg={3}>
            <Box
              sx={{
                pt: "32px",
                mb: "15px",
              }}
            >
              <Header5Layout text="Smart Links" fs="16px" />

              <AuthPRightLayout
                text="Smart Links let you use Gafah from within other tools or your own software."
                fs="16px"
                lh="22px"
                mt="10px"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            {kycStatus?.status !== "Completed" ? (
              <KYCBLOCK />

            ) : (
              <>
                {/* add apikey */}
                {isShow && (
                  <CardLayout
                    my="15px"
                    component={<>
                      <CardTitleComponentWithRightArrow mainTitle="Generate New Smart Link" noArrow={true} />

                      <Box sx={{ p: '15px' }}>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                          }}
                        >
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Currency" />
                            <PrimarySelectComponent
                              // label="Currency"
                              defaultValue="USD"
                              name="currency"
                              value={inputs?.currency}
                              onChange={handleChange}
                              sx={{
                                width: "100%",
                                mt: "5px",
                                fontSize: '14px'
                              }}
                              size="small"
                              children={["USD", "AED"].map((item, index) =>
                                <MenuItem value={item} key={index + item} sx={{ fontSize: '14px' }}>{item}</MenuItem>)
                              }
                            />

                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Amount" mb="5px" />
                            <PrimaryInputComponents
                              id="amount"
                              InputLabelProps={{ shrink: true }}

                              name="amount"
                              onChange={handleChange}
                              value={inputs?.amount || ""}
                              placeholder="Enter Amount"
                              size="small"

                              required
                            />
                          </Grid>

                          <Grid item xs={12} lg={6}>
                            <InputLabels label="Expires In" />
                            <PrimarySelectComponent
                              defaultValue="1"
                              name="expiryAt"
                              value={inputs?.expiryAt}
                              onChange={handleChange}
                              sx={{
                                width: "100%",
                                mt: "5px",
                                fontSize: '14px',

                              }}
                            >
                              <MenuItem value="1" sx={{ fontSize: '14px' }}>1 Day</MenuItem>
                              <MenuItem value="10" sx={{ fontSize: '14px' }}>10 Day</MenuItem>
                              <MenuItem value="20" sx={{ fontSize: '14px' }}>20 Day</MenuItem>
                              <MenuItem value="30" sx={{ fontSize: '14px' }}>30 Day</MenuItem>
                              <MenuItem value="90" sx={{ fontSize: '14px' }}>90 Day</MenuItem>
                              <MenuItem value="180" sx={{ fontSize: '14px' }}>180 Day</MenuItem>
                              <MenuItem value="270" sx={{ fontSize: '14px' }}>270 Day</MenuItem>
                              <MenuItem value="365" sx={{ fontSize: '14px' }}>1 Year</MenuItem>
                            </PrimarySelectComponent>
                          </Grid>
                        </Box>
                        <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                          {isSubmitting ? (
                            <ButtonLoader />
                          ) : (
                            <PrimaryBlackButton
                              variant="contained"
                              title="Submit"
                              onClick={generateSmartLink}
                              disabled={isDisableSubmit}
                            />
                          )}

                          <ErrorButton onClick={() => setIsShow(false)}>Cancel</ErrorButton>
                        </Box>
                      </Box>
                    </>}
                  />
                )
                }


                {/* smartlinks */}
                <CardLayout
                  component={<>
                    <CardTitleComponentWithFilterComponent
                      mainTitle="Smartlinks"
                      component={
                        hasAccess(["Smartlink-create"], accessLists) &&
                        <PrimaryBlackButton
                          text={<><AddOutlinedIcon sx={{ fontSize: '20px', fontWeight: 900 }} /> Generate</>}
                          onClick={() => setIsShow(true)}
                        />
                      }

                    />

                    <BasicTableWithBody
                      maxWid="100%"
                      minWid="300px"
                      tableHead={[
                        "Amount",
                        "Code",
                        "Expires At",
                        "Action",
                      ]}
                      tableBody={
                        <>
                          {isFetching ? (
                            <TableDataLoad />
                          ) : smartLinks?.rows?.length > 0 ? (
                            smartLinks?.rows?.map(item => (
                              <StyledTableRow key={item?.id}>
                                <SmartLinkList item={item} keyC={item?.id} />
                              </StyledTableRow>

                            ))
                          ) : (
                            <NoRecordInTable />
                          )}
                        </>
                      }

                      pages={totalPages}
                      page={page}
                      setPage={setPage}
                    />
                  </>}
                />
                {/* end smartlinks */}

              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
