import { PDFViewer, pdf } from "@react-pdf/renderer";
import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from '@mui/icons-material/Print';
import { PrimaryBlackButton } from "../../components/Buttons";
import PaymentInvoicePdf from "../Transactions/components/PaymentInvoicePdf";

const PaymentReceipt = (props) => {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [blobUrl, setBlobUrl] = useState(null);

    const handleDownload = () => {
        if (blobUrl) {
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = `${props?.data?.id}_invoice.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    useEffect(() => {
        const generatePdfBlob = async () => {
            const doc = <PaymentInvoicePdf data={props?.data} />;
            const asBlob = await pdf(doc).toBlob();
            const url = URL.createObjectURL(asBlob);
            setBlobUrl(url);
        };

        if (props?.data) generatePdfBlob();
    }, [props?.data]);
    return (
        <>
            <PrimaryBlackButton onClick={handleClickOpen}
                title="click to open"
                text={<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><PrintIcon sx={{ fontSize: '18px' }} /> Print Receipt</Box>}
            />

            {/* <Button onClick={handleClickOpen}>Download</Button> */}
            {blobUrl && (
                <PrimaryBlackButton
                    onClick={handleDownload}
                    title="click to download"
                    text={<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}><FileDownloadOutlinedIcon sx={{ fontSize: '18px' }} /> Download</Box>}
                />
            )}

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <Box
                    sx={{
                        height: "90vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <PDFViewer
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                    >
                        <PaymentInvoicePdf data={props?.data} />
                    </PDFViewer>
                </Box>
            </Dialog>
        </>
    );
};

export default PaymentReceipt