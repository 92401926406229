import React from "react";
import { Box } from "@mui/material";
import WatchlistListComponent from "./watchlistCryptos";
import { useSelector } from "react-redux";
import { tokenHelpers } from "../../../../utilities";

export default function MyWatchListComponent() {
    const { balancesData, showCurrency } = useSelector(
        (state) => state?.dashboard
    );

    return (
        <Box
            sx={{
                borderRadius: " 8px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                height: "100%",
            }}
        >

            <Box>
                {balancesData?.balances?.length > 0 &&
                    balancesData?.balances?.map((item, index) => (
                        <Box
                            key={item?.logo + index + 'wx'}
                        >
                            <WatchlistListComponent
                                icon={item?.logo}
                                cryptoName={tokenHelpers[item?.token]}
                                cryptoShortName={item?.token}
                                amount={
                                    showCurrency === "usd"
                                        ? Number(item?.realTimePrice?.usd).toFixed(2)
                                        : Number(item?.realTimePrice?.aed).toFixed(2)
                                }
                                showCurrency={showCurrency}
                            />
                        </Box>
                    ))}
            </Box>
        </Box>
    );
}
