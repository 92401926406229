import React from "react";
import { Box, Grid } from "@mui/material";
// import SelectBoxWallet from "./selectBox";
import { useSelector } from "react-redux";
import UpDownShowComponent from "../../../components/Helpers/UpDownShowComponent";
import { PortfolioSvg } from "../../../components/Helpers/svg";
import { Numberz } from "../../../utilities";
import { RectCard } from "../../../components/Cards";
import { LoaderCircular } from "../../../components/Helpers/helperComponents";
export default function WalletComponentOne() {
  const { totalPortfolioValue, showCurrency, portfolioChanges } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <Box
      sx={{
        mb: '10px'
      }}
    >

      <Grid container>
        <Grid item xs={6} md={3} lg={2} sx={{ width: "100%" }}>
          <RectCard
            icon={<PortfolioSvg />}
            title="Portfolio Value"
            data={!totalPortfolioValue ? <LoaderCircular /> : showCurrency === 'usd' ? showCurrency + ' ' + Numberz(totalPortfolioValue?.USD).toFixed(2) : showCurrency + ' ' + Numberz(totalPortfolioValue?.AED).toFixed(2)}
            absoluteComponent={<UpDownShowComponent sign={portfolioChanges?.sign} diff={portfolioChanges?.diff} />}
          />
        </Grid>
      </Grid>

    </Box>
  );
}
