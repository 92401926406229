import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AuthHeader,
  AuthP,
  LogoText,
} from "../../components/Helpers/helperComponents";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ButtonLoader, PrimaryButton } from "../../components/Buttons";
import { PostHandler } from "../../Redux/RequestHandlers";
import { validEmail } from "../../Validation/formValidations";
import { PrimaryInputComponents } from "../../components/Style";

const InitiateForgetPassword = () => {
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const req = {
        url: "initiateForgetPassword",
        payload: inputs,
        action: "ACTION_FORGETPASS_INITIATE",
        reqPayload: inputs?.email,
      };
      const response = await dispatch(PostHandler(req));
      setIsLoading(false);
      if (response?.data?.success) {
        window.localStorage.setItem(
          "accessToken",
          response?.data?.data?.access_token
        );
        return navigate("/reset-password/verify-email/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const vEmail = validEmail(inputs?.email);
    setIsDisable(vEmail ? false : true);
  }, [inputs]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: {
            xs: "40px",
            md: "80px",
          },
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          borderRadius: "8px",
          border: "1px solid var(--neutral-200, #E3E3E8)",
          background: "var(--main-colors-white, #FFF)",
          boxShadow: "10px 4px 30px 0px rgba(161, 145, 243, 0.10)",
          maxWidth: "400px",
        }}
      >
        <LogoText />
        <AuthHeader text="Lookup your profile" />
        <AuthP
          mb="30px"
          text="Enter the  email address linked to your account so we can send you an OTP to verify your email and reset your password."
        />
        <PrimaryInputComponents
          color="#000"
          id="email"
          name="email"
          onChange={handleChange}
          value={inputs?.email || ""}
          placeholder="Enter your email address"
          required
        />

        {isLoading ? (
          <ButtonLoader mt="15px" />
        ) : (
          <PrimaryButton
            title="Find Account"
            onClick={handleSubmit}
            disabled={isDisable}
            mt="15px"
          />
        )}
        <Typography
          sx={{
            mt: "20px",
            color: "#0E0637",
            fontFeatureSettings: `'ss06' on`,
            fontFamily: "SF Pro Display",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20.6px",
            lineSpacing: "0.08px",
            textAlign: "center",
            width: "100%",
          }}
        >
          Back to{" "}
          <Link
            to="/login/"
            style={{
              color: "var(--main-colors-primary, #5235E8)",
              textDecoration: "none",
            }}
          >
            Sign in
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default InitiateForgetPassword;
