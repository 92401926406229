import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { PortfolioSvg, UniqueUser, VolumeSvg } from '../../../../components/Helpers/svg';
import UpDownShowComponent from '../../../../components/Helpers/UpDownShowComponent';
import { Numberz } from '../../../../utilities';
import { CopyToClipboardButton, LoaderCircular } from '../../../../components/Helpers/helperComponents';
import { RectCard } from '../../../../components/Cards';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import config from '../../../../config/config';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { StatusCountShow, StatusShowOld } from '../../../../components/Tables/StatusShow';

export default function LeftGridComponentOne() {
  const { totalPortfolioValue, transactionCountByStatus, showCurrency, volume, portfolioChanges, uniqueUserCount, kycStatus, referralCode } = useSelector(
    (state) => state?.dashboard
  );
  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'start'
        }}
      >
        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            icon={<PortfolioSvg />}
            title="Portfolio Value"
            data={!totalPortfolioValue ? <LoaderCircular /> : showCurrency === 'usd' ? showCurrency + ' ' + Numberz(totalPortfolioValue?.USD).toFixed(2) : showCurrency + ' ' + Numberz(totalPortfolioValue?.AED).toFixed(2)}
            absoluteComponent={<UpDownShowComponent sign={portfolioChanges?.sign} diff={portfolioChanges?.diff} />}
          />
        </Grid>

        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            icon={<VolumeSvg />}
            title="Volume (24H)"
            customData={
              <Box sx={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'space-between',
                width: '100%',
              }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    mt: "5px",
                  }}
                >
                  AED {Number(volume?.aed).toFixed(2)}
                </Box>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    mt: "5px",
                  }}
                >
                  USD {Number(volume?.usd).toFixed(2)}
                </Box>
              </Box>
            }
          />
        </Grid>

        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            to="/transactions/"
            icon={<AccountTreeOutlinedIcon />}
            title="Transactions"
            data={<Box sx={{
              // width: '100%',
              display: 'flex',
              gap: '5px',
            }}>
              <StatusCountShow status="Initiated" count={Numberz(transactionCountByStatus?.Initiated)} />
              <StatusCountShow status="Processing" count={Numberz(transactionCountByStatus?.Processing)} />
              <StatusCountShow status="Completed" count={Numberz(transactionCountByStatus?.Completed)} />
              <StatusCountShow status="Expired" count={Numberz(transactionCountByStatus?.Failed) + Numberz(transactionCountByStatus?.Declined) + Numberz(transactionCountByStatus?.Expired)} />

            </Box>}
          // data={Numberz(totalTransactions)}
          />
        </Grid>

        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            icon={<UniqueUser />}
            data={Numberz(uniqueUserCount)}
            title="Total Unique User"
          />
        </Grid>

        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            icon={<VerifiedOutlinedIcon sx={{ color: kycStatus?.status === "Completed" ? 'green' : "#000" }} />}
            title="KYC"
            data={<StatusShowOld status={kycStatus?.status === "Completed" ? kycStatus?.status : "Incomplete"} />}
            to="/settings/kyc/identity-verification/"
          />
        </Grid>

        <Grid item xs={6} md={3} lg={2} sx={{ p: "4px", width: "100%" }}>
          <RectCard
            icon={<GroupAddOutlinedIcon />}
            title="Referral Code"
            data={referralCode || "Not Initiated"}
            customData={
              <Box
                sx={{
                  display: 'flex',
                  mt: '5px',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    mt: "5px",
                  }}
                >
                  {referralCode || "Not Initiated"}
                </Box>
                {referralCode && <CopyToClipboardButton content={`${config?.SIGN_UP_URL}?code=${referralCode}`} />}
              </Box>
            }

          />
        </Grid>
      </Grid>
      <>

      </>
    </>
  );
}