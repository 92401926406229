import { Box, FormControl, Select, MenuItem } from "@mui/material";
import React from "react";
import { UsdSVG } from "./svgs";
import AEDSvg from "../../assets/img/home/ComponentOne/2.svg";
import { useDispatch, useSelector } from "react-redux";
import { ACTION_CHANGE_CURRENCY } from "../../Redux/Reducers/dashboard.reducer";

export default function CurrencyBtn({ color, border }) {
  const { showCurrency } = useSelector((state) => state?.dashboard);
  const [cur, setCur] = React.useState(showCurrency);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setCur(event.target.value);
    dispatch({
      type: ACTION_CHANGE_CURRENCY,
      payload: event.target.value,
    });
  };
  return (
    <FormControl>
      <Select
        id="demo-simple-select"
        value={cur}
        defaultValue="aed"
        onChange={handleChange}
        sx={{
          color: color,
          height: "35px",
          fontSize: "14px",
          border: border,
        }}
      >
        <MenuItem value="usd">
          <Box
            sx={{
              display: "flex",
              gap: "3px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UsdSVG />
            <Box>USD</Box>
          </Box>
        </MenuItem>
        <MenuItem value="aed">
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <img
              src={AEDSvg}
              width="15px"
              height="15px"
              alt="aed"
              style={{ marginTop: "2.5px" }}
            />
            <Box sx={{ marginTop: "2px" }}>AED</Box>
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
