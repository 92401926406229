import { TextField, Typography, Select, Box } from "@mui/material";
import { Link } from "react-router-dom";
import themes from "../Theme";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";

const selectedTheme = localStorage.getItem('theme') || 'light';

export const PrimaryInputComponents = ({
  id,
  name,
  onChange,
  value,
  placeholder,
  type,
  disabled,
  error,
  color
}) => (
  <TextField
    error={error || false}
    id={id}
    inputProps={{
      style: {
        height: "17px",
        color: color || themes[selectedTheme]?.text?.primary,
      },
    }}
    variant="outlined"
    sx={{
      width: "100%",
      height: "50px",
      borderRadius: '5px',
      border: themes[selectedTheme]?.colors?.border,
      "&:hover fieldset": {
        border: "1px solid #495ECA!important",
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "1px solid #495ECA!important",
        // boxShadow: "0 0 0 2px #495eca6b",
      },
    }}
    name={name}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    type={type || "text"}
    disabled={disabled || false}
  />
);

export const EmailDisableBox = ({ email }) => <Box
  sx={{
    border: themes[selectedTheme]?.colors?.border,
    height: '50px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    p:'15px'
  }}>
  {email}
</Box>

export const PhoneInputDisableBox = ({phone})=> <PhoneInput
country={"ae"}
name="phone"
value={phone || ""}
inputStyle={{
  width: "100%",
  height: "50px",
  background: 'transparent',
  color: themes[selectedTheme]?.text?.primary
}}
disabled
/>

export const PrimaryInputComponentsWithInputProps = ({
  id,
  name,
  onChange,
  value,
  placeholder,
  type,
  InputProps,
}) => (
  <TextField
    id={id}
    InputProps={{
      ...InputProps,
      style: {
        height: "48px",
        color: themes[selectedTheme]?.text?.primary,
      }
    }}
    variant="outlined"
    sx={{
      border: themes[selectedTheme]?.colors?.border,
      width: "100%",
      "&:hover fieldset": {
        border: "1px solid #495ECA!important",
      },
      "&:focus-within fieldset, &:focus-visible fieldset": {
        border: "1px solid #495ECA!important",
        boxShadow: "0 0 0 2px #495eca6b",
      },
    }}
    name={name}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    type={type || "text"}
  />
);

export const FooterHeader = ({ title }) => (
  <Typography
    sx={{
      color: "var(--neutral-900, #131316)",
      fontFeatureSettings: " 'ss06' on",
      fontFamily: "SF Pro Display",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "25.6px",
      letterSpacing: "0.08px",
      mb: "8px",
    }}
  >
    {title}
  </Typography>
);

export const MenuLink = ({ onClick, to, title }) => (
  <Typography
    to={to || "/"}
    component={Link}
    onClick={onClick}
    sx={{
      color: "var(--neutral-500, #717184)",
      fontFeatureSettings: " 'ss06' on",
      fontFamily: "SF Pro Display",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20.6px",
      mt: "8px",
      textDecoration: "none!important",
    }}
  >
    {title}
  </Typography>
);

export const BoldLabel = ({ labelFor }) => (
  <Typography
    sx={{
      textAlign: "left",
      mb: "10px",
      color: "#242331",
      fontFamily: "SF Pro Display",
      fontSize: "16.2px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "150%" /* 24.3px */,
      textTransform: "capitalize",
    }}
  >
    {labelFor}
  </Typography>
);

export const PrimarySelectComponent = ({ children, onChange, name, value, placeholder, disabled }) => <Select
  labelId="demo-simple-select-label"
  name={name}
  onChange={onChange}
  value={value}
  placeholder={placeholder}
  disabled={disabled || false}
  sx={{
    mt: "5px",
    height: "48px",
    width: "100%",
    color: themes[selectedTheme]?.text?.primary,
    border: themes[selectedTheme]?.colors?.border,
    "&:hover fieldset": {
      border: "1px solid #495ECA!important",
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "1px solid #495ECA!important",
      boxShadow: "0 0 0 2px #495eca6b",
    },
  }}
>
  {children}
</Select>
