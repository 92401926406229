import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { ErrorButton, PrimaryBlackButton } from "../../components/Buttons";
import { InputLabels } from "../../components/Helpers/label";
import { useDispatch, useSelector } from "react-redux";
import { GetHandler, PostHandler } from "../../Redux/RequestHandlers";
import ApiKeyList from "./ApiKeyList";
import PosMachineApiKeys from "./PosMachineApiKeys";
import KYCBLOCK from "../../components/Kyc";
import { hasAccess } from "../../utilities";
import { CardTitleComponentWithFilterComponent, CardTitleComponentWithRightArrow } from "../../components/Cards";
import BasicTableWithBody, { NoRecordInTable, TableDataLoad } from "../../components/Tables";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { AuthPRightLayout, CardLayout, Header5Layout } from "../../components/Layouts/AllLayOuts";
import { PrimaryInputComponents } from "../../components/Style";

const APIKeyLists = () => {
  const { ecommerce, pos } = useSelector((state) => state?.apikey);
  const { kycStatus } = useSelector((state) => state?.dashboard);
  const { accessLists } = useSelector((state) => state.auth);
  const [componentShow, setComponentShow] = useState("");
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const init = async () => {
    try {
      setIsFetching(true);
      const req = {
        url: "apiKey",
        action: "ACTION_GET_APIKEY",
      };
      await dispatch(GetHandler(req));
      setIsFetching(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) =>
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const req = {
        url: "apiKey",
        action: "ACTION_GET_ECCOMERCE_APIKEY",
        payload: inputs,
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setInputs({});
        setComponentShow("");
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsDisableSubmit(inputs?.keyname ? false : true);
  }, [inputs?.keyname]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisableUpdate, setIsDisableUpdate] = useState(true);
  const [updateInputs, setUpdateInputs] = useState({});
  const [updateData, setUpdateData] = useState({});
  // const handleUpdateChange = (e) =>
  // setUpdateInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      const req = {
        url: "updateApiKeyCallback",
        payload: { ...updateInputs, apiKeyId: updateData?.id },
      };
      const res = await dispatch(PostHandler(req));
      if (res?.data?.success) {
        setUpdateData({});
        setUpdateInputs({});
        setComponentShow("");
        init();
      }
      setIsUpdating(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsDisableUpdate(updateInputs?.callbackurl ? false : true);
  }, [updateInputs?.callbackurl]);

  useEffect(() => {
    setUpdateInputs((prev) => ({
      ...prev,
      callbackurl: updateData?.callbackurl,
    }));
  }, [updateData]);
  useEffect(() => {
    if (kycStatus?.status === "Completed") init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        p: {
          xs: "0px",
          lg: "0px 20px",
        }
      }}
    >
      <Grid container>
        <Grid item md={3} lg={4}>
          <Box
            sx={{
              mb: "15px",
            }}
          >
            <Header5Layout text="API Keys" fs="16px" />

            <AuthPRightLayout
              text="API Keys let you use Gafah from within other tools or your own software."
              fs="16px"
              lh="22px"
              mt="10px"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg={8}>
          {kycStatus?.status !== "Completed" ? (
              <KYCBLOCK />
          ) : (
            <>
              {/* add apikey */}
              {componentShow === "add" && (
                <CardLayout
                  my="15px"
                  component={<>
                    <CardTitleComponentWithRightArrow
                      noArrow={true}
                      mainTitle="Add New API Keys"
                    />

                    <Box sx={{ p: '15px' }}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",

                          },
                        }}
                      >
                        <Grid item xs={12} lg={5}>
                          <InputLabels label="Name" />
                          <PrimaryInputComponents
                            id="keyname"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            sx={{
                              width: "100%",
                              mt: "5px",
                              fontSize: '14px',
                              "&.Mui-focused fieldset": {
                                borderColor: "#C52328",
                                borderWidth: "2px",
                              },
                            }}
                            name="keyname"
                            onChange={handleChange}
                            value={inputs?.keyname || ""}
                            placeholder="Enter API key name"
                            size="small"
                            required
                          />
                        </Grid>
                        {/* <Grid item xs={12} lg={7}>
                      <InputLabels label="Callback Url (optional)" />
                      <TextField
                        id="callbackurl"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        name="callbackurl"
                        onChange={handleChange}
                        value={inputs?.callbackurl || ""}
                        placeholder="Enter callback url"
                        required
                      />
                    </Grid> */}
                      </Box>
                      <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                        {isSubmitting ? (
                          <Button variant="contained" title="submit" disabled>
                            <CircularProgress size="1.7rem" />
                          </Button>
                        ) : (
                          <PrimaryBlackButton
                            variant="contained"
                            title="Save"
                            onClick={handleSubmit}
                            disabled={isDisableSubmit}
                          />
                        )}

                        <ErrorButton onClick={() => setComponentShow("")}>Cancel</ErrorButton>
                      </Box>
                    </Box>
                  </>}
                />

              )}

              {/* update apikey */}
              {componentShow === "update" && (
                <Box
                  sx={{
                    mb: "15px",
                    p: "20px",
                    borderRadius: "8px",
                    border: "1px solid var(--neutral-200, #E3E3E8)",
                    background: "var(--main-colors-white, #FFF)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space",
                      gap: "5px",
                    }}
                  >
                    <Header5Layout text="Update API Keys" fs="16px" mt="0px" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                      },
                    }}
                  >
                    <Grid item xs={12} lg={5}>
                      <InputLabels label="Name" />
                      <TextField
                        id="keynamex"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        placeholder="API key name"
                        value={updateData?.name}
                        disabled
                      />
                    </Grid>

                    {/* <Grid item xs={12} lg={7}>
                      <InputLabels label="Callback Url (required)" />
                      <TextField
                        id="callbackurl"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", mt: "5px" }}
                        name="callbackurl"
                        onChange={handleUpdateChange}
                        value={updateInputs?.callbackurl || ""}
                        placeholder="Enter callback url"
                        required
                      />
                    </Grid> */}
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px", mt: "20px" }}>
                    {isUpdating ? (
                      <Button variant="contained" title="submit" disabled>
                        <CircularProgress size="1.7rem" />
                      </Button>
                    ) : (
                      <PrimaryBlackButton
                        variant="contained"
                        title="Update"
                        onClick={handleUpdate}
                        disabled={isDisableUpdate}
                      />
                    )}
                    <Button onClick={() => setComponentShow("")}>Cancel</Button>
                  </Box>
                </Box>
              )}

              {/* ecommerce apis */}

              <CardLayout
                component={<>
                  <CardTitleComponentWithFilterComponent
                    mainTitle="E-commerce Api Keys"
                    component={
                      hasAccess(["Apikey-create"], accessLists) &&
                      componentShow === "" && (
                        <PrimaryBlackButton
                          text={<><AddOutlinedIcon sx={{ fontSize: '20px', fontWeight: 900 }} /> API key</>}
                          onClick={() => setComponentShow("add")}
                        />
                      )
                    }

                  />

                  <BasicTableWithBody
                    maxWid="100%"
                    minWid="300px"
                    tableHead={[
                      "Name",
                      "Generated At",
                      "Action",
                    ]}
                    tableBody={
                      <>
                        {isFetching ? (
                          <TableDataLoad />
                        ) : ecommerce?.length > 0 ? (
                          ecommerce?.map(item => (
                            <ApiKeyList
                              item={item}
                              setUpdateData={setUpdateData}
                              setComponentShow={setComponentShow}
                              init={init}
                            />
                          ))
                        ) : (
                          <NoRecordInTable />
                        )}
                      </>
                    }
                  />
                </>}
              />



              {/* pos apis */}
              <CardLayout
                my="15px"
                component={<>
                  <CardTitleComponentWithRightArrow
                    mainTitle="Pos Machine Api Keys"
                    noArrow={true}
                  />

                  <BasicTableWithBody
                    maxWid="100%"
                    minWid="300px"
                    tableHead={[
                      "Name",
                      "Generated At",
                      "Action",
                    ]}
                    tableBody={
                      <>
                        {isFetching ? (
                          <TableDataLoad />
                        ) : pos?.length > 0 ? (
                          pos?.map((item) => (
                            <PosMachineApiKeys
                              item={item}
                              setUpdateData={setUpdateData}
                              setComponentShow={setComponentShow}
                              init={init}
                            />
                          ))
                        ) : (
                          <NoRecordInTable />
                        )}
                      </>
                    }
                  />
                </>}
              />

            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default APIKeyLists;
