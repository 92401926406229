import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CountdownComponent from "../../components/Helpers/countdown";
import RefundComponent from "./Refund";
import { GoDownAnimation } from "../../components/Helpers/helperComponents";
import { hasObjectPropertyExist } from "../../utilities";
import { MerchantAndLogoComponent } from "./Component/PosComponents";
import moment from "moment";
// import PaymentReceipt from "./PaymentReceipt";
import StatusShow from "../../components/Tables/StatusShow";
import PaymentReceipt from "./PaymentReceipt";

const DescriptionGrid = ({ left, right }) => <Box
  sx={{
    display: "flex",
    alignItems: 'center',
    mt: '10px',
    gap: '10px',
    justifyContent: 'space-between',
    fontSize: {
      xs: "10px",
      md: '14px'
    },
    fontWeight: 600
  }}
>
  <Box>{left}</Box>
  <Box>{right}</Box>
</Box>
export default function POSFailed() {
  const { invoices } = useSelector((state) => state?.pos);
  // console.log("success", invoices);
  const { id } = useParams();
  const navigate = useNavigate();
  const [refundShow, setRefundShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const [refundSubmit, setRefundSubmit] = useState(false);

  useEffect(() => {
    if (!hasObjectPropertyExist(invoices, id))
      return navigate(`/invoice?id=${id}`);
    let clientId = localStorage.getItem("clientId");
    if (clientId) {
      localStorage.removeItem("clientId");
    }
    if (!redirect && id !== invoices[id]?.id)
      return navigate(`/invoice?id=${id}`);

    setRefundShow(invoices[id]?.refundRequired ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoices, id]);

  const handleRedirect = () =>
    setTimeout(() => {
      const redirectUrl = invoices[id]?.successurl;
      dispatch({
        type: "ACTION_CLEAR_PAYMENT_DETAILS",
        payload: id,
      });
      redirectUrl ? window.location.replace(redirectUrl) : navigate("/");
    }, 15000);

  useEffect(() => {
    if (redirect) handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  useEffect(() => {
    setRedirect(
      refundSubmit ? true : invoices[id]?.refundRequired ? false : true
    );
  }, [id, invoices, refundSubmit]);

  return (
    <>
      <Box
        sx={{
          background: "#F1F5F966",
          minHeight: "95vh",
          p: '15px',
        }}
      >
        <Box sx={{ mt: '15vh' }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            {hasObjectPropertyExist(invoices, id) &&
              <Grid item xs={12} md={5} lg={4}
                sx={{
                  borderRadius: "8px",
                  border: "1px dashed var(--neutral-200, #E3E3E8)",
                  background: "var(--main-colors-white, #FFF)",
                  p: '15px',
                }}>

                <>
                  <MerchantAndLogoComponent
                    merchantName={
                      (
                        <Box sx={{ fontSize: '16px', fontWeight: 900 }}>
                          {invoices[id]?.merchantName}
                        </Box>
                      )
                    }
                    bb="none"
                  />

                  <Box sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}>


                    <svg
                      width="115"
                      height="100"
                      viewBox="0 0 134 123"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M98.4522 18.2161C100.2 16.1413 99.9444 13.0249 97.7368 11.4487C87.8239 4.37052 75.997 0.373072 63.7477 0.0248373C49.8566 -0.370071 36.2413 3.95128 25.1205 12.2847C13.9997 20.618 6.02901 32.4722 2.50744 45.9152C-1.01413 59.3583 0.120996 73.5978 5.72784 86.3132C11.3347 99.0286 21.0827 109.47 33.3833 115.937C45.6839 122.403 59.8119 124.513 73.4649 121.922C87.118 119.331 99.4912 112.193 108.568 101.67C116.572 92.3913 121.597 80.9631 123.057 68.8702C123.382 66.1773 121.253 63.8871 118.543 63.7779C115.832 63.6687 113.571 65.7834 113.205 68.4712C111.861 78.3442 107.684 87.6566 101.13 95.2542C93.503 104.096 83.1061 110.094 71.6337 112.271C60.1614 114.448 48.2899 112.675 37.954 107.242C27.6181 101.808 19.4271 93.0344 14.7158 82.3499C10.0045 71.6655 9.05066 59.7004 12.0098 48.4045C14.9689 37.1086 21.6664 27.1478 31.011 20.1455C40.3555 13.1431 51.7962 9.51199 63.4685 9.84382C73.4982 10.129 83.1909 13.3257 91.3869 18.992C93.6182 20.5345 96.7049 20.2909 98.4522 18.2161Z"
                        fill="red"
                      />
                      <path
                        d="M123.5 19.1953L63.3772 79.6288L36.0488 52.1591"
                        stroke="red"
                        strokeWidth="20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <Box
                      sx={{
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        textAlign: "center",
                        my: '15px'
                      }}>
                      Payment Failed
                    </Box>

                    <Box
                      sx={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                        textAlign: "center",
                        p: '20px'
                      }}
                    >
                      Your transaction has successfully been completed.
                    </Box>
                  </Box>



                  <Box
                    sx={{
                      background: "#f0f0f169",
                      borderRadius: '8px',
                      p: '10px',
                      mt: '10px',
                      border: "1px solid var(--neutral-200, #E3E3E8)",
                    }}>

                    <DescriptionGrid
                      left="Order ID"
                      right={invoices[id]?.id}
                    />

                    <DescriptionGrid
                      left="Amount"
                      right={`${invoices[id]?.amount} ${invoices[id]?.currency}`}
                    />

                    <DescriptionGrid
                      left="Date"
                      right={moment(invoices[id]?.createdAt ? invoices[id]?.createdAt : new Date()).format("lll")}
                    />

                    <DescriptionGrid
                      left="Status"
                      right={<StatusShow status={invoices[id]?.status} />}
                    />

                  </Box>

                  <Box sx={{
                    display: 'flex',
                    p: '20px',
                    gap: '10px',
                    justifyContent: 'center'
                  }}>
                    <PaymentReceipt data={invoices[id]} />


                  </Box>


                  {invoices[id]?.refundRequired && refundSubmit ? (
                    <Box
                      sx={{
                        color: "green",
                        textAlign: 'center'
                      }}
                    >
                      If opted, refund will be processed in 24-48 hours.
                    </Box>
                  ) : (
                    invoices[id]?.refundRequired && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}>
                        <Box sx={{ mb: '20px' }}>
                          Scroll down to add refund details
                        </Box>
                        <GoDownAnimation />
                      </Box>
                    )
                  )}

                  {redirect && (
                    <Box sx={{
                      textAlign: 'center',
                      fontSize: '10px'
                    }}>
                      <CountdownComponent
                        content="This page will redirect in "
                        colour="red"
                        expireIn={15}
                      />
                      <Box sx={{ color: 'red' }}>Want to Print and Download receipt. Please do it before redirection.</Box>
                    </Box>
                  )}



                </>


              </Grid>
            }


          </Grid>
        </Box>


        {hasObjectPropertyExist(invoices, id) && refundShow &&

          <RefundComponent
            setRefundShow={setRefundShow}
            setRedirect={setRedirect}
            setRefundSubmit={setRefundSubmit}
            refundData={invoices[id]}
          />
        }
      </Box>


    </>
  );
}
